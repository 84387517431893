/** Generated sass for domain-specific styling
 * by common\modules\domains\helpers\DomainScssWriter
 */


//// Custom scss for domain #368 (nieuwwonennederland.nl) ////

// Bootstrap SORTED variables and domain specific custom colors: 

$dark: #212529;
$light: #f8f9fa;
$danger: #df2427;
$warning: #fec135;
$info: #10b0a7;
$success: #46b365;
$secondary: #161547;
$primary: #f26a38;

$theme-colors: (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
);

//Add legacy styling from domain_css

//custom theme:
.subscription .toggle-btn.disabled {
    pointer-events: auto !important;
}
.pageblock_14020 {
.subscription .toggle-btn.disabled {
    pointer-events: auto !important;
}
}
.pageblock_14023 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14024 {
@media (min-width: 768px) {
    .footer__list-item {
        width:25%; 
    }
}

@media (min-width: 1200px) {
    .footer__list-item {
        width:20%; 
    }
}

}
.pageblock_14657 {
.news-banner {
  $self: &;
  margin-bottom: 60px;
  padding-top: 165px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 320px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    max-width: 1840px;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    span {
      margin: 4px 0 0 15px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 4px;
      background: $cyan;
    }
  }

  &__content {
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    color: $white;

    h6 {
      font-weight: 600;
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: $white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 640px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__button {
    padding: 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(xl) {
      top: 50px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14658 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14028 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14663 {
.news-banner {
  $self: &;
  margin-bottom: 60px;
  padding-top: 165px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 320px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    max-width: 1840px;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    span {
      margin: 4px 0 0 15px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 4px;
      background: $cyan;
    }
  }

  &__content {
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    color: $white;

    h6 {
      font-weight: 600;
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: $white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 640px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__button {
    padding: 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(xl) {
      top: 50px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14664 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14032 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14034 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14630 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14038 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14040 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14648 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14044 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14046 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14638 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14049 {
.news-banner {
  $self: &;
  margin-bottom: 60px;
  padding-top: 165px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 320px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    max-width: 1840px;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    span {
      margin: 4px 0 0 15px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 4px;
      background: $cyan;
    }
  }

  &__content {
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    color: $white;

    h6 {
      font-weight: 600;
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: $white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 640px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__button {
    padding: 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(xl) {
      top: 50px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14051 {
.card-house {
  a:hover {
    text-decoration: none;
  }
}

.card-house__image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
}
.pageblock_14978 {
.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

.house-media {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      border-radius: 5px;
      @include media-breakpoint-up(lg) {
        padding-top: 5px;
      }

      li {
        &:first-child {
          a {
            border-left: 1px solid rgba($pink, 0.25);
            border-radius: 5px 0 0 5px;
          }
        }

        &:last-child {
          a {
            border-radius: 0 5px 5px 0;
          }
        }

        a {
          padding: 0 10px;
          display: flex;
          align-items: center;
          height: 32px;
          font-size: 14px;
          cursor: pointer;
          font-weight: 600;
          color: $blue;
          border-top: 1px solid rgba($pink, 0.25);
          border-right: 1px solid rgba($pink, 0.25);
          border-bottom: 1px solid rgba($pink, 0.25);

          &:hover {
            text-decoration: none;
            color: $white;
            border-color: $cyan;
            background: $cyan;
          }
        }

        &.is-active {
          a {
            text-decoration: none;
            color: $white;
            border-color: $cyan;
            background: $cyan;
          }
        }
      }
    }
  }

  .house-media__bottom {
    .house-media__tab {
      display: none;

      &.active {
        display: block;
      }

      .iframe-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;

        &:not(:first-child) {
          margin-top: 16px;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__list {
    margin: 0 -4px;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 8px);
  }

  &__list-item {
    padding: 4px;
    position: relative;
    width: 33.333%;
    aspect-ratio: 6 / 4;
    @include media-breakpoint-up(lg) {
      width: 25%;
    }

    &:first-child {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &:last-child {
      .house-media__list-link {
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0) 40%);
          content: '';
        }
      }
    }
  }

  &__large {
    margin-bottom: 8px;
    position: relative;
    display: block;
    border-radius: 4px;
    aspect-ratio: 6 /4;

    //&:after {
    //	position: absolute;
    //	top: 50%;
    //	left: 50%;
    //	z-index: 2;
    //	display: flex;
    //	align-items: center;
    //	justify-content: center;
    //	width: 52px;
    //	height: 38px;
    //	font-family: $font-family-icon;
    //	font-size: 12px;
    //	color: $white;
    //	border-radius: 8px;
    //	background: $cyan;
    //	transform: translate(-50%, -50%);
    //	content: '\e933';
    //	box-shadow: 0 8px 8px rgba($black, 0.08);
    //	@include media-breakpoint-up(xl) {
    //		width: 72px;
    //		height: 48px;
    //		font-size: 16px;
    //	}
    //}

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  &__list-link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;

    span {
      position: absolute;
      top: 50%;
      bottom: unset;
      left: 50%;
      z-index: 2;
      display: block;
      color: $white;
      transform: translate(-50%, -50%);
      @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 15px;
        left: 15px;
        transform: unset;
      }
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
}
}
.pageblock_14055 {
.event-img {
  background-position: center;
  height: 15em;
}

}
.pageblock_14060 {
#map {
    height: 800px !important;
}
}
.pageblock_14061 {
.house-planning {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__holder {
    width: 100%;
    @include media-breakpoint-up(md) {
      padding: 20px;
      border-radius: 8px;
      background: $gray-300;
    }
    @include media-breakpoint-up(lg) {
      padding: 45px;
    }
    @include media-breakpoint-up(xl) {
      padding: 65px;
    }
  }

  h4 {
    margin-bottom: 25px;
  }

  &__holder-inner {
    padding-bottom: 20px;
    width: 100%;
    overflow: auto;
    @include media-breakpoint-up(md) {
      padding-bottom: 0;
      overflow: visible;
    }
  }

  &__list {
    position: relative;
    display: flex;
    width: 1200px;
    white-space: nowrap;
    @include media-breakpoint-up(md) {
      margin-top: 95px;
      width: 100%;
    }

    &:after {
      @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: linear-gradient(to right, rgba($cyan, 1) 0%, rgba($cyan, 1) 32%, rgba($cyan, 0.1) 100%);
        content: '';
      }
    }
  }

  &__list-item {
    margin-right: 10px;
    padding: 60px 45px 40px;
    position: relative;
    width: 320px;
    font-size: 16px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(md) {
      margin-right: 0;
      padding: 30px 0 0 20px;
      width: 33.333%;
      border-radius: 0;
      background: transparent;
    }

    &:before {
      position: absolute;
      top: 37px;
      left: 30px;
      width: calc(100% - 30px);
      height: 4px;
      background: linear-gradient(to right, rgba($cyan, 1) 0%, rgba($cyan, 1) 32%, rgba($cyan, 0.1) 100%);
      content: '';
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    &:after {
      position: absolute;
      top: 30px;
      left: 20px;
      z-index: 1;
      width: 18px;
      height: 18px;
      border: 3px solid $white;
      border-radius: 50%;
      background: $cyan;
      content: '';
      @include media-breakpoint-up(md) {
        top: -7px;
        left: -6px;
      }
    }

    &:last-child {
      padding-right: 20px;
      text-align: right;

      &:after {
        @include media-breakpoint-up(md) {
          right: -6px;
          left: auto;
        }
      }
    }

    &.is-active {
      &:after {
        top: 27px;
        width: 22px;
        height: 22px;
        background: $orange;
        @include media-breakpoint-up(md) {
          top: -9px;
        }
      }
    }

    &.is-past {
      &:after {
        top: 27px;
        width: 22px;
        height: 22px;
        background: $teal;
        @include media-breakpoint-up(md) {
          top: -9px;
        }
      }
    }

    strong {
      display: block;
      font-weight: 600;
      color: $blue;
    }

    span {
      display: block;
      font-weight: 300;
      color: $teal;
    }
  }

  &__list-none {
    position: absolute;
    top: -70px;
    left: -5px;
  }
}

}
.pageblock_14062 {
/* stylelint-disable */
.house-location {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  h4 {
    margin-bottom: 25px;
  }

  &__map {
    position: relative;
    display: block;
    border-radius:  16px 16px 0 0;
    height: 400px;

    @include media-breakpoint-up(xl) {
      height: 650px;
    }

    .leaflet-popup-content {
      width: 200px !important;
    }

    .leaflet-div-icon {
      @include background-image(marker-blue, svg, center center, no-repeat);
      background-size: 32px;
      width: 32px !important;
      height: 64px !important;
      background-color: transparent;
      border: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .house-location__wrapper {
    position: relative;

    .house-location__filters {
      width: 100%;
      background-color: $white;
      padding: 40px;
      border-radius: 8px;
      z-index: 400;
      box-shadow: 0 2px 4px 0 rgba($black, .1);

      @include media-breakpoint-up(xl) {
        position: absolute;
        top: 32px;
        right: 32px;
        padding: 30px;
        width: 250px;
      }

      @include media-breakpoint-up(xxl) {
        width: 312px;
        padding: 40px;
      }

      h5 {
        margin-bottom: 8px;
      }
    }
  }
}
/* stylelint-enable */
}
.pageblock_14627 {
.project-slider {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
  }

  &--background {
    background-image: linear-gradient(to bottom, rgba(250, 244, 239, 1) 0%, rgba(255, 253, 249, 1) 100%);
    @include media-breakpoint-up(xl) {
      padding: {
        top: 130px;
        bottom: 30px;
      };
    }
    padding: {
      top: 60px;
      bottom: 20px;
    };
  }

  &--background2 {
    margin-bottom: 60px;
    background: $gray-300;
    @include media-breakpoint-up(xl) {
      margin-bottom: 120px;
      padding: {
        top: 120px;
        bottom: 120px;
      };
    }
    padding: {
      top: 60px;
      bottom: 60px;
    };
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__top-left {
    display: inline-flex;
    align-items: center;

    h4 {
      margin-bottom: 0;
    }
  }

  &__number {
    margin-right: 15px;
    padding: 0 15px;
    display: inline-flex;
    align-items: center;
    height: 32px;
    font-size: 14px;
    color: $pink;
    border-radius: 40px;
    background: $gray-300;
    @include media-breakpoint-up(xl) {
      margin-right: 25px;
      padding: 0 25px;
      height: 48px;
    }
  }

  &__slider {
    position: relative;
    width: 100%;

    &:after {
      @include media-breakpoint-up(xxxl) {
        position: absolute;
        top: 0;
        left: 100%;
        width: 70000px;
        height: 100%;
        background: rgba($light-alt, 0.5);
        content: '';
      }
    }
  }

  &__slide {
    margin-right: 10px;
    width: 350px;
    @include media-breakpoint-up(xl) {
      margin-right: 16px;
      width: 528px;
    }

    .card-house {
      .card-house__bar .card-house__bar-inner {
        span {
          left: 0;
          border-radius: 0 4px 0 0;
        }
      }
    }
  }

  &__holder {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
  }

  &__next,
  &__prev {
    display: none !important;
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 115px;
      z-index: 3;
      display: flex !important;
      align-content: center;
      align-items: center;
      width: 40px;
      height: 140px;
      font-size: 13px;
      color: $blue;
      border: 0;
      background: transparent;
      transition: all 0.3s;
      cursor: pointer;
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 0;
      height: 0;
      border-style: solid;
      content: '';
    }
  }

  &--step {
    z-index: 9;

    .project-slider__slider {
      &:after {
        display: none;
      }
    }
  }

  &__prev {
    padding-left: 12px;
    left: 0;

    &:after {
      border-color: transparent transparent transparent $white;
      border-width: 70px 0 70px 40px;
    }
  }

  &__next {
    padding-left: 15px;
    right: 0;

    &:after {
      border-color: transparent $white transparent transparent;
      border-width: 70px 40px 70px 0;
    }
  }

  &--inner {
    .card-house__list {
      padding: 8px 0;
    }
  }
}

.card-house {
  position: relative;
  display: block;
  width: 100%;

  a:hover {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  &__content {
    padding-top: 15px;
    width: 100%;
    font-size: $font-size-sm;
    font-weight: 300;
    color: $teal;

    h6 {
      margin-bottom: 8px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0 0 10px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 300;
    @include media-breakpoint-up(lg) {
      padding-bottom: 12px;
      font-size: $font-size-sm;
    }
  }

  &__list-item {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    &:after {
      margin: 0 10px;
      display: inline-block;
      width: 4px;
      height: 4px;
      background: $teal;
      transform: rotate(45deg);
      content: '';
      @include media-breakpoint-up(xl) {
        margin: 0 15px;
      }
    }

    &.city {
      font-size: 20px;
      font-weight: 500;
    }

    a {
      text-decoration: none;
      color: $cyan;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__bar {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 4px;
    background: rgba(242, 232, 225);
  }

  &__bar-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    //width: attr(data-percentage %); // not supported yet


    height: 4px;
    background: $cyan;

    span {
      padding: 0 10px;
      position: absolute;
      bottom: calc(100% + 15px);
      left: calc(100% - 50px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 26px;
      font-size: 12px;
      text-align: center;
      color: $blue;
      border-radius: 4px 4px 0 0;
      background: $white;

      &:after {
        margin-left: -4px;
        position: absolute;
        bottom: -4px;
        left: 50%;
        width: 0;
        height: 0;
        border-color: $white transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0;
        content: '';
      }
    }
  }

  &__heart {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 10px;
    color: $red;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;
    @include media-breakpoint-up(xl) {
      top: 26px;
      right: 26px;
    }

    i.icon-heart-alt {
      color: $blue;
    }

    &.is-active,
    &:hover {
      text-decoration: none;

      i {
        &:before {
          content: '\e919';
          color: $blue;

        }

        &.icon-heart-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &__image {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    &:hover {
      text-decoration: none;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 39%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      border-radius: 8px;
      aspect-ratio: 3/2;
      object-fit: cover;
    }
  }

  &__label {
    position: absolute;
    top: 22px;
    left: 0;
    z-index: 1;

    span {
      padding: 0 20px 0 12px;
      display: inline-flex;
      align-items: center;
      height: 24px;
      font-size: 12px;
      font-weight: 600;
      color: $white;
      background: $cyan;
      clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
    }
  }

  &__price {
    color: $cyan;
  }
}
}
.pageblock_15144 {
.card-registration {
  margin-bottom: 45px;
  padding: 25px;
  position: relative;
  z-index: 1;
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 300;
  border-radius: 8px;
  background: $gray-300;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
    padding: 50px;
    font-size: 18px;
  }

  h5 {
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 0;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 12px;
    color: $blue;

    &:hover {
      text-decoration: none;
    }
  }
}

.card-selling {
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  z-index: 1;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background: $gray-300;
  @include media-breakpoint-up(xxl) {
    padding: 20px 30px 20px;
  }

  &__title {
    margin: -20px -20px 15px;
    padding: 20px;
    width: calc(100% + 40px);
    border-radius: 8px 8px 0 0;
    background: $blue;
    @include media-breakpoint-up(xxl) {
      margin: -20px -30px 15px;
      padding: 20px 30px;
      width: calc(100% + 60px);
    }

    h6 {
      margin-bottom: 0;
      font-weight: 600;
      color: $white;
    }
  }

  &__block {
    margin: 0 -20px 10px;
    padding: 0 20px 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% + 40px);
    border-bottom: 1px solid $light-border;
    @include media-breakpoint-up(xxl) {
      margin: 0 -30px 15px;
      padding: 0 30px 15px;
      width: calc(100% + 60px);
    }

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  &__block-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 2px 2px rgba($black, 0.04);
    @include media-breakpoint-up(xxl) {
      width: 80px;
      height: 80px;
    }
  }

  &__block-text {
    padding-left: 10px;
    width: calc(100% - 72px);
    @include media-breakpoint-up(xxl) {
      padding-left: 15px;
      width: calc(100% - 80px);
    }

    h6 {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 1.2;
      color: $blue;
      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }

      span {
        flex: 1 0 0%;
        padding-right: 12px;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
      }

      &:not(.no-chevron):after {
        margin-left: 0;
        font-family: $font-family-icon;
        font-size: 12px;
        font-weight: 400;
        content: '\e90b';
      }
    }

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
      color: $blue;
      @include media-breakpoint-up(xl) {
        font-size: 16px;
      }
    }
  }
}

.house-info {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__button {
    padding-left: 25px;
    position: relative;
    font-size: 16px;
    font-weight: 300;
    color: $cyan;

    &:after {
      position: absolute;
      top: 1px;
      left: 0;
      font-family: $font-family-icon;
      font-size: 12px;
      content: '\e928';
    }

    span + span {
      display: none;
    }

    &:hover {
      text-transform: none;
      color: $cyan;
    }

    &.is-active {
      &:after {
        top: 8px;
        font-size: 1.5px;
        content: '\e926';
      }

      span {
        display: none;
      }

      span + span {
        display: inline-block;
      }
    }
  }

  &__intro {
    margin-bottom: 10px;
    position: relative;
    width: 100%;
    height: 200px;
    color: color(light-gray);
    overflow: hidden;

    &.house-info__intro--full {
      height: auto;

      &::after {
        display: none;
      }
    }

    &.is-active {
      height: auto;
      margin-bottom: 0;

      &:after {
        opacity: 0;
        z-index: -1;
      }
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50px;
      background-image: linear-gradient(to bottom, rgba($light-alt, 0) 0%, rgba($light-alt, 1) 100%);
      transition: .3s ease;
      content: '';
    }
  }
}

.house-media {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      border-radius: 5px;
      @include media-breakpoint-up(lg) {
        padding-top: 5px;
      }

      li {
        &:first-child {
          a {
            border-left: 1px solid rgba($pink, 0.25);
            border-radius: 5px 0 0 5px;
          }
        }

        &:last-child {
          a {
            border-radius: 0 5px 5px 0;
          }
        }

        a {
          padding: 0 10px;
          display: flex;
          align-items: center;
          height: 32px;
          font-size: 14px;
          cursor: pointer;
          font-weight: 600;
          color: $blue;
          border-top: 1px solid rgba($pink, 0.25);
          border-right: 1px solid rgba($pink, 0.25);
          border-bottom: 1px solid rgba($pink, 0.25);

          &:hover {
            text-decoration: none;
            color: $white;
            border-color: $cyan;
            background: $cyan;
          }
        }

        &.is-active {
          a {
            text-decoration: none;
            color: $white;
            border-color: $cyan;
            background: $cyan;
          }
        }
      }
    }
  }

  .house-media__bottom {
    .house-media__tab {
      display: none;

      &.active {
        display: block;
      }

      .iframe-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;

        &:not(:first-child) {
          margin-top: 16px;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__list {
    margin: 0 -4px;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 8px);
  }

  &__list-item {
    padding: 4px;
    position: relative;
    width: 33.333%;
    aspect-ratio: 6 / 4;
    @include media-breakpoint-up(lg) {
      width: 25%;
    }

    &:first-child {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &:last-child {
      .house-media__list-link {
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0) 40%);
          content: '';
        }
      }
    }
  }

  &__large {
    margin-bottom: 8px;
    position: relative;
    display: block;
    border-radius: 4px;
    aspect-ratio: 6 /4;

    //&:after {
    //	position: absolute;
    //	top: 50%;
    //	left: 50%;
    //	z-index: 2;
    //	display: flex;
    //	align-items: center;
    //	justify-content: center;
    //	width: 52px;
    //	height: 38px;
    //	font-family: $font-family-icon;
    //	font-size: 12px;
    //	color: $white;
    //	border-radius: 8px;
    //	background: $cyan;
    //	transform: translate(-50%, -50%);
    //	content: '\e933';
    //	box-shadow: 0 8px 8px rgba($black, 0.08);
    //	@include media-breakpoint-up(xl) {
    //		width: 72px;
    //		height: 48px;
    //		font-size: 16px;
    //	}
    //}

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  &__list-link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;

    span {
      position: absolute;
      top: 50%;
      bottom: unset;
      left: 50%;
      z-index: 2;
      display: block;
      color: $white;
      transform: translate(-50%, -50%);
      @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 15px;
        left: 15px;
        transform: unset;
      }
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
}

.house-overview {
  position: relative;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 60px;
    };
  }
  @include media-breakpoint-up(xl) {
    margin: {
      bottom: 120px;
    }
  }
  margin: {
    top: 24px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__list {
    margin: 0 0 30px;
    padding: 0;
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    }
  }

  &__list-item {
    margin-right: 10px;
    padding: 5px 5px 5px 20px;
    position: relative;
    font-size: 16px;
    font-weight: 300;
    border-right: 1px solid $border-color;
    @include media-breakpoint-up(xl) {
      margin-right: 20px;
      padding: 5px 20px;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: 0;
    }

    p {
      margin-bottom: 0;
    }

    i {
      position: absolute;
      top: 7px;
      left: 0;
      color: $cyan;
    }

    strong {
      font-weight: 600;
      color: $blue;
    }
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(xl) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  .row {
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 32px;
    }

    .card-broker {
      @include media-breakpoint-between(lg, xl) {
        margin-bottom: 0;
      }
    }
  }
}

.house-types {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__heading {
    padding: 8px 30px 8px 15px;
    font-size: 16px;
    color: $white;
    border-radius: 5px;
    background: $blue;
    @include media-breakpoint-up(xl) {
      padding: 8px 60px 8px 5px;
    }

    strong {
      font-weight: 600;
    }
  }

  &__tag {
    @include media-breakpoint-down(sm) {
      padding: 8px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      background: $gray-300;

      &:before {
        content: 'Status';
      }
    }

    span {
      padding: 3px 8px;
      display: inline-block;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 2px;
      background: $green;

      &.status-option {
        background: $orange
      }
      &.status-oral-agreement {
        background: $orange;
      }
      &.status-sold {
        background: $red;
      }
    }

    &--sold {
      span {
        background: $red;
      }
    }

    &--offer {
      span {
        background: $orange;
      }
    }
  }

  &__holder {
    margin-bottom: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid $border-color;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    &.is-active {
      margin-bottom: 10px;
      border-bottom: 0;

      .house-types__list {
        display: block;
      }
    }
  }

  &__list {
    margin-top: 25px;
  }

  &__item {
    padding: 15px 0 0;
    position: relative;
    font-size: 16px;
    color: $blue;
    @include media-breakpoint-up(md) {
      padding-right: 30px;
      padding-bottom: 15px;
      border-bottom: 1px solid $border-color;
    }
    @include media-breakpoint-up(xl) {
      padding: 15px 60px 15px 5px;
    }

    &:not(.no-chevron):after {
      @include media-breakpoint-up(md) {
        position: absolute;
        top: 50%;
        right: 10px;
        font-family: $font-family-icon;
        font-size: 14px;
        transform: translateY(-50%);
        content: '\e904';
      }
      @include media-breakpoint-up(xl) {
        right: 20px;
      }
    }

    &.is-active {
      .house-types__item-title {
        &:after {
          top: 9px;
          font-size: 1.5px;
          content: '\e926';
        }
      }
    }
  }

  &__item-title {
    padding-bottom: 10px;
    position: relative;
    font-size: 14px;

    &:after {
      position: absolute;
      top: 2px;
      right: 0;
      font-family: $font-family-icon;
      font-size: 12px;
      content: '\e928';
    }
  }

  &__item-panel {
    display: none;
    width: 100%;
    @include media-breakpoint-up(md) {
      display: block !important;
    }

    & .row > a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      content: ' ';
      z-index: 1;
    }
  }

  &__title {
    &:before {
      @include media-breakpoint-down(sm) {
        content: '';
      }
    }
  }

  &__wonen {
    @include media-breakpoint-down(sm) {
      padding: 8px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;

      &:before {
        content: 'Wonen';
      }
    }
  }

  &__room {
    @include media-breakpoint-down(sm) {
      padding: 8px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      background: $gray-300;

      &:before {
        content: 'Kamers';
      }
    }
  }

  &__price {
    @include media-breakpoint-down(sm) {
      padding: 8px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;

      &:before {
        content: 'Prijs';
      }
    }
  }
}

.document-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-left: 0;

  > li {
    flex-basis: 150px;
    position: relative;
    cursor: pointer;

    img {
      object-fit: cover;
      width: 150px;
      height: 150px;
      vertical-align: middle;
      border-radius: 5px;
    }

    &::after {
      content: "";
      flex-basis: 150px;
    }

    .document-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(57, 57, 57, 0.502);
      top: 0;
      left: 0;
      transform: scale(0);
      transition: all 0.2s 0.1s ease-in-out;
      color: #fff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover .document-overlay {
      transform: scale(1);
    }
  }
}

.js-employee-slider {
  .slick-dots {
    margin: 0;
    padding: 0 0 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding-bottom: 60px;
    }

    li {
      margin: 0 4px;
      display: block;
      width: 10px;
      height: 10px;

      button {
        padding: 0;
        display: block;
        width: 10px;
        height: 10px;
        text-indent: -9999px;
        border: 0;
        border-radius: 50%;
        background: $teal;
        cursor: pointer;
      }

      &.slick-active {
        button {
          background: $cyan;
        }
      }
    }
  }
}

}
.pageblock_15145 {
.js-employee-slider {
  .slick-dots {
    margin: 0;
    padding: 0 0 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding-bottom: 60px;
    }

    li {
      margin: 0 4px;
      display: block;
      width: 10px;
      height: 10px;

      button {
        padding: 0;
        display: block;
        width: 10px;
        height: 10px;
        text-indent: -9999px;
        border: 0;
        border-radius: 50%;
        background: $teal;
        cursor: pointer;
      }

      &.slick-active {
        button {
          background: $cyan;
        }
      }
    }
  }
}

.card-broker {
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  z-index: 1;
  width: 100%;
  background: $gray-300;
  @include media-breakpoint-up(xxl) {
    padding: 20px 30px 30px;
  }

  &--top {
    border-radius: 8px 8px 0 0;
  }

  &--bottom {
    border-radius: 0 0 8px 8px;
  }

  &__title {
    margin: -20px -20px 25px;
    padding: 20px;
    width: calc(100% + 40px);
    border-radius: 8px 8px 0 0;
    background: $blue;
    @include media-breakpoint-up(xxl) {
      margin: -20px -30px 25px;
      padding: 20px 30px;
      width: calc(100% + 60px);
    }

    h6 {
      margin-bottom: 0;
      font-weight: 600;
      color: $white;
    }
  }

  &__block {
    margin: 0 -20px 20px;
    padding: 0 20px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% + 40px);
    border-bottom: 1px solid $light-border;
    @include media-breakpoint-up(xxl) {
      margin: 0 -30px 30px;
      padding: 0 30px 20px;
      width: calc(100% + 60px);
    }
  }

  &__block-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 2px 2px rgba($black, 0.04);
  }

  &__block-text {
    padding-left: 15px;
    width: calc(100% - 80px);

    span {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 300;
      color: $pink;

      i {
        margin-right: 10px;
      }
    }
  }

  &__info {
    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        span,
        a {
          padding-left: 35px;
          position: relative;
          display: inline-block;
          align-items: flex-start;
          font-size: $font-size-sm;
          color: $blue;

          small {
            display: block;
            font-size: 14px;
            color: $teal;
          }

          &:hover {
            text-decoration: none;
            color: $pink;
          }

          i {
            position: absolute;
            top: 3px;
            left: 0;
            width: 30px;
            color: $cyan;

            &.icon-mail {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  &__list {
    ul {
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        margin-bottom: 3px;
        position: relative;
        width: 100%;
        font-size: 16px;
        color: $blue;
        border-right: 1px solid $gray-500;
        padding: {
          top: 4px;
          bottom: 4px;
          left: 25px;
        }

        &:last-child {
          margin: 0;
          border-right: 0;
        }

        &:before {
          position: absolute;
          top: 7px;
          left: 1px;
          font-family: $font-family-icon;
          font-size: 10px;
          color: $cyan;
          content: '\e909';
        }
      }
    }
  }

  &__logo {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        margin: 10px 5px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        border-radius: 4px;
        box-shadow: 0 2px 2px rgba($black, 0.04);

        img {
          padding: 5px 10px;
          width: auto;
          max-width: 138px;
          height: 56px;
        }
      }
    }
  }

  &--logo {
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    h6 {
      margin-bottom: 0;
      font-size: 16px;
    }

    ul {
      margin-bottom: 0;
    }
  }
}

.card-selling {
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  z-index: 1;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background: $gray-300;
  @include media-breakpoint-up(xxl) {
    padding: 20px 30px 30px;
  }

  &__title {
    margin: -20px -20px 15px;
    padding: 20px;
    width: calc(100% + 40px);
    border-radius: 8px 8px 0 0;
    background: $blue;
    @include media-breakpoint-up(xxl) {
      margin: -20px -30px 15px;
      padding: 20px 30px;
      width: calc(100% + 60px);
    }

    h6 {
      margin-bottom: 0;
      font-weight: 600;
      color: $white;
    }
  }

  &__block {
    margin: 0 -20px 10px;
    padding: 0 20px 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% + 40px);
    border-bottom: 1px solid $light-border;
    @include media-breakpoint-up(xxl) {
      margin: 0 -30px 15px;
      padding: 0 30px 15px;
      width: calc(100% + 60px);
    }

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  &__block-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 2px 2px rgba($black, 0.04);
    @include media-breakpoint-up(xxl) {
      width: 80px;
      height: 80px;
    }
  }

  &__block-text {
    padding-left: 10px;
    width: calc(100% - 72px);
    @include media-breakpoint-up(xxl) {
      padding-left: 15px;
      width: calc(100% - 80px);
    }

    h6 {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 1.2;
      color: $blue;
      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }

      span {
        flex: 1 0 0%;
        padding-right: 12px;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
      }

      &:not(.no-chevron):after {
        margin-left: 0;
        font-family: $font-family-icon;
        font-size: 12px;
        font-weight: 400;
        content: '\e90b';
      }
    }

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
      color: $blue;
      @include media-breakpoint-up(xl) {
        font-size: 16px;
      }
    }
  }
}
}
.pageblock_14077 {
.building-type-badge {
  width: 75px;
  height: 75px;
}
}
.pageblock_14079 {
.gallery-banner {
	margin-bottom: 24px;
	padding: 165px 20px 0;
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin-bottom: 80px;
		padding-top: 125px;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 160px;
	}

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 240px;
		border-radius: 0 0 12px 12px;
		background: $blue;
		content: '';
		@include media-breakpoint-up(lg) {
			border-radius: 0 0 15px 15px;
		}
	}

	.breadcrumb {
		margin-bottom: 10px;
		padding-top: 25px;
		border-top: 1px solid rgba($white, 0.1);
	}

	&__holder {
		margin: 0 auto;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		max-width: 1840px;
		overflow: hidden;
		@include media-breakpoint-up(lg) {
			border-radius: 16px;
		}
	}

	&__large {
		margin-bottom: 8px;
		position: relative;
		overflow: hidden;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			width: 50%;
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 16px;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 0;
			}
		}
	}

	&__small-item {
		padding: 0 4px;
		width: 33.333%;
		height: 80px;
		@include media-breakpoint-up(sm) {
			height: auto;
		}
		@include media-breakpoint-up(lg) {
			padding: 0;
			width: 50%;
		}
	}

	&__small {
		margin: 0 -4px;
		position: relative;
		display: flex;
		flex-wrap: wrap;
		width: calc(100% + 8px);
		overflow: hidden;
		@include media-breakpoint-up(lg) {
			margin: 0;
			width: 50%;
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 8px;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 0;
			}
		}
	}

	&__button {
		position: absolute;
		top: 20px;
		right: 0;
		z-index: 3;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		@include media-breakpoint-up(lg) {
			padding: 0 20px;
			top: 30px;
		}
	}

	&__share,
	&__heart {
		margin-left: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		color: $cyan;
		border-radius: 50%;
		background: $white;
		transition: all 0.3s;
		@include media-breakpoint-up(lg) {
			width: 48px;
			height: 48px;
		}

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;
        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;
          }
        }
      }
    }
  }
}
}
.pageblock_14080 {
.house-overview {
  position: relative;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 60px;
    };
  }
  @include media-breakpoint-up(xl) {
    margin: {
      bottom: 120px;
    }
  }
  margin: {
    top: 24px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__list {
    margin: 0 0 30px;
    padding: 0;
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    }
  }

  &__list-item {
    margin-right: 10px;
    padding: 5px 5px 5px 20px;
    position: relative;
    font-size: 16px;
    font-weight: 300;
    border-right: 1px solid $border-color;
    @include media-breakpoint-up(xl) {
      margin-right: 20px;
      padding: 5px 20px;
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;
      border-right: 0;
    }

    p {
      margin-bottom: 0;
    }

    i {
      position: absolute;
      top: 7px;
      left: 0;
      color: $cyan;
    }

    strong {
      font-weight: 600;
      color: $blue;
    }
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(xl) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  .row {
    @include media-breakpoint-down(md) {
      flex-direction: column-reverse;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 32px;
    }

    .card-broker {
      @include media-breakpoint-between(lg, xl) {
        margin-bottom: 0;
      }
    }
  }
}

.house-content {
	margin-bottom: 50px;
	position: relative;
	width: 100%;
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	h3 {
		margin-bottom: 8px;
		font-size: 2rem;
		line-height: 40px;
		letter-spacing: -1px;
		@include media-breakpoint-up(lg) {
			margin-bottom: 20px;
			font-size: $h3-font-size;
			line-height: 48px;
		}
	}

	&__tag {
		margin-bottom: 24px;

		span {
			padding: 3px 8px;
			display: inline-block;
			font-size: 10px;
			text-transform: uppercase;
			color: $white;
			border-radius: 2px;
			background: $cyan;
		}
	}

	&__price {
		margin-bottom: 8px;
		padding-top: 8px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		@include media-breakpoint-up(lg) {
			margin-bottom: 30px;
			padding-bottom: 27px;
			border-top: 1px solid $border-color;
			border-bottom: 1px solid $border-color;
		}

		h5 {
			margin-right: 30px;
			margin-bottom: 0;
			color: $blue;
		}

		span {
			display: inline-flex;
			align-items: center;
			font-weight: 300;
			color: $teal;

			i {
				margin-right: 10px;
				font-size: 14px;
			}
		}
	}

	&__subtitle {
		margin-bottom: 8px;
		display: inline-flex;
		align-items: center;
		@include media-breakpoint-up(lg) {
			margin-bottom: 30px;
		}

		strong {
			margin-right: 20px;
		}

		span {
			padding: 3px 8px;
			display: inline-block;
			font-size: 10px;
			text-transform: uppercase;
			color: $white;
			border-radius: 2px;
			background: $green;
		}
	}

	&__list {
		padding: 0 0 25px;
		width: 100%;
		border-bottom: 1px solid $border-color;
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
		}
	}

	&__list-item {
		margin-right: 10px;
		padding: 5px 5px 5px 25px;
		position: relative;
		font-size: 16px;
		font-weight: 300;
		@include media-breakpoint-up(lg) {
			margin-right: 20px;
			padding-right: 20px;
			border-right: 1px solid $border-color;
		}

		&:last-child {
			margin-right: 0;
			padding-right: 0;
			border-right: 0;
		}

		p {
			margin-bottom: 0;
		}

		i {
			position: absolute;
			top: 7px;
			left: 0;
			color: $cyan;
		}

		strong {
			font-weight: 600;
			color: $blue;
		}
	}
}

.house-media {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      border-radius: 5px;
      @include media-breakpoint-up(lg) {
        padding-top: 5px;
      }

      li {
        &:first-child {
          a {
            border-left: 1px solid rgba($pink, 0.25);
            border-radius: 5px 0 0 5px;
          }
        }

        &:last-child {
          a {
            border-radius: 0 5px 5px 0;
          }
        }

        a {
          padding: 0 10px;
          display: flex;
          align-items: center;
          height: 32px;
          font-size: 14px;
          cursor: pointer;
          font-weight: 600;
          color: $blue;
          border-top: 1px solid rgba($pink, 0.25);
          border-right: 1px solid rgba($pink, 0.25);
          border-bottom: 1px solid rgba($pink, 0.25);

          &:hover {
            text-decoration: none;
            color: $white;
            border-color: $cyan;
            background: $cyan;
          }
        }

        &.is-active {
          a {
            text-decoration: none;
            color: $white;
            border-color: $cyan;
            background: $cyan;
          }
        }
      }
    }
  }

  .house-media__bottom {
    .house-media__tab {
      display: none;

      &.active {
        display: block;
      }

      .iframe-wrapper {
        position: relative;
        overflow: hidden;
        width: 100%;
        padding-top: 56.25%;

        &:not(:first-child) {
          margin-top: 16px;
        }

        iframe {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &__list {
    margin: 0 -4px;
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 8px);
  }

  &__list-item {
    padding: 4px;
    position: relative;
    width: 33.333%;
    aspect-ratio: 6 / 4;
    @include media-breakpoint-up(lg) {
      width: 25%;
    }

    &:first-child {
      display: none;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &:last-child {
      .house-media__list-link {
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.56) 0%, rgba(0, 0, 0, 0) 40%);
          content: '';
        }
      }
    }
  }

  &__large {
    margin-bottom: 8px;
    position: relative;
    display: block;
    border-radius: 4px;
    aspect-ratio: 6 /4;

    //&:after {
    //	position: absolute;
    //	top: 50%;
    //	left: 50%;
    //	z-index: 2;
    //	display: flex;
    //	align-items: center;
    //	justify-content: center;
    //	width: 52px;
    //	height: 38px;
    //	font-family: $font-family-icon;
    //	font-size: 12px;
    //	color: $white;
    //	border-radius: 8px;
    //	background: $cyan;
    //	transform: translate(-50%, -50%);
    //	content: '\e933';
    //	box-shadow: 0 8px 8px rgba($black, 0.08);
    //	@include media-breakpoint-up(xl) {
    //		width: 72px;
    //		height: 48px;
    //		font-size: 16px;
    //	}
    //}

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }

  &__list-link {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;

    span {
      position: absolute;
      top: 50%;
      bottom: unset;
      left: 50%;
      z-index: 2;
      display: block;
      color: $white;
      transform: translate(-50%, -50%);
      @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 15px;
        left: 15px;
        transform: unset;
      }
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      object-fit: cover;
    }
  }
}

.gallery-popup {
  padding-top: 100px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  opacity: 0;
  z-index: -1;
  visibility: hidden;
  width: 100%;
  height: 100vh;
  background: $white;
  transition: all 0.3s;
  overflow-y: auto;
  overflow-x: hidden;
  @include media-breakpoint-up(lg) {
    padding-top: 0;
    display: block;
    overflow: hidden;
  }

  &__block {
    padding: 16px 20px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    z-index: 9;
    align-items: center;
    justify-content: space-between;
    width: 393px;
    font-size: 14px;
    border-radius: 8px;
    background: $white;
    transform: translateX(-50%);
    box-shadow: 0 8px 8px rgba($black, 0.08);

    p {
      margin-bottom: 0;
    }

    h6 {
      font-size: 18px;
    }
  }

  &__block-left {
    width: 200px;
  }

  .is-open-gallery & {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }

  &__nav {
    width: 100%;
    transition: .3s ease;

    @include media-breakpoint-down(md) {
      margin: 0 -4px;
      padding: 4px 0;
      width: calc(100% + 8px);
    }
  }

  &__nav-item {
    padding: 4px;
    position: relative;
    border-radius: 8px;
    cursor: pointer;

    img {
      height: 50px;
      display: block;
      object-fit: cover;
      border-radius: 8px;
      width: auto;

      @include media-breakpoint-up(xl) {
        height: 100px;
      }
    }
  }

  &__top {
    padding: 20px 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding: 50px 0;
    }
  }

  &__top-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;

    a.favorite-btn,
    a.favorite-btn-lot {
      color: $blue;
      background: $white;

      i.icon-heart {
        color: $red;
        &-alt {
          color: $blue;
        }
      }

      &.is-active,
      &:hover {
        color: $white;
        background: $blue;
        text-decoration: none;

        i.icon-heart {
          &:before {
            content: '\e919';
            color: $white;

          }

          &-alt {
            &:before {
              content: '\e91a';
              color: $red;

            }
          }
        }
      }
    }
  }

  .gallery-popup__holder {
    @include media-breakpoint-up(lg) {
      position: relative;
      margin: {
        top: 125px;
        bottom: 100px;
      };
    }

    @include media-breakpoint-up(xxl) {
      margin: {
        top: 150px;
        bottom: 150px;
      };
    }

    .js-expand-gallery {
      position: absolute;
      top: 0;
      cursor: pointer;
      right: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 4px;
      transform: rotate(45deg);
      z-index: 10;

      @include media-breakpoint-down(md) {
        display: none;
      }

      i {
        color: $blue;
        &::before {
          display: inline-block;
        }
      }
    }

    .gallery-popup__for {
      @include media-breakpoint-up(lg) {
        transition: .3s ease;
      }
    }
  }

  &__for {
    width: 100%;
    padding: 0 !important;

    .slick-list {
      @include media-breakpoint-down(lg) {
        overflow: visible;
      }

      .slick-track {
        @include media-breakpoint-up(lg) {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &__for-item {
    position: relative;
    width: 100%;
    overflow: hidden;
    @include media-breakpoint-down(lg) {
      margin: 4px 0;
      opacity: 0.4;
      border-radius: 8px;
    }

    a {
      @include media-breakpoint-up(lg) {
        width: fit-content;
        display: block;
        position: relative;
        margin: 0 auto;
      }

      img {
        width: fit-content;
        max-width: 100%;
        margin: 0 auto;
        transition: .3s ease;

        @include media-breakpoint-up(fhd) {
          max-height: 60vh;
        }
      }

      &.slick-current {
        @include media-breakpoint-down(lg) {
          opacity: 1;
        }
      }
    }
  }

  &__holder {
    margin: 0 auto;
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: 900px;
    }
    @include media-breakpoint-up(xl) {
      width: 1074px;
    }
  }
}

.house-info {
	margin-bottom: 50px;
	position: relative;
	width: 100%;
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__button {
		padding-left: 25px;
		position: relative;
		font-size: 16px;
		font-weight: 300;
		color: $cyan;

		&:after {
			position: absolute;
			top: 1px;
			left: 0;
			font-family: $font-family-icon;
			font-size: 12px;
			content: '\e928';
		}

		span + span {
			display: none;
		}

		&:hover {
			text-transform: none;
			color: $cyan;
		}

		&.is-active {
			&:after {
				top: 8px;
				font-size: 1.5px;
				content: '\e926';
			}

			span {
				display: none;
			}

			span + span {
				display: inline-block;
			}
		}
	}

	&__intro {
		margin-bottom: 10px;
		position: relative;
		width: 100%;
		height: 200px;
		color: color(light-gray);
		overflow: hidden;

    &.house-info__intro--full {
      height: auto;

      &::after {
        display: none;
      }
    }

		&.is-active {
			height: auto;
      margin-bottom: 0;

      &:after {
        opacity: 0;
        z-index: -1;
      }
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 50px;
			background-image: linear-gradient(to bottom, rgba($light-alt, 0) 0%, rgba($light-alt, 1) 100%);
      transition: .3s ease;
			content: '';
		}
	}
}

.house-features {
	margin-bottom: 50px;
	position: relative;
	width: 100%;
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__subtitle {
		font-weight: 600;
		color: $blue;
	}

	&__item {
		padding: 8px 0;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		font-size: 16px;
		border-bottom: 1px solid $gray-500;

		&:last-child {
			border-bottom: 0;
		}

		span {
			width: 50%;
			color: $pink;
		}

		strong {
			width: 50%;
			font-weight: 400;
			color: $blue;
		}
	}

	&__list {
		margin-bottom: 20px;
		padding: 0;
		width: 100%;
		@include media-breakpoint-up(xl) {
			margin-bottom: 30px;
		}
	}
}

.card-broker {
	margin-bottom: 10px;
	padding: 20px;
	position: relative;
	z-index: 1;
	width: 100%;
	background: $gray-300;
	@include media-breakpoint-up(xxl) {
		padding: 20px 30px 30px;
	}

	&--top {
		border-radius: 8px 8px 0 0;
	}

	&--bottom {
		border-radius: 0 0 8px 8px;
	}

	&__title {
		margin: -20px -20px 25px;
		padding: 20px;
		width: calc(100% + 40px);
		border-radius: 8px 8px 0 0;
		background: $blue;
		@include media-breakpoint-up(xxl) {
			margin: -20px -30px 25px;
			padding: 20px 30px;
			width: calc(100% + 60px);
		}

		h6 {
			margin-bottom: 0;
			font-weight: 600;
			color: $white;
		}
	}

	&__block {
		margin: 0 -20px 20px;
		padding: 0 20px 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: calc(100% + 40px);
		border-bottom: 1px solid $light-border;
		@include media-breakpoint-up(xxl) {
			margin: 0 -30px 30px;
			padding: 0 30px 20px;
			width: calc(100% + 60px);
		}
	}

	&__block-img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 80px;
		border-radius: 4px;
		background: $white;
		box-shadow: 0 2px 2px rgba($black, 0.04);
	}

	&__block-text {
		padding-left: 15px;
		width: calc(100% - 80px);

		span {
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 300;
			color: $pink;

			i {
				margin-right: 10px;
			}
		}
	}

	&__info {
		ul {
			margin: 0;
			padding: 0;

			li {
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}

				span,
				a {
					padding-left: 35px;
					position: relative;
					display: inline-block;
					align-items: flex-start;
					font-size: $font-size-sm;
					color: $blue;

					small {
						display: block;
						font-size: 14px;
						color: $teal;
					}

					&:hover {
						text-decoration: none;
						color: $pink;
					}

					i {
						position: absolute;
						top: 3px;
						left: 0;
						width: 30px;
						color: $cyan;

						&.icon-mail {
							font-size: 12px;
						}
					}
				}
			}
		}
	}

	&__list {
		ul {
			margin: 0;
			padding: 0;
			width: 100%;

			li {
				margin-bottom: 3px;
				position: relative;
				width: 100%;
				font-size: 16px;
				color: $blue;
				border-right: 1px solid $gray-500;
				padding: {
					top: 4px;
					bottom: 4px;
					left: 25px;
				}

				&:last-child {
					margin: 0;
					border-right: 0;
				}

				&:before {
					position: absolute;
					top: 7px;
					left: 1px;
					font-family: $font-family-icon;
					font-size: 10px;
					color: $cyan;
					content: '\e909';
				}
			}
		}
	}

	&__logo {
		ul {
			margin: 0;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			li {
				margin: 10px 5px;
				padding: 0 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 80px;
				border-radius: 4px;
				box-shadow: 0 2px 2px rgba($black, 0.04);

				img {
          padding: 5px 10px;
          width: auto;
          max-width: 138px;
          height: 56px;
				}
			}
		}
	}

	&--logo {
		padding-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		h6 {
			margin-bottom: 0;
			font-size: 16px;
		}

		ul {
			margin-bottom: 0;
		}
	}
}

.card-selling {
	margin-bottom: 10px;
	padding: 20px;
	position: relative;
	z-index: 1;
	width: 100%;
	border-radius: 8px 8px 0 0;
	background: $gray-300;
	@include media-breakpoint-up(xxl) {
		padding: 20px 30px 30px;
	}

	&__title {
		margin: -20px -20px 15px;
		padding: 20px;
		width: calc(100% + 40px);
		border-radius: 8px 8px 0 0;
		background: $blue;
		@include media-breakpoint-up(xxl) {
			margin: -20px -30px 15px;
			padding: 20px 30px;
			width: calc(100% + 60px);
		}

		h6 {
			margin-bottom: 0;
			font-weight: 600;
			color: $white;
		}
	}

	&__block {
		margin: 0 -20px 10px;
		padding: 0 20px 10px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: calc(100% + 40px);
		border-bottom: 1px solid $light-border;
		@include media-breakpoint-up(xxl) {
			margin: 0 -30px 15px;
			padding: 0 30px 15px;
			width: calc(100% + 60px);
		}

		&:hover {
			text-decoration: none;
		}

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}
	}

	&__block-img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 72px;
		height: 72px;
		border-radius: 4px;
		background: $white;
		box-shadow: 0 2px 2px rgba($black, 0.04);
		@include media-breakpoint-up(xxl) {
			width: 80px;
			height: 80px;
		}
	}

	&__block-text {
		padding-left: 10px;
		width: calc(100% - 72px);
		@include media-breakpoint-up(xxl) {
			padding-left: 15px;
			width: calc(100% - 80px);
		}

		h6 {
			margin-bottom: 5px;
			display: flex;
			align-items: center;
			font-size: 15px;
			line-height: 1.2;
			color: $blue;
			@include media-breakpoint-up(xl) {
				font-size: 18px;
			}

      span {
        flex: 1 0 0%;
        padding-right: 12px;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
      }

			&:not(.no-chevron):after {
        margin-left: 0;
				font-family: $font-family-icon;
				font-size: 12px;
				font-weight: 400;
				content: '\e90b';
			}
		}

		span {
			display: block;
			font-size: 14px;
			font-weight: 300;
			color: $blue;
			@include media-breakpoint-up(xl) {
				font-size: 16px;
			}
		}
	}
}

.document-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding-left: 0;

  > li {
    flex-basis: 150px;
    position: relative;
    cursor: pointer;

    img {
      object-fit: cover;
      width: 150px;
      height: 150px;
      vertical-align: middle;
      border-radius: 5px;
    }

    &::after {
      content: "";
      flex-basis: 150px;
    }

    .document-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(57, 57, 57, 0.502);
      top: 0;
      left: 0;
      transform: scale(0);
      transition: all 0.2s 0.1s ease-in-out;
      color: #fff;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &:hover .document-overlay {
      transform: scale(1);
    }
  }
}


.js-employee-slider {
  .slick-dots {
    margin: 0;
    padding: 0 0 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding-bottom: 60px;
    }

    li {
      margin: 0 4px;
      display: block;
      width: 10px;
      height: 10px;

      button {
        padding: 0;
        display: block;
        width: 10px;
        height: 10px;
        text-indent: -9999px;
        border: 0;
        border-radius: 50%;
        background: $teal;
        cursor: pointer;
      }

      &.slick-active {
        button {
          background: $cyan;
        }
      }
    }
  }
}

.house-types {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__tag {
    @include media-breakpoint-down(sm) {
      padding: 8px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-radius: 10px;
      background: $gray-300;

      &:before {
        content: 'Status';
      }
    }

    span {
      padding: 3px 8px;
      display: inline-block;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 2px;
      background: $green;

      &.status-option {
        background: $orange
      }
      &.status-oral-agreement {
        background: $orange;
      }
      &.status-sold {
        background: $red;
      }
    }
  }
}
}
.pageblock_14081 {
.house-location {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  h4 {
    margin-bottom: 25px;
  }

  &__map {
    position: relative;
    display: block;
    border-radius:  16px 16px 0 0;
    height: 400px;

    @include media-breakpoint-up(xl) {
      height: 650px;
    }

    .leaflet-popup-content {
      width: 200px !important;
    }

    .leaflet-div-icon {
      @include background-image(marker-blue, svg, center center, no-repeat);
      background-size: 32px;
      width: 32px !important;
      height: 64px !important;
      background-color: transparent;
      border: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .house-location__wrapper {
    position: relative;

    .house-location__filters {
      width: 100%;
      background-color: $white;
      padding: 40px;
      border-radius: 8px;
      z-index: 400;
      box-shadow: 0 2px 4px 0 rgba($black, .1);

      @include media-breakpoint-up(xl) {
        position: absolute;
        top: 32px;
        right: 32px;
        padding: 30px;
        width: 250px;
      }

      @include media-breakpoint-up(xxl) {
        width: 312px;
        padding: 40px;
      }

      h5 {
        margin-bottom: 8px;
      }
    }
  }
}
}
.pageblock_14082 {
.card-broker {
	margin-bottom: 10px;
	padding: 20px;
	position: relative;
	z-index: 1;
	width: 100%;
	background: $gray-300;
	@include media-breakpoint-up(xxl) {
		padding: 20px 30px 30px;
	}

	&--top {
		border-radius: 8px 8px 0 0;
	}

	&--bottom {
		border-radius: 0 0 8px 8px;
	}

	&__title {
		margin: -20px -20px 25px;
		padding: 20px;
		width: calc(100% + 40px);
		border-radius: 8px 8px 0 0;
		background: $blue;
		@include media-breakpoint-up(xxl) {
			margin: -20px -30px 25px;
			padding: 20px 30px;
			width: calc(100% + 60px);
		}

		h6 {
			margin-bottom: 0;
			font-weight: 600;
			color: $white;
		}
	}

	&__block {
		margin: 0 -20px 20px;
		padding: 0 20px 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: calc(100% + 40px);
		border-bottom: 1px solid $light-border;
		@include media-breakpoint-up(xxl) {
			margin: 0 -30px 30px;
			padding: 0 30px 20px;
			width: calc(100% + 60px);
		}
	}

	&__block-img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 80px;
		height: 80px;
		border-radius: 4px;
		background: $white;
		box-shadow: 0 2px 2px rgba($black, 0.04);
	}

	&__block-text {
		padding-left: 15px;
		width: calc(100% - 80px);

		span {
			display: flex;
			align-items: center;
			font-size: 16px;
			font-weight: 300;
			color: $pink;

			i {
				margin-right: 10px;
			}
		}
	}

	&__info {
		ul {
			margin: 0;
			padding: 0;

			li {
				margin-bottom: 10px;

				&:last-child {
					margin-bottom: 0;
				}

				span,
				a {
					padding-left: 35px;
					position: relative;
					display: inline-block;
					align-items: flex-start;
					font-size: $font-size-sm;
					color: $blue;

					small {
						display: block;
						font-size: 14px;
						color: $teal;
					}

					&:hover {
						text-decoration: none;
						color: $pink;
					}

					i {
						position: absolute;
						top: 3px;
						left: 0;
						width: 30px;
						color: $cyan;

						&.icon-mail {
							font-size: 12px;
						}
					}
				}
			}
		}
	}

	&__list {
		ul {
			margin: 0;
			padding: 0;
			width: 100%;

			li {
				margin-bottom: 3px;
				position: relative;
				width: 100%;
				font-size: 16px;
				color: $blue;
				border-right: 1px solid $gray-500;
				padding: {
					top: 4px;
					bottom: 4px;
					left: 25px;
				}

				&:last-child {
					margin: 0;
					border-right: 0;
				}

				&:before {
					position: absolute;
					top: 7px;
					left: 1px;
					font-family: $font-family-icon;
					font-size: 10px;
					color: $cyan;
					content: '\e909';
				}
			}
		}
	}

	&__logo {
		ul {
			margin: 0;
			padding: 0;
			display: flex;
			flex-wrap: wrap;
			width: 100%;

			li {
				margin: 10px 5px;
				padding: 0 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 80px;
				border-radius: 4px;
				box-shadow: 0 2px 2px rgba($black, 0.04);

				img {
          padding: 5px 10px;
          width: auto;
          max-width: 138px;
          height: 56px;
				}
			}
		}
	}

	&--logo {
		padding-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;

		h6 {
			margin-bottom: 0;
			font-size: 16px;
		}

		ul {
			margin-bottom: 0;
		}
	}
}

.card-selling {
	margin-bottom: 10px;
	padding: 20px;
	position: relative;
	z-index: 1;
	width: 100%;
	border-radius: 8px 8px 0 0;
	background: $gray-300;
	@include media-breakpoint-up(xxl) {
		padding: 20px 30px 30px;
	}

	&__title {
		margin: -20px -20px 15px;
		padding: 20px;
		width: calc(100% + 40px);
		border-radius: 8px 8px 0 0;
		background: $blue;
		@include media-breakpoint-up(xxl) {
			margin: -20px -30px 15px;
			padding: 20px 30px;
			width: calc(100% + 60px);
		}

		h6 {
			margin-bottom: 0;
			font-weight: 600;
			color: $white;
		}
	}

	&__block {
		margin: 0 -20px 10px;
		padding: 0 20px 10px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: calc(100% + 40px);
		border-bottom: 1px solid $light-border;
		@include media-breakpoint-up(xxl) {
			margin: 0 -30px 15px;
			padding: 0 30px 15px;
			width: calc(100% + 60px);
		}

		&:hover {
			text-decoration: none;
		}

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}
	}

	&__block-img {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 72px;
		height: 72px;
		border-radius: 4px;
		background: $white;
		box-shadow: 0 2px 2px rgba($black, 0.04);
		@include media-breakpoint-up(xxl) {
			width: 80px;
			height: 80px;
		}
	}

	&__block-text {
		padding-left: 10px;
		width: calc(100% - 72px);
		@include media-breakpoint-up(xxl) {
			padding-left: 15px;
			width: calc(100% - 80px);
		}

		h6 {
			margin-bottom: 5px;
			display: flex;
			align-items: center;
			font-size: 15px;
			line-height: 1.2;
			color: $blue;
			@include media-breakpoint-up(xl) {
				font-size: 18px;
			}

      span {
        flex: 1 0 0%;
        padding-right: 12px;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
      }

			&:not(.no-chevron):after {
        margin-left: 0;
				font-family: $font-family-icon;
				font-size: 12px;
				font-weight: 400;
				content: '\e90b';
			}
		}

		span {
			display: block;
			font-size: 14px;
			font-weight: 300;
			color: $blue;
			@include media-breakpoint-up(xl) {
				font-size: 16px;
			}
		}
	}
}

.js-employee-slider {
  .slick-dots {
    margin: 0;
    padding: 0 0 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding-bottom: 60px;
    }

    li {
      margin: 0 4px;
      display: block;
      width: 10px;
      height: 10px;

      button {
        padding: 0;
        display: block;
        width: 10px;
        height: 10px;
        text-indent: -9999px;
        border: 0;
        border-radius: 50%;
        background: $teal;
        cursor: pointer;
      }

      &.slick-active {
        button {
          background: $cyan;
        }
      }
    }
  }
}
}
.pageblock_14083 {
.project-slider {
	margin-bottom: 50px;
	position: relative;
	width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(lg) {
		margin-bottom: 80px;
	}

	&--background {
		background-image: linear-gradient(to bottom, rgba(250, 244, 239, 1) 0%, rgba(255, 253, 249, 1) 100%);
		@include media-breakpoint-up(xl) {
			padding: {
				top: 130px;
				bottom: 30px;
			};
		}
		padding: {
			top: 60px;
			bottom: 20px;
		};
	}

	&--background2 {
		margin-bottom: 60px;
		background: $gray-300;
		@include media-breakpoint-up(xl) {
			margin-bottom: 120px;
			padding: {
				top: 120px;
				bottom: 120px;
			};
		}
		padding: {
			top: 60px;
			bottom: 60px;
		};
	}

	&__top {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&__top-left {
		display: inline-flex;
		align-items: center;

		h4 {
			margin-bottom: 0;
		}
	}

	&__number {
		margin-right: 15px;
		padding: 0 15px;
		display: inline-flex;
		align-items: center;
		height: 32px;
		font-size: 14px;
		color: $pink;
		border-radius: 40px;
		background: $gray-300;
		@include media-breakpoint-up(xl) {
			margin-right: 25px;
			padding: 0 25px;
			height: 48px;
		}
	}

	&__slider {
		position: relative;
		width: 100%;

		&:after {
			@include media-breakpoint-up(xxxl) {
				position: absolute;
				top: 0;
				left: 100%;
				width: 70000px;
				height: 100%;
				background: rgba($light-alt, 0.5);
				content: '';
			}
		}
	}

	&__slide {
		margin-right: 10px;
		width: 350px;
		@include media-breakpoint-up(xl) {
			margin-right: 16px;
			width: 528px;
		}

    .card-house {
      .card-house__bar .card-house__bar-inner {
        span {
          left: 0;
          border-radius: 0 4px 0 0;
        }
      }
    }
	}

	&__holder {
		position: relative;
		width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
	}

	&__next,
	&__prev {
		display: none !important;
		@include media-breakpoint-up(xl) {
			position: absolute;
			top: 115px;
			z-index: 3;
			display: flex !important;
			align-content: center;
			align-items: center;
			width: 40px;
			height: 140px;
			font-size: 13px;
			color: $blue;
			border: 0;
			background: transparent;
			transition: all 0.3s;
			cursor: pointer;
		}

		&.slick-disabled {
			opacity: 0;
			visibility: hidden;
		}

		&:hover {
			text-decoration: none;
			color: $blue;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 0;
			height: 0;
			border-style: solid;
			content: '';
		}
	}

	&--step {
		z-index: 9;

		.project-slider__slider {
			&:after {
				display: none;
			}
		}
	}

	&__prev {
		padding-left: 12px;
		left: 0;

		&:after {
			border-color: transparent transparent transparent $white;
			border-width: 70px 0 70px 40px;
		}
	}

	&__next {
		padding-left: 15px;
		right: 0;

		&:after {
			border-color: transparent $white transparent transparent;
			border-width: 70px 40px 70px 0;
		}
	}

	&--inner {
		.card-house__list {
			padding: 8px 0;
		}
	}
}

.card-house {
	position: relative;
	display: block;
	width: 100%;

  a:hover {
    text-decoration: none;
  }

	&:hover {
		text-decoration: none;
	}

	&__content {
		padding-top: 15px;
		width: 100%;
		font-size: $font-size-sm;
		font-weight: 300;
		color: $teal;

		h6 {
			margin-bottom: 8px;
			@include media-breakpoint-up(lg) {
				margin-bottom: 16px;
			}
		}
	}

	&__list {
		margin: 0;
		padding: 0 0 10px;
		display: flex;
		flex-wrap: wrap;
		font-size: 14px;
		font-weight: 300;
		@include media-breakpoint-up(lg) {
			padding-bottom: 12px;
			font-size: $font-size-sm;
		}
	}

	&__list-item {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;

		&:after {
			margin: 0 10px;
			display: inline-block;
			width: 4px;
			height: 4px;
			background: $teal;
			transform: rotate(45deg);
			content: '';
			@include media-breakpoint-up(xl) {
				margin: 0 15px;
			}
		}

    &.city {
      font-size: 20px;
      font-weight: 500;
    }

		a {
			text-decoration: none;
			color: $cyan;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	&__bar {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 4px;
		background: rgba(242, 232, 225);
	}

	&__bar-inner {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0;
		//width: attr(data-percentage %); // not supported yet


		height: 4px;
		background: $cyan;

		span {
			padding: 0 10px;
			position: absolute;
			bottom: calc(100% + 15px);
			left: calc(100% - 50px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 100px;
			height: 26px;
			font-size: 12px;
			text-align: center;
			color: $blue;
			border-radius: 4px 4px 0 0;
			background: $white;

			&:after {
				margin-left: -4px;
				position: absolute;
				bottom: -4px;
				left: 50%;
				width: 0;
				height: 0;
				border-color: $white transparent transparent transparent;
				border-style: solid;
				border-width: 5px 4px 0;
				content: '';
			}
		}
	}

	&__heart {
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 2;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		font-size: 10px;
		color: $red;
		border-radius: 50%;
		background: $white;
		transition: all 0.3s;
		@include media-breakpoint-up(xl) {
			top: 26px;
			right: 26px;
		}

    i.icon-heart-alt {
      color: $blue;
    }

		&.is-active,
		&:hover {
			text-decoration: none;

			i {
				&:before {
					content: '\e919';
          color: $blue;

				}

        &.icon-heart-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
			}
    }
	}

	&__image {
		position: relative;
		display: block;
		width: 100%;
		border-radius: 8px;
		overflow: hidden;

		&:hover {
			text-decoration: none;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 39%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 100%);
			content: '';
		}

		picture,
		img {
			display: block;
			width: 100%;
			border-radius: 8px;
      aspect-ratio: 3/2;
      object-fit: cover;
    }
	}

	&__label {
		position: absolute;
		top: 22px;
		left: 0;
		z-index: 1;

		span {
			padding: 0 20px 0 12px;
			display: inline-flex;
			align-items: center;
			height: 24px;
			font-size: 12px;
			font-weight: 600;
			color: $white;
			background: $cyan;
			clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
		}
	}

	&__price {
		color: $cyan;
	}
}

}
.pageblock_14085 {
input[name="office_id"] {
    padding: initial;
    padding-left: inherit;
}
}
.pageblock_15147 {
.js-employee-slider {
  .slick-dots {
    margin: 0;
    padding: 0 0 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding-bottom: 60px;
    }

    li {
      margin: 0 4px;
      display: block;
      width: 10px;
      height: 10px;

      button {
        padding: 0;
        display: block;
        width: 10px;
        height: 10px;
        text-indent: -9999px;
        border: 0;
        border-radius: 50%;
        background: $teal;
        cursor: pointer;
      }

      &.slick-active {
        button {
          background: $cyan;
        }
      }
    }
  }
}

.card-broker {
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  z-index: 1;
  width: 100%;
  background: $gray-300;
  @include media-breakpoint-up(xxl) {
    padding: 20px 30px 30px;
  }

  &--top {
    border-radius: 8px 8px 0 0;
  }

  &--bottom {
    border-radius: 0 0 8px 8px;
  }

  &__title {
    margin: -20px -20px 25px;
    padding: 20px;
    width: calc(100% + 40px);
    border-radius: 8px 8px 0 0;
    background: $blue;
    @include media-breakpoint-up(xxl) {
      margin: -20px -30px 25px;
      padding: 20px 30px;
      width: calc(100% + 60px);
    }

    h6 {
      margin-bottom: 0;
      font-weight: 600;
      color: $white;
    }
  }

  &__block {
    margin: 0 -20px 20px;
    padding: 0 20px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% + 40px);
    border-bottom: 1px solid $light-border;
    @include media-breakpoint-up(xxl) {
      margin: 0 -30px 30px;
      padding: 0 30px 20px;
      width: calc(100% + 60px);
    }
  }

  &__block-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 2px 2px rgba($black, 0.04);
  }

  &__block-text {
    padding-left: 15px;
    width: calc(100% - 80px);

    span {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 300;
      color: $pink;

      i {
        margin-right: 10px;
      }
    }
  }

  &__info {
    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        span,
        a {
          padding-left: 35px;
          position: relative;
          display: inline-block;
          align-items: flex-start;
          font-size: $font-size-sm;
          color: $blue;

          small {
            display: block;
            font-size: 14px;
            color: $teal;
          }

          &:hover {
            text-decoration: none;
            color: $pink;
          }

          i {
            position: absolute;
            top: 3px;
            left: 0;
            width: 30px;
            color: $cyan;

            &.icon-mail {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  &__list {
    ul {
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        margin-bottom: 3px;
        position: relative;
        width: 100%;
        font-size: 16px;
        color: $blue;
        border-right: 1px solid $gray-500;
        padding: {
          top: 4px;
          bottom: 4px;
          left: 25px;
        }

        &:last-child {
          margin: 0;
          border-right: 0;
        }

        &:before {
          position: absolute;
          top: 7px;
          left: 1px;
          font-family: $font-family-icon;
          font-size: 10px;
          color: $cyan;
          content: '\e909';
        }
      }
    }
  }

  &__logo {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        margin: 10px 5px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        border-radius: 4px;
        box-shadow: 0 2px 2px rgba($black, 0.04);

        img {
          padding: 5px 10px;
          width: auto;
          max-width: 138px;
          height: 56px;
        }
      }
    }
  }

  &--logo {
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    h6 {
      margin-bottom: 0;
      font-size: 16px;
    }

    ul {
      margin-bottom: 0;
    }
  }
}

.card-selling {
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  z-index: 1;
  width: 100%;
  border-radius: 8px 8px 0 0;
  background: $gray-300;
  @include media-breakpoint-up(xxl) {
    padding: 20px 30px 30px;
  }

  &__title {
    margin: -20px -20px 15px;
    padding: 20px;
    width: calc(100% + 40px);
    border-radius: 8px 8px 0 0;
    background: $blue;
    @include media-breakpoint-up(xxl) {
      margin: -20px -30px 15px;
      padding: 20px 30px;
      width: calc(100% + 60px);
    }

    h6 {
      margin-bottom: 0;
      font-weight: 600;
      color: $white;
    }
  }

  &__block {
    margin: 0 -20px 10px;
    padding: 0 20px 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% + 40px);
    border-bottom: 1px solid $light-border;
    @include media-breakpoint-up(xxl) {
      margin: 0 -30px 15px;
      padding: 0 30px 15px;
      width: calc(100% + 60px);
    }

    &:hover {
      text-decoration: none;
    }

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

  &__block-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 2px 2px rgba($black, 0.04);
    @include media-breakpoint-up(xxl) {
      width: 80px;
      height: 80px;
    }
  }

  &__block-text {
    padding-left: 10px;
    width: calc(100% - 72px);
    @include media-breakpoint-up(xxl) {
      padding-left: 15px;
      width: calc(100% - 80px);
    }

    h6 {
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      font-size: 15px;
      line-height: 1.2;
      color: $blue;
      @include media-breakpoint-up(xl) {
        font-size: 18px;
      }

      span {
        flex: 1 0 0%;
        padding-right: 12px;
        font-size: inherit;
        font-weight: inherit;
        color: inherit;
      }

      &:not(.no-chevron):after {
        margin-left: 0;
        font-family: $font-family-icon;
        font-size: 12px;
        font-weight: 400;
        content: '\e90b';
      }
    }

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
      color: $blue;
      @include media-breakpoint-up(xl) {
        font-size: 16px;
      }
    }
  }
}
}
.pageblock_14094 {
.hero {
  margin: 0 auto 50px;
  padding-top: 95px;
  position: relative;
  width: 100%;
  max-width: 1920px;

  @include media-breakpoint-up(lg) {
    padding-top: 124px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
    padding-top: 160px;

  }

  @include media-breakpoint-up(xxxl) {
    padding-right: 40px;
    padding-left: 40px;
  }

  &__holder {
    position: relative;
    width: 100%;
    height: 320px;
    border-radius: 0 0 16px 16px;
    @include media-breakpoint-up(sm) {
      height: 400px;
    }
    @include media-breakpoint-up(md) {
      height: 500px;
    }
    @include media-breakpoint-up(lg) {
      height: 600px;
    }
    @include media-breakpoint-up(xxl) {
      height: 550px;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 0 0 16px 16px;
      background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      content: '';
      @include media-breakpoint-up(xl) {
        @include background-image(hero-overlay, svg, center center, no-repeat);
      }
    }

    &:before {
      @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        left: 0;
        width: 417px;
        height: 100%;
        content: '';
        @include background-image(hero-overlay2, svg, right top, no-repeat, cover);
      }
    }
  }

  &__content {
    position: absolute;
    bottom: 60px !important;
    top: auto !important;
    left: 0;
    z-index: 2;
    width: 100%;
    color: $white;
    @include media-breakpoint-up(md) {
      bottom: 160px !important;
    }
    @include media-breakpoint-up(xl) {
      bottom: 80px !important;
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }
      @include media-breakpoint-down(sm) {
        font-size: 2rem;
      }
    }
  }

  &__block {
    margin: -50px 0 0 20px;
    position: relative;
    z-index: 1;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    color: $white;
    border-radius: 10px 0 0 10px;
    background: $blue;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 370px;
    }
    @include media-breakpoint-up(xl) {
      width: 480px;
    }
    @include media-breakpoint-up(xxxl) {
      right: 0;
    }
  }

  &__block-img {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 112px;
    @include background-image(hero-block-bg, svg, center right, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      width: 160px;
      height: 152px;
    }

    img {
      max-height: 70px;
      @include media-breakpoint-up(xl) {
        max-height: 92px;
      }
    }
  }

  &__block-text {
    padding-left: 20px;
    width: calc(100% - 120px);
    font-size: 12px;
    @include media-breakpoint-up(xl) {
      padding-left: 35px;
      width: calc(100% - 160px);
      font-size: $font-size-sm;
    }

    strong {
      margin-bottom: 5px;
      display: block;
      font-size: $font-size-sm;
      font-weight: 700;
      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }

    p {
      margin-bottom: 5px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 15px;
      }
    }

    span {
      display: block;
      font-weight: 600;
      color: $cyan;
    }

    a {
      color: $cyan;
      &:hover {
        color: $cyan;
      }
    }
  }

  &__search {
    max-width: 550px;
    width: calc(100% - 15px);

    @include media-breakpoint-up(xl) {
      margin-top: 20px;
    }

    @include media-breakpoint-up(xxl) {
      width: 666px;
    }


    input, .card-search label {
      @include media-breakpoint-down(md) {
        height: 60px;
      }
    }

    button {
      @include media-breakpoint-down(md) {
        top: 6px;
      }
    }

    .card-search {
      &::after {
        @include media-breakpoint-down(md) {
          border-width: 30px 0 30px 20px;
        }
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border-radius: 0 0 16px 16px;

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 0 0 16px 16px;
      object-fit: cover;
    }
  }
}

}
.pageblock_14096 {
// Styling for the step option card
.card-assistance {
  margin-bottom: 8px;
  padding: 16px 20px;
  display: flex;
  width: 100%;
  text-align: center;
  border: 2px solid $white;
  border-radius: 16px;
  background: $white;
  min-height: 80px;
  transition: all 0.3s;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba($orange, .3);
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    transition: .3s ease;

  }

  .card-assistance__icon,
  h5,
  span {
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-down(md) {
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    display: block;
    padding: 0 0 24px;
    margin-bottom: 25px;
  }

  &:hover {
    text-decoration: none;
    border-color: $orange;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    transform: scale(1.1);
    z-index: 2;
    position: relative;

    &::before {
      transform: translateX(0);
    }
  }

  h5 {
    @include media-breakpoint-down(md) {
      margin-bottom: 0 !important;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
      margin-top: 12px;
      font-size: 18px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 21px;
    }
  }

  &--title {

    @include media-breakpoint-up(md) {
      padding-top: 32px;
      padding-bottom: 40px;
    }
  }

  &__icon {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: fit-content;

    @include media-breakpoint-down(md) {
      margin-right: 20px;
      padding-top: 0 !important;
      width: 32px;
      height: auto !important;
    }

    @include media-breakpoint-up(md) {
      padding-top: 20px;
      height: 80px;
      width: 100%;
    }
  }

  .btn {
    transition: .3se ease;
    @include media-breakpoint-down(md) {
      position: absolute;
      right: 28px;
    }
  }
}

// Active state for the step option card
.step-option-button {
  @include media-breakpoint-down(md) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
  }

  span {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  i {
    @include media-breakpoint-up(md) {
      display: none !important;
    }
  }

  i.icon-cross {
    transform: rotate(45deg);
  }

  i.icon-check {
    display: none;
  }
}

input.step-option-input:checked + label {
  text-decoration: none;
  border-color: $orange;
  box-shadow: 0 16px 48px rgba($black, 0.12);

  &::before {
    transform: translateX(0);
  }

  .step-option-button {
    background-color: $orange;
    border-color: $orange;
    color: $white;

    i.icon-cross {
      display: none;
    }

    i.icon-check {
      display: inline;
    }
  }
}

.card-postcode {
  margin-bottom: 30px;
  padding: 20px 35px;
  width: 100%;
  font-size: $font-size-sm;
  border-radius: 16px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(lg) {
    padding: 10px;
  }

  &__content {
    padding: 15px 0;
    @include media-breakpoint-up(lg) {
      padding: 35px 10px;
    }
  }

  &__map {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h6 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
  }
}

// begin livingwish popup
.onboarding-popup {
  width: 100%;
}

.housingneeds-popup {
  .mfp-close {
    display: none;
  }
}

.mfp-fullscreen {
  .mfp-container {
    padding: 0; /* 1 */
  }
  .mfp-content {
    height: 100%; /* 2 */
  }
}

.full-popup {
  background: #FFF;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
}
// end livingwish popup

.step-heading {
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 160px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
    padding-bottom: 60px;
  }

  @include media-breakpoint-up(xxxl) {
    padding-top: 200px;
  }

  h2 {
    color: $white;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-weight: 600;
    color: $cyan;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% + 100px);
    background-color: $blue;
    content: '';
    @include background-image(step-bg, svg, center top, no-repeat, cover);
    @include media-breakpoint-up(xl) {
      height: calc(100% + 160px);
    }
  }
}

.step-pagination {
  margin: -20px 0 30px 0;
  position: relative;
  width: 100%;
  bottom: 0;

  @include media-breakpoint-up(xl) {
    margin: -100px 0 50px;
  }

  .btn {
    margin: 0 4px;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__list-item {
    margin: 0 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
    color: $blue;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      height: 48px;
      width: 48px;
      color: $teal;
    }

    &:after, &::before {
      margin-top: -1px;
      position: absolute;
      top: 50%;
      left: 100%;
      width: 16px;
      height: 2px;
      transition: .3s ease;
      content: '';
    }

    &:after {
      background: rgba(248, 241, 234, 1);
    }

    &::before {
      width: 0;
      transition: .5s ease;
      transition-delay: .5s;
      z-index: 1;
      background-image: linear-gradient(to right, rgba(0, 176, 167, 1) 0%, rgba(237, 237, 238, 1) 100%);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      text-decoration: none;
      color: $blue;
      border-radius: 50%;
      background: $gray-300;
      position: relative;
      overflow: hidden;
      transition: .3s ease;

      @include media-breakpoint-up(md) {
        width: 48px;
        height: 48px;
      }

      &::before {
        content: '';
        background: $cyan;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: .3s ease;
        transition-delay: .3s;
        width: 100%;
        height: 100%;
      }

      span {
        position: relative;
        z-index: 1;
      }

      &:hover {
        color: $white;
        background: $cyan;
      }
    }

    &.is-selected {
      a {
        color: $cyan;
        background: $gray-300;
      }
    }

    &.is-active {
      a {
        background-color: $gray-300 !important;
        span {
          color: $white;
        }

        &::before {
          transform: translateX(0);
        }
      }

      &:not(:last-child)::before {
        width: 16px;
      }

      &:not(:last-child)::after {
        background: rgba(248, 241, 234, 1) !important;
      }
    }

    &.is-done {
      a {
        color: $white;
        background: $cyan;
      }

      &:after {
        background-color: $cyan;
      }
    }

    &.is-disabled {
      a {
        color: $white;
        background: $gray-500;
        cursor: default;
      }
    }

    &.is-blocked {
      display: none;
    }

    &.is-blocked.is-done {
      a {
        color: $white;
        background: $cyan;
      }
    }
  }
}

// begin step-section
.livingwish-popup {

  .hidden {
    display: none;
  }

  .wrapper {
    overflow: scroll;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 32px;
    }
  }
}

.step {
  display: none;
  transition: .3s ease;
  width: 100%;
  height: 100%;

  &:not(.hidden) {
    display: block;

    .step-pagination {
      li.is-active {
        a {
          &::before {
            transform: translateX(0);
          }
        }

        &::before {
          width: 16px;
        }
      }
    }

    .step-section {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .step-section {
    margin-bottom: 60px;
    position: relative;
    z-index: 6;
    width: 100%;
    // inactive
    opacity: 0;
    transform: translateX(-16px);
    transition: .3s ease;

    @include media-breakpoint-up(xl) {
      margin-bottom: 120px;
    }

    &__list {
      margin: 0 -8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: calc(100% + 16px);
    }

    &__list-item {
      padding: 0 8px;
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 33.333%;
      }
      @include media-breakpoint-up(xl) {
        width: 25%;
      }
      @include media-breakpoint-up(xxl) {
        width: 20%;
      }
    }

    .col-lg-3 {
      &:nth-child(1) {
        .step-image { // Hypotheek afsluiten
          background-image: url('../../assets/images/services-hypotheek-afsluiten.png');
        }
      }

      &:nth-child(2) {
        .step-image { // Verzekeringen
          background-image: url('../../assets/images/services-verzekeringen.png');
        }
      }

      &:nth-child(3) {
        .step-image { // Taxatie
          background-image: url('../../assets/images/services-taxatie.png');
        }
      }

      &:nth-child(4) {
        .step-image { // Verkopen
          background-image: url('../../assets/images/services-verkopen.png');
        }
      }

      &:nth-child(5) {
        .step-image { // Kopen
          background-image: url('../../assets/images/services-makelaar.png');
        }
      }

      &:nth-child(6) {
        .step-image { // Huren
          background-image: url('../../assets/images/services-huren.png');
        }
      }

      :nth-child(7) {
        .step-image { // Makelaar
          background-image: url('../../assets/images/services-makelaar.png');
        }
      }
    }
  }
}

.step-section__select {
  border-radius: 16px;
  background-color: $white;
  padding: 32px;
  text-align: center;
  box-shadow: 0 8px 8px 0 rgba($black, .08);

  @include media-breakpoint-up(xl) {
    padding: 64px 0 32px;
    max-width: 800px;
    margin: 0 auto;
  }

  h4 {
    text-align: center;
  }

  .select-wrapper {
    margin: 24px auto 16px;
    max-width: 530px;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
    }

    @include media-breakpoint-up(xl) {
      margin: 40px auto 32px;
    }

    select {
      @include media-breakpoint-up(sm) {
        width: calc(50% - 8px);
      }

      &:first-child {
        @include media-breakpoint-down(md) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: fit-content;
    }
  }
}
// end step-section
}
.pageblock_14097 {
.onboarding-section {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__holder {
    margin: 0 -20px;
    padding: 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 40px);
    border-radius: 15px;
    background: $gray-400;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 22px;
      width: 100%;
    }
  }

  &__left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    text-align: center;
    @include media-breakpoint-up(lg) {
      width: 60%;
      text-align: left;
    }
  }

  &__right {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: flex-end;
      width: 40%;
    }
  }

  &__image {
    padding-right: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 122px;
    height: 96px;
    @include background-image(onboarding-bg, svg, center center, no-repeat, 100% 100%);

    img {
      max-height: 60px;
    }
  }

  &__text {
    padding-top: 10px;
    width: 100%;
    font-size: $font-size-sm;
    @include media-breakpoint-up(lg) {
      width: calc(100% - 122px);
    }
    @include media-breakpoint-up(xl) {
      padding-left: 50px;
    }

    p {
      @include media-breakpoint-up(lg) {
        margin-bottom: 10px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: auto;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 50px;
    }
  }

  &__list-item {
    margin: 0 3px;
    @include media-breakpoint-up(md) {
      margin-right: 0;
      margin-left: 8px;
    }

    .btn-light {
      color: $blue;
      border-color: $white;
      background: $white;

      &:hover {
        color: $white;
        border-color: $cyan;
        background: $cyan;
      }
    }
  }

  &__list-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-weight: 600;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    &:hover {
      text-decoration: none;
      color: $white;
      background: $cyan;
    }
  }
}

}
.pageblock_14098 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14099 {
.usp-slider {
  margin-bottom: 56px;
  position: relative;
  width: 100%;
  overflow: hidden;

  &.usp-slider--alt {
    .usp-slider__title {
      strong {
        color: $cyan;
      }
    }

    .usp-slider__slide {
      margin-right: 20px;
    }

    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        .card-usp {
          &,
          &__content {
            height: 100%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__slider {
    position: relative;
    width: 100%;
  }

  &__title {
    margin-bottom: 30px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 50px;
    }

    strong {
      color: $pink;
    }
  }

  h3 {
    line-height: 1;
  }

  &__slide {
    margin-right: 30px;
    width: 240px;
    @include media-breakpoint-up(xl) {
      margin-right: 20px;
      width: 300px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: 30px;
      width: 320px;
    }
    @include media-breakpoint-up(xxxl) {
      margin-right: 70px;
      width: 340px;
    }
  }
}

.card-usp {
  position: relative;
  display: block;
  width: 100%;

  &.card-usp--alt {
    .card-usp__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 16px;
      background-color: $gray-300;
      padding: 32px;

      p {
        padding-bottom: 32px;
      }
    }

    .btn {
      margin-top: auto;
    }
  }

  &__image {
    margin-bottom: 15px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102px;
    height: 80px;
    @include background-image(menu-bg, svg, center center, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      margin-bottom: 35px;
      width: 155px;
      height: 120px;
    }

    img {
      max-height: 50px;
      @include media-breakpoint-up(xl) {
        max-height: 76px;
      }
    }
  }

  &__content {
    p {
      margin-bottom: 0;
      padding: 16px 0;
      font-size: $font-size-sm;
    }
  }
}
}
.pageblock_14100 {
.story-slider {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__slide {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: 640px;
      border-radius: 16px 0 0 16px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        clip-path: polygon(0 0, calc(100% - 185px) 0%, 100% 50%, calc(100% - 185px) 100%, 0 100%);
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    right: 0;
    width: 366px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      clip-path: polygon(185px 0%, calc(100% - 185px) 0%, 100% 50%, calc(100% - 185px) 100%, 185px 100%, 0% 50%);
    }
  }

  &__slider {
    position: relative;
    width: 100%;

    .slick-dots {
      margin: 0;
      padding: 0;
      position: absolute;
      bottom: 120px;
      left: 25px;
      display: flex;
      flex-wrap: wrap;

      li {
        margin-right: 8px;
        width: 10px;
        height: 10px;

        button {
          padding: 0;
          display: block;
          width: 10px;
          height: 10px;
          text-indent: -9999px;
          border: 1px solid rgba($pink, 0.5);
          border-radius: 50%;
          background: transparent;
          cursor: pointer;
        }

        &.slick-active {
          button {
            background: $purple;
          }
        }
      }
    }

    &:after {
      @include media-breakpoint-up(xxxl) {
        position: absolute;
        top: 0;
        left: 100%;
        width: 70000px;
        height: 100%;
        background: rgba($light-alt, 0.8);
        content: '';
      }
    }
  }

  &__holder {
    margin: 0 -20px;
    position: relative;
    width: calc(100% + 40px);
    @include media-breakpoint-up(lg) {
      margin: 0;
      width: 100%;
    }
  }

  &__block {
    margin-top: -100px;
    padding: 25px;
    position: relative;
    z-index: 9;
    width: calc(100% - 20px);
    max-width: 370px;
    font-size: $font-size-sm;
    color: $white;
    @include background-image(story-bg, svg, center center, no-repeat, 100% 100%);
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      position: absolute;
      bottom: 25px;
      left: 25px;
      max-width: 428px;
    }
    @include media-breakpoint-up(xl) {
      top: 40px;
      bottom: auto;
      left: 40px;
    }
    @include media-breakpoint-up(xxl) {
      padding: 40px;
      top: 65px;
      left: 65px;
    }

    h5 {
      margin-bottom: 16px;
      color: $white;
    }
  }

  &__next,
  &__prev {
    display: none !important;
    @include media-breakpoint-up(xl) {
      margin-top: -28px;
      position: absolute;
      top: 50%;
      z-index: 3;
      display: flex !important;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 56px;
      height: 56px;
      font-size: 10px;
      color: $cyan;
      border-radius: 50%;
      background: $white;
      transition: all 0.3s;
      cursor: pointer;
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }

  &__prev {
    left: 50px;
    display: none !important;
  }

  &__next {
    right: 150px;
  }
}

}
.pageblock_14101 {
.cta-house {
  margin: 0 auto 60px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__holder {
    padding: 20px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }
    @include media-breakpoint-up(xl) {
      padding: 80px 0;
    }
  }

  &__left {
    position: relative;
    z-index: 5;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-right: -20px;
      width: calc(50% + 20px);
    }
    @include media-breakpoint-up(xxl) {
      margin-right: -50px;
      width: calc(50% + 50px);
    }
  }

  &__right {
    position: relative;
    z-index: 1;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-left: -20px;
      padding-left: 50px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
      width: calc(50% + 20px);
      border-radius: 0 16px 16px 0;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 100px;
    }
    @include media-breakpoint-up(xxl) {
      margin-left: -50px;
      padding-right: 100px;
      padding-left: 100px;
      width: calc(50% + 50px);
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(22, 21, 73, 1) 0%, rgba(46, 45, 92, 1) 100%);
      content: '';
      @include media-breakpoint-up(lg) {
        background-image: linear-gradient(to right, rgba(22, 21, 73, 1) 0%, rgba(46, 45, 92, 1) 100%);
        clip-path: polygon(100px 0%, 100% 0, 100% 100%, 100px 100%, 0% 50%);
      }
      @include media-breakpoint-up(xl) {
        clip-path: polygon(155px 0%, 100% 0, 100% 100%, 155px 100%, 0% 50%);
      }
    }
  }

  &__slide {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: 538px;
      border-radius: 16px 0 0 16px;
    }
    @include media-breakpoint-up(xl) {
      height: 538px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        clip-path: polygon(0 0, calc(100% - 100px) 0%, 100% 50%, calc(100% - 100px) 100%, 0 100%);
      }
      @include media-breakpoint-up(xl) {
        clip-path: polygon(0 0, calc(100% - 155px) 0%, 100% 50%, calc(100% - 155px) 100%, 0 100%);
      }
    }
  }

  &__content {
    padding: 20px;
    position: relative;
    color: $white;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 50px;
      background: transparent;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
    }

    h6 {
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 35px;
      }
    }
  }

  &__block {
    padding: 5px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 10px;
      right: auto;
      bottom: 40px;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 500;
        color: $cyan;
      }
    }
  }

  &__next,
  &__prev {
    display: none !important;
    @include media-breakpoint-up(xl) {
      margin-top: -70px;
      position: absolute;
      top: 50%;
      z-index: 3;
      display: flex !important;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 140px;
      font-size: 13px;
      color: $blue;
      background: $white;
      transition: all 0.3s;
      clip-path: polygon(40px 0%, calc(100% - 40px) 0%, 100% 50%, calc(100% - 40px) 100%, 40px 100%, 0% 50%);
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:after {
      width: 0;
      height: 0;
      border-color: transparent transparent transparent $cyan;
      border-style: solid;
      border-width: 8px 0 8px 10px;
      content: '';
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }

  &__prev {
    left: 20px;

    &:after {
      border-color: transparent $cyan transparent transparent;
      border-width: 8px 10px 8px 0;
    }
  }

  &__next {
    right: 20px;
  }
}

}
.pageblock_14102 {
.card-house {
  a:hover {
    text-decoration: none;
  }
}

.card-house__image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
}
.pageblock_14110 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14651 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14115 {
.expertises-wrapper {
  h2 {
    margin-bottom: 16px;
    margin-top: 50px;

    strong {
      color: $pink;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 32px;
    }
  }

  .expertises {
    margin-bottom: 40px;

    @include media-breakpoint-up(md) {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }

    @include media-breakpoint-up(xl) {
      gap: 16px;
      margin-bottom: 80px;
    }

    .expertises__item {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      font-weight: 300;
      background-color: $gray-300;
      color: $white;
      border-radius: 8px;
      align-items: center;
      transition: .3s ease;
      margin-bottom: 8px;

      &:hover {
        background-color: $light-border;
      }

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        width: calc(50% - 8px);
      }

      @include media-breakpoint-up(xl) {
        width: calc(50% - 16px);
      }

      .expertises__item-img {
        padding-right: 10px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 104px;
        height: 80px;
        @include background-image(menu-bg-dark, svg, center center, no-repeat);

        img {
          max-height: 50px;
        }
      }

      .expertises__item-text {
        padding-right: 10px;
        padding-left: 10px;
        width: calc(100% - 104px);

        strong {
          margin-bottom: 5px;
          display: inline-flex;
          flex-wrap: wrap;
          align-items: center;
          font-weight: 500;
          color: $blue;
        }
      }

      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
  }

  &.faq-section {
    .faq-section__item {
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.content {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__article {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }

    h5 {
      margin-bottom: 16px;
    }

    p {
      a {
        text-decoration: underline;
        color: $cyan;

        &:hover {
          color: $blue;
        }
      }
    }
  }

  &__video {
    position: relative;
    display: block;
    width: 100%;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 38px;
      font-family: $font-family-icon;
      font-size: 12px;
      color: $white;
      border-radius: 8px;
      background: $cyan;
      transform: translate(-50%, -50%);
      content: '\e933';
      box-shadow: 0 8px 8px rgba($black, 0.08);
      @include media-breakpoint-up(xl) {
        width: 72px;
        height: 48px;
        font-size: 16px;
      }
    }

    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }
}

.faq-section {
  --size-link-asset: 32px;
  margin-bottom: 60px;
  position: relative;
  width: 100%;

  @include media-breakpoint-up(lg) {
    --size-link-asset: 54px;
    margin-bottom: 120px;
  }

  h2 {
    margin-bottom: 20px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
    }

    strong {
      color: $pink;
    }
  }

  &__list {
    &--with-asset {
      .faq-section__panel {
        padding-left: calc(var(--size-link-asset) + 12px);
      }
    }
  }

  &__top {
    color: $blue;
    @include media-breakpoint-up(lg) {
      color: $pink;
    }
  }

  &__holder {
    margin-bottom: 20px;
    padding: 25px;
    width: 100%;
    border-radius: 8px;
    background-color: $gray-300;
    @include media-breakpoint-up(md) {
      padding: 45px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 40px;
      padding: 85px;
      border-radius: 16px;
    }

    h3 {
      margin-bottom: 25px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 50px;
      }
    }
  }

  &__item {
    margin-bottom: 18px;
    padding-bottom: 18px;
    border-bottom: 1px solid $light;

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0;
    }

    &.is-active {
      .faq-section__link {
        i {
          transform: rotate(-180deg);
        }
      }
    }
  }

  &__link {
    padding: 10px 35px 10px 0;
    position: relative;
    display: block;
    font-weight: 600;
    color: $blue;

    @include media-breakpoint-up(lg) {
      padding: 0px 30px 0px 0;
    }

    &:not(.js-faq-link) {
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;

      &__asset {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--size-link-asset);
        height: var(--size-link-asset);
        margin-right: 12px;
        border-radius: 50%;
        background-color: $light;

        img {
          max: {
            width: 60%;
            height: 60%;
          }
        }
      }
    }

    i {
      position: absolute;
      top: 13px;
      right: 0;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      font-size: 8px;
      transition: all 0.3s;
      @include media-breakpoint-up(lg) {
        margin-top: -15px;
        top: 50%;
        width: 30px;
        height: 30px;
        font-size: 10px;
      }
    }

    &.js-faq-link {
      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
  }

  &__panel {
    display: none;
    @include media-breakpoint-up(xl) {
      padding-right: 100px;
    }

    p {
      margin-bottom: 0;
      font-size: 16px;
    }
  }

  &--inner {
    padding-top: 20px;
    @include media-breakpoint-up(xl) {
      padding-top: 80px;
    }

    .faq-section__holder {
      @include media-breakpoint-up(xl) {
        padding: 30px 60px;
      }
    }

    &:after {
      display: none;
    }
  }
}

.card-broker {
  margin-bottom: 10px;
  padding: 20px;
  position: relative;
  z-index: 1;
  width: 100%;
  background: $gray-300;
  @include media-breakpoint-up(xxl) {
    padding: 20px 30px 30px;
  }

  &--top {
    border-radius: 8px 8px 0 0;
  }

  &--bottom {
    border-radius: 0 0 8px 8px;
  }

  &__title {
    margin: -20px -20px 25px;
    padding: 20px;
    width: calc(100% + 40px);
    border-radius: 8px 8px 0 0;
    background: $blue;
    @include media-breakpoint-up(xxl) {
      margin: -20px -30px 25px;
      padding: 20px 30px;
      width: calc(100% + 60px);
    }

    h6 {
      margin-bottom: 0;
      font-weight: 600;
      color: $white;
    }
  }

  &__block {
    margin: 0 -20px 20px;
    padding: 0 20px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% + 40px);
    border-bottom: 1px solid $light-border;
    @include media-breakpoint-up(xxl) {
      margin: 0 -30px 30px;
      padding: 0 30px 20px;
      width: calc(100% + 60px);
    }
  }

  &__block-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 2px 2px rgba($black, 0.04);
  }

  &__block-text {
    padding-left: 15px;
    width: calc(100% - 80px);

    span {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 300;
      color: $pink;

      i {
        margin-right: 10px;
      }
    }
  }

  &__info {
    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }

        span,
        a {
          padding-left: 35px;
          position: relative;
          display: inline-block;
          align-items: flex-start;
          font-size: $font-size-sm;
          color: $blue;

          small {
            display: block;
            font-size: 14px;
            color: $teal;
          }

          &:hover {
            text-decoration: none;
            color: $pink;
          }

          i {
            position: absolute;
            top: 3px;
            left: 0;
            width: 30px;
            color: $cyan;

            &.icon-mail {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  &__list {
    ul {
      margin: 0;
      padding: 0;
      width: 100%;

      li {
        margin-bottom: 3px;
        position: relative;
        width: 100%;
        font-size: 16px;
        color: $blue;
        border-right: 1px solid $gray-500;
        padding: {
          top: 4px;
          bottom: 4px;
          left: 25px;
        }

        &:last-child {
          margin: 0;
          border-right: 0;
        }

        &:before {
          position: absolute;
          top: 7px;
          left: 1px;
          font-family: $font-family-icon;
          font-size: 10px;
          color: $cyan;
          content: '\e909';
        }
      }
    }
  }

  &__logo {
    ul {
      margin: 0;
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      li {
        margin: 10px 5px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 80px;
        border-radius: 4px;
        box-shadow: 0 2px 2px rgba($black, 0.04);

        img {
          padding: 5px 10px;
          width: auto;
          max-width: 138px;
          height: 56px;
        }
      }
    }
  }

  &--logo {
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    h6 {
      margin-bottom: 0;
      font-size: 16px;
    }

    ul {
      margin-bottom: 0;
    }
  }
}

.js-employee-slider {
  .slick-dots {
    margin: 0;
    padding: 0 0 40px;
    display: flex;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding-bottom: 60px;
    }

    li {
      margin: 0 4px;
      display: block;
      width: 10px;
      height: 10px;

      button {
        padding: 0;
        display: block;
        width: 10px;
        height: 10px;
        text-indent: -9999px;
        border: 0;
        border-radius: 50%;
        background: $teal;
        cursor: pointer;
      }

      &.slick-active {
        button {
          background: $cyan;
        }
      }
    }
  }
}

.content__article {
  .iframe-wrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
    margin: 1rem 0;

    &:not(:first-child) {
      margin-top: 16px;
    }

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
}
.pageblock_14120 {
.blog-img {
    background-position: center;
    height: 15em;
}
}
.pageblock_14124 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14126 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14655 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14136 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14137 {
.plans-section {
	position: relative;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
		};
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 150px;
			bottom: 120px;
		}
	}
	margin: {
		top: 60px;
		bottom: 60px;
	}

	h3 {
		strong {
			color: $pink;
		}
	}

	&__list {
		margin: 0 -8px;
		padding: 15px 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: calc(100% + 16px);
		@include media-breakpoint-up(xl) {
			padding-top: 30px;
		}
	}

	&__list-item {
		padding: 0 8px 8px;
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 50%;
		}
		@include media-breakpoint-up(xl) {
			padding-bottom: 16px;
			width: 33.333%;
		}
	}

	&__list-link {
		padding: 30px 60px 30px 50px;
		position: relative;
		display: block;
		width: 100%;
		font-size: 18px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: -0.5px;
		color: $blue;
		border-radius: 8px;
		background: $gray-300;
		transition: all 0.3s;
		@include media-breakpoint-up(xl) {
			padding: 30px 80px;
		}

		&:after {
			margin-top: -24px;
			position: absolute;
			top: 50%;
			right: 15px;
			z-index: 2;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			font-family: $font-family-icon;
			font-size: 14px;
			font-weight: 400;
			color: $blue;
			border-radius: 50%;
			background: $white;
			transition: all 0.3s;
			content: '\e904';
			@include media-breakpoint-up(xl) {
				right: 20px;
			}
		}

		i {
			position: absolute;
			top: 50%;
			left: 20px;
			font-size: 20px;
			transform: translateY(-50%);
			transition: all 0.3s;
			@include media-breakpoint-up(xl) {
				left: 40px;
			}
		}

		&:hover {
			text-decoration: none;
			color: $blue;

			i {
				color: $cyan;
			}

			&:after {
				color: $white;
				background: $cyan;
			}
		}
	}
}

}
.pageblock_14138 {
.cta-banner {
	margin: 0 auto 56px;
	position: relative;
	width: 100%;
	max-width: 1920px;
	border-radius: 16px;
	background: $blue;
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 640px;
		border-radius: 24px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__content {
		margin: -20px 0 0;
		padding: 40px 20px;
		position: relative;
		color: $white;
		border-radius: 0 12px 12px 0;
		background: $blue;
		@include media-breakpoint-up(lg) {
			margin: 0;
			padding: 0 20px 0 0;
			width: 50%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(xl) {
			width: 40%;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 50px;
			width: 36%;
		}
		@include media-breakpoint-up(xxxl) {
			padding-right: 0;
			padding-left: 90px;
			width: 34%;
		}

		h6 {
			margin-bottom: 16px;
			color: $cyan;
		}

		h2 {
			color: $white;
			@include media-breakpoint-up(lg) {
				margin-bottom: 32px;
			}
		}

		p {
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				line-height: 26px;
			}
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 240px;
		@include media-breakpoint-up(sm) {
			height: auto;
		}
		@include media-breakpoint-up(lg) {
			width: 50%;
			height: 640px;
			border-radius: 24px 0 0 24px;
		}
		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		&:after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				right: -185px;
				width: 370px;
				height: 100%;
				content: '';
				@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 24px 0 0 24px;
			}
		}
	}
}

}
.pageblock_14139 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14140 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14141 {
.specialists-form {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(xl) {
		margin-bottom: 120px;
	}

	&__holder {
		margin: 0 -20px;
		position: relative;
		z-index: 2;
		width: calc(100% + 40px);
		border-radius: 16px;
		background-color: $blue;
		overflow: hidden;
		@include background-image(specialist-bg, svg, center center, no-repeat, cover);
		@include media-breakpoint-up(sm) {
			margin: 0;
			width: 100%;
		}
		@include media-breakpoint-up(xl) {
			border-radius: 18px;
			padding: {
				top: 90px;
				bottom: 90px;
			}
		}
		padding: {
			top: 50px;
			right: 20px;
			bottom: 50px;
			left: 20px;
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: -390px;
			z-index: -1;
			width: 776px;
			height: 1522px;
			transform: scaleX(-1);
			content: '';
			@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
		}
	}

	&__top {
		margin-bottom: 10px;
		width: 100%;
		font-size: 16px;
		text-align: center;
		color: rgba($white, 0.4);
		@include media-breakpoint-up(xl) {
			margin-bottom: 20px;
			font-size: 20px;
		}

		h2 {
			margin-bottom: 25px;
			color: $white;
			@include media-breakpoint-down(lg) {
				font-size: 24px;
				line-height: 32px;
				letter-spacing: -0.75px;
			}
		}
	}

	&__slider {
		position: relative;
		width: 100%;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 80px;
		border-radius: 8px;
		background: $pink;
	}

	&__slide {
		margin: 0 8px;
		width: 160px;
	}

	&__form {
		margin-bottom: 30px;
		padding-right: 20px;
		width: 100%;
		@include media-breakpoint-up(xl) {
			margin-bottom: 60px;
		}
	}
}

.card-search {
	padding-left: 80px;
	position: relative;
	width: 100%;
	border-radius: 10px 0 0 10px;
	background: $white;
	box-shadow: 0 12px 24px rgba($black, 0.12);

  @include media-breakpoint-up(md) {
    padding-left: 100px;
  }

	label {
		padding-left: 18px;
		position: absolute;
		top: 0;
		left: 0;
		display: inline-flex;
		align-items: center;
		height: 60px;
		font-size: $font-size-sm;
		font-weight: 600;
		color: $blue;

    @include media-breakpoint-up(md) {
      padding-left: 28px;
      height: 80px;
    }
	}

	&:after {
		position: absolute;
		top: 0;
		left: 100%;
		width: 0;
		height: 0;
		border-color: transparent transparent transparent $white;
		border-style: solid;
		border-width: 30px 0 30px 24px;
		content: '';

    @include media-breakpoint-up(md) {
      border-width: 40px 0 40px 24px;
    }
	}

	&__search-input {
		padding-right: 70px;
		width: 100%;
		height: 60px;
		font-size: $font-size-sm;
		font-weight: 300;
		color: $teal;
		border: 0;
		background: transparent;

    @include media-breakpoint-up(md) {
      height: 80px;
    }

		&::placeholder {
			color: $teal;
		}
	}

	&__search-btn {
		padding-top: 4px;
		position: absolute;
		top: 6px;
		right: 5px;
		width: 48px;
		height: 48px;
		font-size: $font-size-sm;
		color: $white;
		border: 0;
		border-radius: 50%;
		background: $orange;

    @include media-breakpoint-up(md) {
      top: 16px;
    }
	}

	&--select {
		padding-right: 245px;
	}

	&__select {
		padding-right: 20px;
		position: absolute;
		top: 15px;
		right: 40px;
		width: 210px;
		border-left: 1px solid $light-border;

		select {
			padding: 0 40px 0 15px;
			height: 48px;
			font-size: 16px;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}
	}
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3;

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}
}
.pageblock_14144 {
.faq-section {
  --size-link-asset: 32px;
	margin-bottom: 60px;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(lg) {
    --size-link-asset: 54px;
		margin-bottom: 120px;
	}

	h2 {
		margin-bottom: 20px;
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
		}

		strong {
			color: $pink;
		}
	}

  &__list {
    &--with-asset {
      .faq-section__panel {
        padding-left: calc(var(--size-link-asset) + 12px);
      }
    }
  }

	&__top {
		color: $blue;
		@include media-breakpoint-up(lg) {
			color: $pink;
		}
	}

	&__holder {
		margin-bottom: 20px;
		padding: 25px;
		width: 100%;
		border-radius: 8px;
		background-color: $gray-300;
		@include media-breakpoint-up(md) {
			padding: 45px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
			padding: 85px;
			border-radius: 16px;
		}

		h3 {
			margin-bottom: 25px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 50px;
			}
		}
	}

	&__item {
		margin-bottom: 18px;
		padding-bottom: 18px;
		border-bottom: 1px solid $light;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}

		&.is-active {
			.faq-section__link {
				i {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__link {
		padding: 10px 35px 10px 0;
		position: relative;
		display: block;
		font-weight: 600;
		color: $blue;

		@include media-breakpoint-up(lg) {
			padding: 0px 30px 0px 0;
		}

    &:not(.js-faq-link) {
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;

      &__asset {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--size-link-asset);
        height: var(--size-link-asset);
        margin-right: 12px;
        border-radius: 50%;
        background-color: $light;

        img {
          max: {
            width: 60%;
            height: 60%;
          }
        }
      }
    }

		i {
			position: absolute;
			top: 13px;
			right: 0;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			font-size: 8px;
			transition: all 0.3s;
			@include media-breakpoint-up(lg) {
				margin-top: -15px;
				top: 50%;
				width: 30px;
				height: 30px;
				font-size: 10px;
			}
		}

		&.js-faq-link {
      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
	}

	&__panel {
		display: none;
		@include media-breakpoint-up(xl) {
			padding-right: 100px;
		}

		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}

	&--inner {
		padding-top: 20px;
		@include media-breakpoint-up(xl) {
			padding-top: 80px;
		}

		.faq-section__holder {
			@include media-breakpoint-up(xl) {
				padding: 30px 60px;
			}
		}

		&:after {
			display: none;
		}
	}
}

}
.pageblock_14145 {
.card-house {
  a:hover {
    text-decoration: none;
  }
}

.card-house__image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
}
.pageblock_14152 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14154 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14642 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14158 {
@import '~assets/styles/variables';

.content {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;

  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__article {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }

    h5 {
      margin-bottom: 16px;
    }

    p {
      a {
        text-decoration: underline;
        color: $cyan;

        &:hover {
          color: $blue;
        }
      }
    }
  }

  &__video {
    position: relative;
    display: block;
    width: 100%;

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 38px;
      font-family: $font-family-icon;
      font-size: 12px;
      color: $white;
      border-radius: 8px;
      background: $cyan;
      transform: translate(-50%, -50%);
      content: '\e933';
      box-shadow: 0 8px 8px rgba($black, 0.08);
      @include media-breakpoint-up(xl) {
        width: 72px;
        height: 48px;
        font-size: 16px;
      }
    }

    img {
      width: 100%;
      border-radius: 8px;
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  .content__list {
    ul {
      padding-left: 0;
      margin-bottom: 0;
      margin-top: 16px;
      column-count: 2;

      @include media-breakpoint-up(lg) {
        column-count: 2;
      }

      @include media-breakpoint-up(xl) {
        column-count: 3;
        margin-top: 32px;
      }

      li {
        margin-bottom: 8px;

        @include media-breakpoint-up(xl) {
          margin-bottom: 16px;
        }

        a {
          color: $blue;
          text-decoration: none;
          display: flex;
          align-items: center;
          font-weight: 600;

          &:hover i {
            color: $orange;
          }

          i {
            color: $cyan;
            margin-right: 8px;
            font-size: 16px;
            transition: .3s ease;
          }
        }
      }
    }
  }

  .card-social__logo {
    margin-bottom: 32px;

    img {
      width: 146px;
    }
  }
}

}
.pageblock_14159 {
.onboarding-section {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__holder {
    margin: 0 -20px;
    padding: 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: calc(100% + 40px);
    border-radius: 15px;
    background: $gray-400;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 22px;
      width: 100%;
    }
  }

  &__left {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    text-align: center;
    @include media-breakpoint-up(lg) {
      width: 60%;
      text-align: left;
    }
  }

  &__right {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: flex-end;
      width: 40%;
    }
  }

  &__image {
    padding-right: 10px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 122px;
    height: 96px;
    @include background-image(onboarding-bg, svg, center center, no-repeat, 100% 100%);

    img {
      max-height: 60px;
    }
  }

  &__text {
    padding-top: 10px;
    width: 100%;
    font-size: $font-size-sm;
    @include media-breakpoint-up(lg) {
      width: calc(100% - 122px);
    }
    @include media-breakpoint-up(xl) {
      padding-left: 50px;
    }

    p {
      @include media-breakpoint-up(lg) {
        margin-bottom: 10px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: auto;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 50px;
    }
  }

  &__list-item {
    margin: 0 3px;
    @include media-breakpoint-up(md) {
      margin-right: 0;
      margin-left: 8px;
    }

    .btn-light {
      color: $blue;
      border-color: $white;
      background: $white;

      &:hover {
        color: $white;
        border-color: $cyan;
        background: $cyan;
      }
    }
  }

  &__list-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-weight: 600;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    &:hover {
      text-decoration: none;
      color: $white;
      background: $cyan;
    }
  }
}

}
.pageblock_14160 {
// Styling for the step option card
.card-assistance {
  margin-bottom: 8px;
  padding: 16px 20px;
  display: flex;
  width: 100%;
  text-align: center;
  border: 2px solid $white;
  border-radius: 16px;
  background: $white;
  min-height: 80px;
  transition: all 0.3s;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba($orange, .3);
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    transition: .3s ease;

  }

  .card-assistance__icon,
  h5,
  span {
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-down(md) {
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    display: block;
    padding: 0 0 24px;
    margin-bottom: 25px;
  }

  &:hover {
    text-decoration: none;
    border-color: $orange;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    transform: scale(1.1);
    z-index: 2;
    position: relative;

    &::before {
      transform: translateX(0);
    }
  }

  h5 {
    @include media-breakpoint-down(md) {
      margin-bottom: 0 !important;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
      margin-top: 12px;
      font-size: 18px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 21px;
    }
  }

  &--title {

    @include media-breakpoint-up(md) {
      padding-top: 32px;
      padding-bottom: 40px;
    }
  }

  &__icon {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: fit-content;

    @include media-breakpoint-down(md) {
      margin-right: 20px;
      padding-top: 0 !important;
      width: 32px;
      height: auto !important;
    }

    @include media-breakpoint-up(md) {
      padding-top: 20px;
      height: 80px;
      width: 100%;
    }
  }

  .btn {
    transition: .3se ease;
    @include media-breakpoint-down(md) {
      position: absolute;
      right: 28px;
    }
  }
}

// Active state for the step option card
.step-option-button {
  @include media-breakpoint-down(md) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
  }

  span {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  i {
    @include media-breakpoint-up(md) {
      display: none !important;
    }
  }

  i.icon-cross {
    transform: rotate(45deg);
  }

  i.icon-check {
    display: none;
  }
}

input.step-option-input:checked + label {
  text-decoration: none;
  border-color: $orange;
  box-shadow: 0 16px 48px rgba($black, 0.12);

  &::before {
    transform: translateX(0);
  }

  .step-option-button {
    background-color: $orange;
    border-color: $orange;
    color: $white;

    i.icon-cross {
      display: none;
    }

    i.icon-check {
      display: inline;
    }
  }
}

.card-postcode {
  margin-bottom: 30px;
  padding: 20px 35px;
  width: 100%;
  font-size: $font-size-sm;
  border-radius: 16px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(lg) {
    padding: 10px;
  }

  &__content {
    padding: 15px 0;
    @include media-breakpoint-up(lg) {
      padding: 35px 10px;
    }
  }

  &__map {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h6 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
  }
}

// begin livingwish popup
.onboarding-popup {
  width: 100%;
}

.housingneeds-popup {
  .mfp-close {
    display: none;
  }
}

.mfp-fullscreen {
  .mfp-container {
    padding: 0; /* 1 */
  }
  .mfp-content {
    height: 100%; /* 2 */
  }
}

.full-popup {
  background: #FFF;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
}
// end livingwish popup

.step-heading {
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 160px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
    padding-bottom: 60px;
  }

  @include media-breakpoint-up(xxxl) {
    padding-top: 200px;
  }

  h2 {
    color: $white;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-weight: 600;
    color: $cyan;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% + 100px);
    background-color: $blue;
    content: '';
    @include background-image(step-bg, svg, center top, no-repeat, cover);
    @include media-breakpoint-up(xl) {
      height: calc(100% + 160px);
    }
  }
}

.step-pagination {
  margin: -20px 0 30px 0;
  position: relative;
  width: 100%;
  bottom: 0;

  @include media-breakpoint-up(xl) {
    margin: -100px 0 50px;
  }

  .btn {
    margin: 0 4px;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__list-item {
    margin: 0 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
    color: $blue;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      height: 48px;
      width: 48px;
      color: $teal;
    }

    &:after, &::before {
      margin-top: -1px;
      position: absolute;
      top: 50%;
      left: 100%;
      width: 16px;
      height: 2px;
      transition: .3s ease;
      content: '';
    }

    &:after {
      background: rgba(248, 241, 234, 1);
    }

    &::before {
      width: 0;
      transition: .5s ease;
      transition-delay: .5s;
      z-index: 1;
      background-image: linear-gradient(to right, rgba(0, 176, 167, 1) 0%, rgba(237, 237, 238, 1) 100%);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      text-decoration: none;
      color: $blue;
      border-radius: 50%;
      background: $gray-300;
      position: relative;
      overflow: hidden;
      transition: .3s ease;

      @include media-breakpoint-up(md) {
        width: 48px;
        height: 48px;
      }

      &::before {
        content: '';
        background: $cyan;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: .3s ease;
        transition-delay: .3s;
        width: 100%;
        height: 100%;
      }

      span {
        position: relative;
        z-index: 1;
      }

      &:hover {
        color: $white;
        background: $cyan;
      }
    }

    &.is-selected {
      a {
        color: $cyan;
        background: $gray-300;
      }
    }

    &.is-active {
      a {
        background-color: $gray-300 !important;
        span {
          color: $white;
        }

        &::before {
          transform: translateX(0);
        }
      }

      &:not(:last-child)::before {
        width: 16px;
      }

      &:not(:last-child)::after {
        background: rgba(248, 241, 234, 1) !important;
      }
    }

    &.is-done {
      a {
        color: $white;
        background: $cyan;
      }

      &:after {
        background-color: $cyan;
      }
    }

    &.is-disabled {
      a {
        color: $white;
        background: $gray-500;
        cursor: default;
      }
    }

    &.is-blocked {
      display: none;
    }

    &.is-blocked.is-done {
      a {
        color: $white;
        background: $cyan;
      }
    }
  }
}

// begin step-section
.livingwish-popup {

  .hidden {
    display: none;
  }

  .wrapper {
    overflow: scroll;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 32px;
    }
  }
}

.step {
  display: none;
  transition: .3s ease;
  width: 100%;
  height: 100%;

  &:not(.hidden) {
    display: block;

    .step-pagination {
      li.is-active {
        a {
          &::before {
            transform: translateX(0);
          }
        }

        &::before {
          width: 16px;
        }
      }
    }

    .step-section {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .step-section {
    margin-bottom: 60px;
    position: relative;
    z-index: 6;
    width: 100%;
    // inactive
    opacity: 0;
    transform: translateX(-16px);
    transition: .3s ease;

    @include media-breakpoint-up(xl) {
      margin-bottom: 120px;
    }

    &__list {
      margin: 0 -8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: calc(100% + 16px);
    }

    &__list-item {
      padding: 0 8px;
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 33.333%;
      }
      @include media-breakpoint-up(xl) {
        width: 25%;
      }
      @include media-breakpoint-up(xxl) {
        width: 20%;
      }
    }

    .col-lg-3 {
      &:nth-child(1) {
        .step-image { // Hypotheek afsluiten
          background-image: url('../../assets/images/services-hypotheek-afsluiten.png');
        }
      }

      &:nth-child(2) {
        .step-image { // Verzekeringen
          background-image: url('../../assets/images/services-verzekeringen.png');
        }
      }

      &:nth-child(3) {
        .step-image { // Taxatie
          background-image: url('../../assets/images/services-taxatie.png');
        }
      }

      &:nth-child(4) {
        .step-image { // Verkopen
          background-image: url('../../assets/images/services-verkopen.png');
        }
      }

      &:nth-child(5) {
        .step-image { // Kopen
          background-image: url('../../assets/images/services-makelaar.png');
        }
      }

      &:nth-child(6) {
        .step-image { // Huren
          background-image: url('../../assets/images/services-huren.png');
        }
      }

      :nth-child(7) {
        .step-image { // Makelaar
          background-image: url('../../assets/images/services-makelaar.png');
        }
      }
    }
  }
}

.step-section__select {
  border-radius: 16px;
  background-color: $white;
  padding: 32px;
  text-align: center;
  box-shadow: 0 8px 8px 0 rgba($black, .08);

  @include media-breakpoint-up(xl) {
    padding: 64px 0 32px;
    max-width: 800px;
    margin: 0 auto;
  }

  h4 {
    text-align: center;
  }

  .select-wrapper {
    margin: 24px auto 16px;
    max-width: 530px;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
    }

    @include media-breakpoint-up(xl) {
      margin: 40px auto 32px;
    }

    select {
      @include media-breakpoint-up(sm) {
        width: calc(50% - 8px);
      }

      &:first-child {
        @include media-breakpoint-down(md) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: fit-content;
    }
  }
}
// end step-section
}
.pageblock_14161 {
/* stylelint-disable */
.region-realtor {
  margin-bottom: 50px;
  position: relative;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-bottom: 80px;
  }

  h2 {
    margin-bottom: 16px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 40px;
    }

    strong {
      display: block;
      //replace
      color: #5C5B81;
    }
  }

  .slider-wrapper {
    position: relative;

    @include media-breakpoint-down(md) {
      margin-top: 32px;
    }

    .realtor-arrows {
      @include media-breakpoint-down(md) {
        display: flex;
        gap: 8px;
        margin: 20px auto 0;
        width: fit-content;
        flex-direction: row-reverse;
      }

      @include media-breakpoint-up(md) {
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        left: -60px;
        height: fit-content;
      }

      @include media-breakpoint-up(xxxl) {
        left: -80px;
      }

      .arrow {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        //replace
        box-shadow: 0 8px 8px 0 rgba(#000,0.08);
        margin: 4px 0;
        cursor: pointer;

        i {
          font-size: 14px;
        }
      }
    }

    .realtor-slider {
      margin: 0 -8px;

      @include media-breakpoint-up(md) {
        margin: 0 -16px;
      }

      .realtor {
        padding: 8px;

        @include media-breakpoint-up(md) {
          padding: 16px;
        }

        .realtor__inner {
          height: 120px;
          padding: 24px 32px;
          // replace
          background-color: #f2e8e1;
          border-radius: 8px;
          display: flex;
          align-items: center;

          img {
            max-height: 100%;
            margin: auto;
          }

          @include media-breakpoint-up(xl) {
            height: 140px;
          }

          @include media-breakpoint-up(xxxl) {
            padding: {
              right: 65px;
              left: 65px;
            }
          }
        }
      }
    }
  }
}
/* stylelint-enable */
}
.pageblock_14163 {
.region-developers {
  overflow: hidden;
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  .section__header {
    margin-bottom: 24px;
    text-align: center;

    strong {
      color: $pink;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 48px;
    }
  }

  .region-developers__slider {
    width: 672px;
    max-width: 100%;
    margin: {
      right: auto;
      left: auto;
    }

    .developer-slide {
      padding: {
        right: 8px;
        left: 8px;
      }
    }

    .developer-item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 320px;
      height: 140px;
      border-radius: 8px;
      padding: 16px;
      background-color: rgba($light-border, 60%);

      img {
        max-width: 60%;
        max-height: 60%;
      }
    }

    @include media-breakpoint-up(md) {
      .slick-list {
        overflow: visible;
      }

      .slick-track {
        left: -336px;
      }
    }
  }
}

}
.pageblock_14164 {
.project-slider {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
  }

  &--background {
    background-image: linear-gradient(to bottom, rgba(250, 244, 239, 1) 0%, rgba(255, 253, 249, 1) 100%);
    @include media-breakpoint-up(xl) {
      padding: {
        top: 130px;
        bottom: 30px;
      };
    }
    padding: {
      top: 60px;
      bottom: 20px;
    };
  }

  &--background2 {
    margin-bottom: 60px;
    background: $gray-300;
    @include media-breakpoint-up(xl) {
      margin-bottom: 120px;
      padding: {
        top: 120px;
        bottom: 120px;
      };
    }
    padding: {
      top: 60px;
      bottom: 60px;
    };
  }

  &__top {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__top-left {
    display: inline-flex;
    align-items: center;

    h4 {
      margin-bottom: 0;
    }
  }

  &__number {
    margin-right: 15px;
    padding: 0 15px;
    display: inline-flex;
    align-items: center;
    height: 32px;
    font-size: 14px;
    color: $pink;
    border-radius: 40px;
    background: $gray-300;
    @include media-breakpoint-up(xl) {
      margin-right: 25px;
      padding: 0 25px;
      height: 48px;
    }
  }

  &__slider {
    position: relative;
    width: 100%;

    &:after {
      @include media-breakpoint-up(xxxl) {
        position: absolute;
        top: 0;
        left: 100%;
        width: 70000px;
        height: 100%;
        background: rgba($light-alt, 0.5);
        content: '';
      }
    }
  }

  &__slide {
    margin-right: 10px;
    width: 350px;
    @include media-breakpoint-up(xl) {
      margin-right: 16px;
      width: 528px;
    }

    .card-house {
      .card-house__bar .card-house__bar-inner {
        span {
          left: 0;
          border-radius: 0 4px 0 0;
        }
      }
    }
  }

  &__holder {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
  }

  &__next,
  &__prev {
    display: none !important;
    @include media-breakpoint-up(xl) {
      position: absolute;
      top: 115px;
      z-index: 3;
      display: flex !important;
      align-content: center;
      align-items: center;
      width: 40px;
      height: 140px;
      font-size: 13px;
      color: $blue;
      border: 0;
      background: transparent;
      transition: all 0.3s;
      cursor: pointer;
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 0;
      height: 0;
      border-style: solid;
      content: '';
    }
  }

  &--step {
    z-index: 9;

    .project-slider__slider {
      &:after {
        display: none;
      }
    }
  }

  &__prev {
    padding-left: 12px;
    left: 0;

    &:after {
      border-color: transparent transparent transparent $white;
      border-width: 70px 0 70px 40px;
    }
  }

  &__next {
    padding-left: 15px;
    right: 0;

    &:after {
      border-color: transparent $white transparent transparent;
      border-width: 70px 40px 70px 0;
    }
  }

  &--inner {
    .card-house__list {
      padding: 8px 0;
    }
  }
}

.card-house {
  position: relative;
  display: block;
  width: 100%;

  a:hover {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  &__content {
    padding-top: 15px;
    width: 100%;
    font-size: $font-size-sm;
    font-weight: 300;
    color: $teal;

    h6 {
      margin-bottom: 8px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 16px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0 0 10px;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: 300;
    @include media-breakpoint-up(lg) {
      padding-bottom: 12px;
      font-size: $font-size-sm;
    }
  }

  &__list-item {
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    &:after {
      margin: 0 10px;
      display: inline-block;
      width: 4px;
      height: 4px;
      background: $teal;
      transform: rotate(45deg);
      content: '';
      @include media-breakpoint-up(xl) {
        margin: 0 15px;
      }
    }

    &.city {
      font-size: 20px;
      font-weight: 500;
    }

    a {
      text-decoration: none;
      color: $cyan;
    }

    &:last-child {
      &:after {
        display: none;
      }
    }
  }

  &__bar {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 4px;
    background: rgba(242, 232, 225);
  }

  &__bar-inner {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    //width: attr(data-percentage %); // not supported yet


    height: 4px;
    background: $cyan;

    span {
      padding: 0 10px;
      position: absolute;
      bottom: calc(100% + 15px);
      left: calc(100% - 50px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      height: 26px;
      font-size: 12px;
      text-align: center;
      color: $blue;
      border-radius: 4px 4px 0 0;
      background: $white;

      &:after {
        margin-left: -4px;
        position: absolute;
        bottom: -4px;
        left: 50%;
        width: 0;
        height: 0;
        border-color: $white transparent transparent transparent;
        border-style: solid;
        border-width: 5px 4px 0;
        content: '';
      }
    }
  }

  &__heart {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 2;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 10px;
    color: $red;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;
    @include media-breakpoint-up(xl) {
      top: 26px;
      right: 26px;
    }

    i.icon-heart-alt {
      color: $blue;
    }

    &.is-active,
    &:hover {
      text-decoration: none;

      i {
        &:before {
          content: '\e919';
          color: $blue;

        }

        &.icon-heart-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &__image {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    &:hover {
      text-decoration: none;
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 39%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      border-radius: 8px;
      aspect-ratio: 3/2;
      object-fit: cover;
    }
  }

  &__label {
    position: absolute;
    top: 22px;
    left: 0;
    z-index: 1;

    span {
      padding: 0 20px 0 12px;
      display: inline-flex;
      align-items: center;
      height: 24px;
      font-size: 12px;
      font-weight: 600;
      color: $white;
      background: $cyan;
      clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
    }
  }

  &__price {
    color: $cyan;
  }
}

.card-house {
  a:hover {
    text-decoration: none;
  }
}

.card-house__image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
}
.pageblock_14165 {
.contact-info__bottom {
  z-index: 1;
}
}
.pageblock_14166 {
.seo-text {
	position: relative;
	width: 100%;
	font-size: $font-size-base;
	@include media-breakpoint-up(md) {
		font-size: $font-size-lg;
	}
	@include media-breakpoint-up(lg) {
		border-top: 1px solid $border-color;
		padding: {
			top: 60px;
		}
		margin: {
			top: 60px;
		}
	}
	@include media-breakpoint-up(xl) {
		padding: {
			top: 120px;
		}
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	padding: {
		top: 30px;
	}
	margin: {
		bottom: 60px;
	}

	h3 {
		@include media-breakpoint-up(lg) {
			margin-bottom: 24px;
		}

		strong {
			color: $pink;
		}
	}

	p {
		margin-bottom: 30px;
		color: $blue;
		@include media-breakpoint-up(lg) {
			margin-bottom: 40px;
			color: $pink;
		}
	}

	&__list {
		margin: 0 -8px;
		padding: 15px 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: calc(100% + 16px);
		@include media-breakpoint-up(xl) {
			padding-top: 30px;
		}
	}

	&__list-item {
		padding: 0 8px 8px;
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 50%;
		}
		@include media-breakpoint-up(xl) {
			padding-bottom: 16px;
			width: 33.333%;
		}
	}

	&__list-link {
		padding: 30px 60px 30px 50px;
		position: relative;
		display: block;
		width: 100%;
		color: $blue;
		border-radius: 8px;
		background: $gray-300;
		transition: all 0.3s;
		@include media-breakpoint-up(xl) {
			padding: 30px 80px;
		}

		&:after {
			margin-top: -24px;
			position: absolute;
			top: 50%;
			right: 15px;
			z-index: 2;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			font-family: $font-family-icon;
			font-size: 14px;
			font-weight: 400;
			color: $blue;
			border-radius: 50%;
			background: $white;
			transition: all 0.3s;
			content: '\e904';
			@include media-breakpoint-up(xl) {
				right: 20px;
			}
		}

		i {
			position: absolute;
			top: 50%;
			left: 20px;
			font-size: 20px;
			transform: translateY(-50%);
			transition: all 0.3s;
			@include media-breakpoint-up(xl) {
				left: 40px;
			}
		}

		&:hover {
			text-decoration: none;
			color: $blue;

			i {
				color: $cyan;
			}

			&:after {
				color: $white;
				background: $cyan;
			}
		}
	}
}
}
.pageblock_14172 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14659 {
.news-banner {
  $self: &;
  margin-bottom: 60px;
  padding-top: 165px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 320px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    max-width: 1840px;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    span {
      margin: 4px 0 0 15px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 4px;
      background: $cyan;
    }
  }

  &__content {
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    color: $white;

    h6 {
      font-weight: 600;
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: $white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 640px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__button {
    padding: 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(xl) {
      top: 50px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14660 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14177 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14665 {
.news-banner {
  $self: &;
  margin-bottom: 60px;
  padding-top: 165px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 320px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    max-width: 1840px;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    span {
      margin: 4px 0 0 15px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 4px;
      background: $cyan;
    }
  }

  &__content {
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    color: $white;

    h6 {
      font-weight: 600;
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: $white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 640px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__button {
    padding: 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(xl) {
      top: 50px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14666 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14181 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14183 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14632 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14187 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14189 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14652 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14193 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14195 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14640 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14199 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14201 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14649 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14205 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14207 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14656 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14210 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 2;

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14214 {
// Styling for the step option card
.card-assistance {
  margin-bottom: 8px;
  padding: 16px 20px;
  display: flex;
  width: 100%;
  text-align: center;
  border: 2px solid $white;
  border-radius: 16px;
  background: $white;
  min-height: 80px;
  transition: all 0.3s;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: rgba($orange, .3);
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(-100%);
    transition: .3s ease;

  }

  .card-assistance__icon,
  h5,
  span {
    position: relative;
    z-index: 1;
  }

  @include media-breakpoint-down(md) {
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    display: block;
    padding: 0 0 24px;
    margin-bottom: 25px;
  }

  &:hover {
    text-decoration: none;
    border-color: $orange;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    transform: scale(1.1);
    z-index: 2;
    position: relative;

    &::before {
      transform: translateX(0);
    }
  }

  h5 {
    @include media-breakpoint-down(md) {
      margin-bottom: 0 !important;
    }
    @include media-breakpoint-up(md) {
      margin-bottom: 25px;
      margin-top: 12px;
      font-size: 18px;
    }

    @include media-breakpoint-up(xxl) {
      font-size: 21px;
    }
  }

  &--title {

    @include media-breakpoint-up(md) {
      padding-top: 32px;
      padding-bottom: 40px;
    }
  }

  &__icon {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: fit-content;

    @include media-breakpoint-down(md) {
      margin-right: 20px;
      padding-top: 0 !important;
      width: 32px;
      height: auto !important;
    }

    @include media-breakpoint-up(md) {
      padding-top: 20px;
      height: 80px;
      width: 100%;
    }
  }

  .btn {
    transition: .3se ease;
    @include media-breakpoint-down(md) {
      position: absolute;
      right: 28px;
    }
  }
}

// Active state for the step option card
.step-option-button {
  @include media-breakpoint-down(md) {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    padding: 0;
  }

  span {
    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  i {
    @include media-breakpoint-up(md) {
      display: none !important;
    }
  }

  i.icon-cross {
    transform: rotate(45deg);
  }

  i.icon-check {
    display: none;
  }
}

input.step-option-input:checked + label {
  text-decoration: none;
  border-color: $orange;
  box-shadow: 0 16px 48px rgba($black, 0.12);

  &::before {
    transform: translateX(0);
  }

  .step-option-button {
    background-color: $orange;
    border-color: $orange;
    color: $white;

    i.icon-cross {
      display: none;
    }

    i.icon-check {
      display: inline;
    }
  }
}

.card-postcode {
  margin-bottom: 30px;
  padding: 20px 35px;
  width: 100%;
  font-size: $font-size-sm;
  border-radius: 16px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(lg) {
    padding: 10px;
  }

  &__content {
    padding: 15px 0;
    @include media-breakpoint-up(lg) {
      padding: 35px 10px;
    }
  }

  &__map {
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h6 {
    margin-bottom: 5px;
    font-size: 16px;
    font-weight: 400;
  }
}

// begin livingwish popup
.onboarding-popup {
  width: 100%;
}

.housingneeds-popup {
  .mfp-close {
    display: none;
  }
}

.mfp-fullscreen {
  .mfp-container {
    padding: 0; /* 1 */
  }
  .mfp-content {
    height: 100%; /* 2 */
  }
}

.full-popup {
  background: #FFF;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
  padding: 0;
  max-width: 100%;
  max-height: 100%;
}
// end livingwish popup

.step-heading {
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 160px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
    padding-bottom: 60px;
  }

  @include media-breakpoint-up(xxxl) {
    padding-top: 200px;
  }

  h2 {
    color: $white;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-weight: 600;
    color: $cyan;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% + 100px);
    background-color: $blue;
    content: '';
    @include background-image(step-bg, svg, center top, no-repeat, cover);
    @include media-breakpoint-up(xl) {
      height: calc(100% + 160px);
    }
  }
}

.step-pagination {
  margin: -20px 0 30px 0;
  position: relative;
  width: 100%;
  bottom: 0;

  @include media-breakpoint-up(xl) {
    margin: -100px 0 50px;
  }

  .btn {
    margin: 0 4px;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__list-item {
    margin: 0 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    font-size: 16px;
    font-weight: 600;
    color: $blue;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      height: 48px;
      width: 48px;
      color: $teal;
    }

    &:after, &::before {
      margin-top: -1px;
      position: absolute;
      top: 50%;
      left: 100%;
      width: 16px;
      height: 2px;
      transition: .3s ease;
      content: '';
    }

    &:after {
      background: rgba(248, 241, 234, 1);
    }

    &::before {
      width: 0;
      transition: .5s ease;
      transition-delay: .5s;
      z-index: 1;
      background-image: linear-gradient(to right, rgba(0, 176, 167, 1) 0%, rgba(237, 237, 238, 1) 100%);
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      text-decoration: none;
      color: $blue;
      border-radius: 50%;
      background: $gray-300;
      position: relative;
      overflow: hidden;
      transition: .3s ease;

      @include media-breakpoint-up(md) {
        width: 48px;
        height: 48px;
      }

      &::before {
        content: '';
        background: $cyan;
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        transition: .3s ease;
        transition-delay: .3s;
        width: 100%;
        height: 100%;
      }

      span {
        position: relative;
        z-index: 1;
      }

      &:hover {
        color: $white;
        background: $cyan;
      }
    }

    &.is-selected {
      a {
        color: $cyan;
        background: $gray-300;
      }
    }

    &.is-active {
      a {
        background-color: $gray-300 !important;
        span {
          color: $white;
        }

        &::before {
          transform: translateX(0);
        }
      }

      &:not(:last-child)::before {
        width: 16px;
      }

      &:not(:last-child)::after {
        background: rgba(248, 241, 234, 1) !important;
      }
    }

    &.is-done {
      a {
        color: $white;
        background: $cyan;
      }

      &:after {
        background-color: $cyan;
      }
    }

    &.is-disabled {
      a {
        color: $white;
        background: $gray-500;
        cursor: default;
      }
    }

    &.is-blocked {
      display: none;
    }

    &.is-blocked.is-done {
      a {
        color: $white;
        background: $cyan;
      }
    }
  }
}

// begin step-section
.livingwish-popup {

  .hidden {
    display: none;
  }

  .wrapper {
    overflow: scroll;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @include media-breakpoint-up(xl) {
      margin-bottom: 32px;
    }
  }
}

.step {
  display: none;
  transition: .3s ease;
  width: 100%;
  height: 100%;

  &:not(.hidden) {
    display: block;

    .step-pagination {
      li.is-active {
        a {
          &::before {
            transform: translateX(0);
          }
        }

        &::before {
          width: 16px;
        }
      }
    }

    .step-section {
      opacity: 1;
      transform: translateX(0);
    }
  }

  .step-section {
    margin-bottom: 60px;
    position: relative;
    z-index: 6;
    width: 100%;
    // inactive
    opacity: 0;
    transform: translateX(-16px);
    transition: .3s ease;

    @include media-breakpoint-up(xl) {
      margin-bottom: 120px;
    }

    &__list {
      margin: 0 -8px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: calc(100% + 16px);
    }

    &__list-item {
      padding: 0 8px;
      width: 100%;
      @include media-breakpoint-up(sm) {
        width: 50%;
      }
      @include media-breakpoint-up(lg) {
        width: 33.333%;
      }
      @include media-breakpoint-up(xl) {
        width: 25%;
      }
      @include media-breakpoint-up(xxl) {
        width: 20%;
      }
    }

    .col-lg-3 {
      &:nth-child(1) {
        .step-image { // Hypotheek afsluiten
          background-image: url('../../assets/images/services-hypotheek-afsluiten.png');
        }
      }

      &:nth-child(2) {
        .step-image { // Verzekeringen
          background-image: url('../../assets/images/services-verzekeringen.png');
        }
      }

      &:nth-child(3) {
        .step-image { // Taxatie
          background-image: url('../../assets/images/services-taxatie.png');
        }
      }

      &:nth-child(4) {
        .step-image { // Verkopen
          background-image: url('../../assets/images/services-verkopen.png');
        }
      }

      &:nth-child(5) {
        .step-image { // Kopen
          background-image: url('../../assets/images/services-makelaar.png');
        }
      }

      &:nth-child(6) {
        .step-image { // Huren
          background-image: url('../../assets/images/services-huren.png');
        }
      }

      :nth-child(7) {
        .step-image { // Makelaar
          background-image: url('../../assets/images/services-makelaar.png');
        }
      }
    }
  }
}

.step-section__select {
  border-radius: 16px;
  background-color: $white;
  padding: 32px;
  text-align: center;
  box-shadow: 0 8px 8px 0 rgba($black, .08);

  @include media-breakpoint-up(xl) {
    padding: 64px 0 32px;
    max-width: 800px;
    margin: 0 auto;
  }

  h4 {
    text-align: center;
  }

  .select-wrapper {
    margin: 24px auto 16px;
    max-width: 530px;

    @include media-breakpoint-up(sm) {
      display: flex;
      justify-content: space-between;
    }

    @include media-breakpoint-up(xl) {
      margin: 40px auto 32px;
    }

    select {
      @include media-breakpoint-up(sm) {
        width: calc(50% - 8px);
      }

      &:first-child {
        @include media-breakpoint-down(md) {
          margin-bottom: 8px;
        }
      }
    }
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(sm) {
      width: fit-content;
    }
  }
}
// end step-section
}
.pageblock_15148 {
/* stylelint-disable */
.house-map {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__map {
    height: 688px;
    margin-top: 24px;
    z-index: 1;

    @include media-breakpoint-down(md) {
      max-height: 80vh;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .leaflet-popup-content {
    margin: 0 !important;

    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }

  .leaflet-popup-content-wrapper {
    box-shadow: none;
    background-color: transparent;
  }

  &__block-close {
    position: absolute;
    top: 12px;
    right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    font-size: 12px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(md) {
      display: inline-block;
      width: auto;
      height: auto;
      border-radius: 0;
      background: transparent;
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }

  &__holder {
    margin-top: -60px;
    position: relative;
    z-index: 1;
    width: 100%;
    @include media-breakpoint-up(md) {
      margin-top: 0;
      position: absolute;
      bottom: 30px;
      left: 0;
    }
  }

  &__block {
    margin: 0 auto;
    position: relative;
    z-index: 2;
    max-width: 260px;
    border-radius: 4px;
    background: $white;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    @include media-breakpoint-up(md) {
      margin-top: -20px;
    }
    @include media-breakpoint-up(lg) {
      padding: 10px;
      max-width: 640px;
    }

    .card-house-list__image {

      @include media-breakpoint-up(xl) {
        padding-right: 20px;
      }
    }

    .card-house-list__list {
      padding: 5px 0 10px;

      .card-house-list__list-item {
        color: $pink;

        i {
          font-size: 16px;
        }
      }
    }

    .card-house-list__content {
      padding-left: 20px;

      @include media-breakpoint-down(md) {
        padding-top: 16px;
      }
    }
  }

  .card-house-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;

    &:hover {
      text-decoration: none;
    }

    &__content {
      width: 100%;
      font-size: $font-size-sm;
      font-weight: 300;
      color: $teal;
      @include media-breakpoint-up(md) {
        padding-left: 20px;
      }
      @include media-breakpoint-up(lg) {
        width: 57%;
      }
      @include media-breakpoint-up(xxl) {
        width: 59.5%;
      }

      p {
        margin-bottom: 5px;
        color: $pink;
      }
    }

    &__list {
      margin: 0;
      padding: 10px 0;
      display: flex;
      flex-wrap: wrap;
      font-size: 12px;
      font-weight: 300;
      color: $pink;
      @include media-breakpoint-up(xl) {
        padding-bottom: 20px;
        font-size: $font-size-sm;
      }
    }

    &__list-item {
      margin-right: 20px;
      display: inline-flex;
      flex-wrap: wrap;
      align-items: center;
      font-size: 14px;

      i {
        margin-right: 10px;
        font-size: 1rem;
        color: $pink;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    &__number {
      display: inline-flex;
      align-items: center;
      color: $green;

      i {
        margin-right: 10px;
        font-size: 12px;
      }
    }

    &__price {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 400;
      color: $blue;
      @include media-breakpoint-up(xl) {
        font-size: 18px;
        font-weight: 600;
      }
    }

    &__image {
      margin-bottom: 25px;
      position: relative;
      display: none;
      width: 100%;
      border-radius: 8px;
      overflow: hidden;
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
      @include media-breakpoint-up(lg) {
        width: 43%;
        display: block;
        border-radius: 4px;
      }
      @include media-breakpoint-up(xxl) {
        width: 40.5%;
      }

      &:hover {
        text-decoration: none;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 39%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 100%);
        content: '';
      }

      picture,
      img {
        display: block;
        width: 100%;
        border-radius: 8px;
        @include media-breakpoint-up(lg) {
          border-radius: 4px;
        }
      }
    }

    &__tag {
      position: absolute;
      top: 10px;
      left: 10px;
      z-index: 2;

      span {
        padding: 3px 8px;
        display: inline-block;
        font-size: 10px;
        text-transform: uppercase;
        color: $white;
        border-radius: 2px;
        background: $green;
      }
    }
  }



  .leaflet-popup-close-button {
    top: 0 !important;
    right: 0 !important;
    z-index: 999;

    @include media-breakpoint-up(lg) {
      top: -20px !important;
      right: 0 !important;
    }
  }

  .leaflet-popup {
    bottom: 50px !important;
    margin-bottom: -10px !important;

    @include media-breakpoint-down(md) {
      display: none !important;
    }
  }
}

.house-map-wrapper {
  position: relative;

  .projectmap-togrid {
    position: fixed;
    bottom: 110px;
    padding: 32px;
    z-index: 99;
    right: 0;
    left: 0;
    margin: 0 auto;
    width: fit-content;

    @include media-breakpoint-up(lg) {
      display: none;
    }

    &.projectmap-togrid--absolute {
      position: absolute;
    }

    span {
      display: flex;
      align-items: center;
      height: 32px;
      padding: 0 24px;
      border-radius: 16px;
      gap: 8px;
      color: $white;
      font-size: 12px;
      background-color: $blue;

      i {
        color: $white;
      }
    }
  }
}

.project-list-wrapper {
  margin: 0 20px;
  z-index: 1;
  position: relative;
  transform: translateY(-160px);

  .card-house-list {
    box-shadow: 0 16px 48px rgba($black, 0.12);
    background-color: $white;
    border-radius: 8px;

    .card-house-list__content {
      padding: 0 20px 20px;

    }
  }
}

.card-onboarding {
  margin-bottom: 50px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 70px;
  }

  &__holder {
    padding: 20px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 8px;
    background: rgba($gray-300, 0.8);
    @include media-breakpoint-up(lg) {
      border-radius: 16px;
    }
    @include media-breakpoint-up(sm) {
      padding: 22px;
    }
  }

  &__left {
    position: relative;
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    @include media-breakpoint-up(lg) {
      width: 60%;
    }
  }

  &__right {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: flex-end;
      width: 40%;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 70px;
    }
  }

  &__form {
    padding-top: 20px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      width: 40%;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .form-group {
      width: 100%;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: calc(100% - 180px);
      }
    }
  }

  &__form-button {
    width: 100%;
    @include media-breakpoint-up(xl) {
      padding-right: 1rem;
      padding-left: 1rem;
      width: 180px;
    }
  }

  &__image {
    margin-top: -35px;
    padding-right: 10px;
    position: absolute;
    top: 50%;
    right: 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 98px;
    height: 70px;
    @include background-image(onboarding-bg, svg, center center, no-repeat, 100% 100%);
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      position: static;
    }
    @include media-breakpoint-up(xl) {
      width: 122px;
      height: 96px;
    }

    img {
      max-height: 44px;
      @include media-breakpoint-up(xl) {
        max-height: 60px;
      }
    }
  }

  &__text {
    padding-top: 10px;
    padding-right: 114px;
    width: 100%;
    font-size: 14px;
    color: $blue;
    @include media-breakpoint-up(lg) {
      padding-right: 0;
      padding-left: 20px;
      width: calc(100% - 98px);
    }
    @include media-breakpoint-up(xl) {
      padding-left: 50px;
      width: calc(100% - 122px);
      font-size: $font-size-sm;
    }

    p {
      @include media-breakpoint-up(lg) {
        margin-bottom: 10px;
      }
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      width: auto;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 50px;
    }
  }

  &__list-item {
    margin: 0 3px;
    @include media-breakpoint-up(md) {
      margin-right: 0;
      margin-left: 8px;
    }
  }

  &__list-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    &:hover {
      text-decoration: none;
      color: $white;
      background: $cyan;
    }
  }
}

/* stylelint-enable */
}
.pageblock_14219 {
.woning-item {
	position: relative;
    height: 100%;
	transition: all .2s ease-in-out;

    .item-btn {
        bottom: 20px;
        position: absolute;
        width: 85%;
    }

	.p-absolute-left, .p-absolute-right {
		position: absolute;
		z-index: 99;
		top: 10px;
		padding: 0 2.5em;
		transition: all .2s ease-in-out;
	}
	.p-absolute-left {
		left: -5px;
	}
	.p-absolute-right {
		right: -5px;
	}

	&:hover {
		// @extend .shadow;
		.p-absolute-left, .p-absolute-right {
			padding: 0 2.75em;
		}
		.woning-img {
			.woning-img-overlay {
				transform: translateY(0%);
			}
		}
	}
	.woning-img {
		position: relative;
		overflow: hidden;
		height: 15em;
		background-position: center center;
		background-size: cover;

		.woning-img-overlay {
			position: absolute;
			width: 100%;
			min-height: 100%;
			transition: all .3s ease-in-out;
			transform: translateY(100%);
			background-color: rgba(137, 176, 199, .5);
			.overlay-button {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
	}
}

}
.pageblock_14223 {
.landing-banner {
  margin-bottom: 30px;
  padding-top: 160px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 124px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 50px;
    padding-top: 160px;
  }

  &__holder {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      min-height: 640px;
      border-radius: 0 0 16px 16px;
      background: $blue;
    }
  }

  &__content {
    padding: 50px 20px;
    position: relative;
    z-index: 4;
    color: $white;
    background: $blue;
    @include media-breakpoint-up(lg) {
      padding: 0 0 0 20px;
      z-index: 6;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 280px;
      width: 40%;
    }

    &:after {
      position: absolute;
      top: -180px;
      left: -185px;
      z-index: -1;
      width: 370px;
      height: 700px;
      transform: scaleX(-1);
      content: '';
      @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    p,
    h1 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }
  }

  &__image {
    margin-top: -10px;
    position: relative;
    z-index: 5;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
      overflow: visible;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      content: '';
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: -185px;
        width: 370px;
        height: 100%;
        transform: scaleX(-1);
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(lg) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__search {
    margin: -28px 0 0 20px;
    padding: 20px;
    position: relative;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    border-radius: 8px;
    background: $white;
    overflow: hidden;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 20px;
      bottom: -40px;
      width: 400px;
    }
    @include media-breakpoint-up(lg) {
      margin-right: -480px;
      right: 50%;
    }
    @include media-breakpoint-up(xl) {
      margin-right: -580px;
      padding: 45px;
      width: 530px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: -680px;
    }

    p {
      margin-bottom: 8px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }
    }
  }

  &__search-form {
    position: relative;
    width: 100%;

    input {
      padding: 0 60px 0 20px;
      width: 100%;
      height: 56px;
      font-size: 16px;
      font-weight: 300;
      border: 1px solid $border-color;
      border-radius: 4px;
    }

    button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 40px;
      height: 40px;
      font-size: 12px;
      color: $white;
      border: 0;
      border-radius: 50%;
      background: $cyan;
    }
  }

  &__block {
    margin: -50px 0 0 20px;
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    color: $white;
    border-radius: 10px 0 0 10px;
    background: $blue;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 370px;
    }
    @include media-breakpoint-up(xl) {
      width: 480px;
    }
  }

  &__block-img {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 112px;
    @include background-image(hero-block-bg, svg, center right, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      width: 160px;
      height: 152px;
    }

    img {
      max-height: 70px;
      @include media-breakpoint-up(xl) {
        max-height: 92px;
      }
    }
  }

  &__block-text {
    padding-left: 20px;
    width: calc(100% - 120px);
    font-size: 12px;
    @include media-breakpoint-up(xl) {
      padding-left: 35px;
      width: calc(100% - 160px);
      font-size: $font-size-sm;
    }

    strong {
      margin-bottom: 5px;
      display: block;
      font-size: $font-size-sm;
      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }

    p {
      margin-bottom: 5px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 15px;
      }
    }

    span {
      display: block;
      color: $cyan;
    }
  }
}

}
.pageblock_14224 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14225 {
.plans-section {
	position: relative;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
		};
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 150px;
			bottom: 120px;
		}
	}
	margin: {
		top: 60px;
		bottom: 60px;
	}

	h3 {
		strong {
			color: $pink;
		}
	}

	&__list {
		margin: 0 -8px;
		padding: 15px 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: calc(100% + 16px);
		@include media-breakpoint-up(xl) {
			padding-top: 30px;
		}
	}

	&__list-item {
		padding: 0 8px 8px;
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 50%;
		}
		@include media-breakpoint-up(xl) {
			padding-bottom: 16px;
			width: 33.333%;
		}
	}

	&__list-link {
		padding: 30px 60px 30px 50px;
		position: relative;
		display: block;
		width: 100%;
		font-size: 18px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: -0.5px;
		color: $blue;
		border-radius: 8px;
		background: $gray-300;
		transition: all 0.3s;
		@include media-breakpoint-up(xl) {
			padding: 30px 80px;
		}

		&:after {
			margin-top: -24px;
			position: absolute;
			top: 50%;
			right: 15px;
			z-index: 2;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			font-family: $font-family-icon;
			font-size: 14px;
			font-weight: 400;
			color: $blue;
			border-radius: 50%;
			background: $white;
			transition: all 0.3s;
			content: '\e904';
			@include media-breakpoint-up(xl) {
				right: 20px;
			}
		}

		i {
			position: absolute;
			top: 50%;
			left: 20px;
			font-size: 20px;
			transform: translateY(-50%);
			transition: all 0.3s;
			@include media-breakpoint-up(xl) {
				left: 40px;
			}
		}

		&:hover {
			text-decoration: none;
			color: $blue;

			i {
				color: $cyan;
			}

			&:after {
				color: $white;
				background: $cyan;
			}
		}
	}
}

}
.pageblock_14226 {
.cta-banner {
	margin: 0 auto 56px;
	position: relative;
	width: 100%;
	max-width: 1920px;
	border-radius: 16px;
	background: $blue;
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 640px;
		border-radius: 24px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__content {
		margin: -20px 0 0;
		padding: 40px 20px;
		position: relative;
		color: $white;
		border-radius: 0 12px 12px 0;
		background: $blue;
		@include media-breakpoint-up(lg) {
			margin: 0;
			padding: 0 20px 0 0;
			width: 50%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(xl) {
			width: 40%;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 50px;
			width: 36%;
		}
		@include media-breakpoint-up(xxxl) {
			padding-right: 0;
			padding-left: 90px;
			width: 34%;
		}

		h6 {
			margin-bottom: 16px;
			color: $cyan;
		}

		h2 {
			color: $white;
			@include media-breakpoint-up(lg) {
				margin-bottom: 32px;
			}
		}

		p {
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				line-height: 26px;
			}
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 240px;
		@include media-breakpoint-up(sm) {
			height: auto;
		}
		@include media-breakpoint-up(lg) {
			width: 50%;
			height: 640px;
			border-radius: 24px 0 0 24px;
		}
		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		&:after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				right: -185px;
				width: 370px;
				height: 100%;
				content: '';
				@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 24px 0 0 24px;
			}
		}
	}
}

}
.pageblock_14227 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14228 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14229 {
.specialists-form {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(xl) {
		margin-bottom: 120px;
	}

	&__holder {
		margin: 0 -20px;
		position: relative;
		z-index: 2;
		width: calc(100% + 40px);
		border-radius: 16px;
		background-color: $blue;
		overflow: hidden;
		@include background-image(specialist-bg, svg, center center, no-repeat, cover);
		@include media-breakpoint-up(sm) {
			margin: 0;
			width: 100%;
		}
		@include media-breakpoint-up(xl) {
			border-radius: 18px;
			padding: {
				top: 90px;
				bottom: 90px;
			}
		}
		padding: {
			top: 50px;
			right: 20px;
			bottom: 50px;
			left: 20px;
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: -390px;
			z-index: -1;
			width: 776px;
			height: 1522px;
			transform: scaleX(-1);
			content: '';
			@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
		}
	}

	&__top {
		margin-bottom: 10px;
		width: 100%;
		font-size: 16px;
		text-align: center;
		color: rgba($white, 0.4);
		@include media-breakpoint-up(xl) {
			margin-bottom: 20px;
			font-size: 20px;
		}

		h2 {
			margin-bottom: 25px;
			color: $white;
			@include media-breakpoint-down(lg) {
				font-size: 24px;
				line-height: 32px;
				letter-spacing: -0.75px;
			}
		}
	}

	&__slider {
		position: relative;
		width: 100%;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 80px;
		border-radius: 8px;
		background: $pink;
	}

	&__slide {
		margin: 0 8px;
		width: 160px;
	}

	&__form {
		margin-bottom: 30px;
		padding-right: 20px;
		width: 100%;
		@include media-breakpoint-up(xl) {
			margin-bottom: 60px;
		}
	}
}

.card-search {
	padding-left: 80px;
	position: relative;
	width: 100%;
	border-radius: 10px 0 0 10px;
	background: $white;
	box-shadow: 0 12px 24px rgba($black, 0.12);

  @include media-breakpoint-up(md) {
    padding-left: 100px;
  }

	label {
		padding-left: 18px;
		position: absolute;
		top: 0;
		left: 0;
		display: inline-flex;
		align-items: center;
		height: 60px;
		font-size: $font-size-sm;
		font-weight: 600;
		color: $blue;

    @include media-breakpoint-up(md) {
      padding-left: 28px;
      height: 80px;
    }
	}

	&:after {
		position: absolute;
		top: 0;
		left: 100%;
		width: 0;
		height: 0;
		border-color: transparent transparent transparent $white;
		border-style: solid;
		border-width: 30px 0 30px 24px;
		content: '';

    @include media-breakpoint-up(md) {
      border-width: 40px 0 40px 24px;
    }
	}

	&__search-input {
		padding-right: 70px;
		width: 100%;
		height: 60px;
		font-size: $font-size-sm;
		font-weight: 300;
		color: $teal;
		border: 0;
		background: transparent;

    @include media-breakpoint-up(md) {
      height: 80px;
    }

		&::placeholder {
			color: $teal;
		}
	}

	&__search-btn {
		padding-top: 4px;
		position: absolute;
		top: 6px;
		right: 5px;
		width: 48px;
		height: 48px;
		font-size: $font-size-sm;
		color: $white;
		border: 0;
		border-radius: 50%;
		background: $orange;

    @include media-breakpoint-up(md) {
      top: 16px;
    }
	}

	&--select {
		padding-right: 245px;
	}

	&__select {
		padding-right: 20px;
		position: absolute;
		top: 15px;
		right: 40px;
		width: 210px;
		border-left: 1px solid $light-border;

		select {
			padding: 0 40px 0 15px;
			height: 48px;
			font-size: 16px;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}
	}
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3;

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}
}
.pageblock_14230 {
.review-section {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	@include media-breakpoint-up(xl) {
		margin-bottom: 120px;
	}

	&__top {
		h2 {
			margin-bottom: 32px;

			strong {
				display: block;
				color: $pink;
			}
		}

		p {
			margin-bottom: 32px;

			strong {
				font-weight: 700;
				color: $pink;
			}
		}
	}

	&__block {
		padding: 20px;
		color: $blue;
		border-radius: 8px;
		background: $gray-300;
		@include media-breakpoint-up(xl) {
			padding: 48px;
		}
	}

	&__block-top {
		min-height: 165px;
		font-size: 18px;
		@include media-breakpoint-up(xl) {
			font-size: 20px;
		}
	}

	&__block-bottom {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	&__rating-text {
		padding: 10px 0;
		font-size: 14px;
		font-weight: 300;
		color: $pink;

		strong {
			font-weight: 600;
			color: $blue;
		}
	}

	&__rating {
		margin: 0 20px 0 0;
		padding: 0;
		display: inline-flex;

		li {
			margin-right: 2px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 24px;
			height: 24px;
			background: $pink;
		}
	}
}

}
.pageblock_14231 {
.specialists-slider {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	background-color: $blue;
	overflow: hidden;
	@include background-image(specialists-bg, svg, center center, no-repeat, cover);
	@include media-breakpoint-up(xl) {
		margin-bottom: 120px;
		padding: {
			top: 90px;
			bottom: 90px;
		}
	}
	padding: {
		top: 50px;
		bottom: 50px;
	}

	&__top {
		margin-bottom: 40px;
		width: 100%;
		font-size: 16px;
		line-height: 26px;
		letter-spacing: -0.5px;
		text-align: center;
		color: rgba($white, 1);
		@include media-breakpoint-up(lg) {
			font-size: 20px;
			line-height: 28px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 80px;
		}

		h2 {
			margin-bottom: 25px;
			color: $white;
		}
	}

	&__slider {
		position: relative;
		width: 100%;
	}

	&__slide {
		margin-right: 10px;
		width: 350px;
		@include media-breakpoint-up(xl) {
			margin-right: 16px;
			width: 528px;
		}
		@include media-breakpoint-up(xxl) {
			width: 665px;
		}
	}

	&__holder {
		margin-bottom: 30px;
		position: relative;
		width: 100%;
		@include media-breakpoint-up(xl) {
			margin-bottom: 80px;
		}
	}

	&__next,
	&__prev {
		display: none !important;
		@include media-breakpoint-up(xl) {
			position: absolute;
			top: 115px;
			z-index: 3;
			display: flex !important;
			align-content: center;
			align-items: center;
			width: 40px;
			height: 140px;
			font-size: 13px;
			color: $blue;
			transition: all 0.3s;
		}

		&.slick-disabled {
			opacity: 0;
			visibility: hidden;
		}

		&:hover {
			text-decoration: none;
			color: $blue;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 0;
			height: 0;
			border-style: solid;
			content: '';
		}
	}

	&__prev {
		padding-left: 12px;
		left: 0;

		&:after {
			border-color: transparent transparent transparent $white;
			border-width: 70px 0 70px 40px;
		}
	}

	&__next {
		padding-left: 15px;
		right: 0;

		&:after {
			border-color: transparent $white transparent transparent;
			border-width: 70px 40px 70px 0;
		}
	}
}

.card-story {
	position: relative;
	display: block;
	width: 100%;

	&:hover {
		text-decoration: none;
	}

	&__content {
		padding: 15px 20px;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		font-size: $font-size-sm;
		font-weight: 300;
		color: $white;
		@include media-breakpoint-up(xl) {
			padding: 25px 30px;
		}

		h6 {
			color: $white;
		}

		p {
			margin-bottom: 10px;
			line-height: 24px;
			letter-spacing: -0.4px;
		}
	}

	&__icon {
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 2;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		font-size: 12px;
		color: $white;
		border-radius: 50%;
		background: $cyan;
		transition: all 0.3s;
		@include media-breakpoint-up(xl) {
			top: 30px;
			right: 30px;
			width: 48px;
			height: 48px;
			font-size: 16px;
		}
	}

	&__image {
		position: relative;
		display: block;
		width: 100%;
		border-radius: 8px;
		overflow: hidden;

		&:hover {
			text-decoration: none;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0) 100%);
			content: '';
		}

		picture,
		img {
			display: block;
			width: 100%;
			border-radius: 8px;
		}
	}
}

}
.pageblock_14232 {
.faq-section {
  --size-link-asset: 32px;
	margin-bottom: 60px;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(lg) {
    --size-link-asset: 54px;
		margin-bottom: 120px;
	}

	h2 {
		margin-bottom: 20px;
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
		}

		strong {
			color: $pink;
		}
	}

  &__list {
    &--with-asset {
      .faq-section__panel {
        padding-left: calc(var(--size-link-asset) + 12px);
      }
    }
  }

	&__top {
		color: $blue;
		@include media-breakpoint-up(lg) {
			color: $pink;
		}
	}

	&__holder {
		margin-bottom: 20px;
		padding: 25px;
		width: 100%;
		border-radius: 8px;
		background-color: $gray-300;
		@include media-breakpoint-up(md) {
			padding: 45px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
			padding: 85px;
			border-radius: 16px;
		}

		h3 {
			margin-bottom: 25px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 50px;
			}
		}
	}

	&__item {
		margin-bottom: 18px;
		padding-bottom: 18px;
		border-bottom: 1px solid $light;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}

		&.is-active {
			.faq-section__link {
				i {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__link {
		padding: 10px 35px 10px 0;
		position: relative;
		display: block;
		font-weight: 600;
		color: $blue;

		@include media-breakpoint-up(lg) {
			padding: 0px 30px 0px 0;
		}

    &:not(.js-faq-link) {
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;

      &__asset {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--size-link-asset);
        height: var(--size-link-asset);
        margin-right: 12px;
        border-radius: 50%;
        background-color: $light;

        img {
          max: {
            width: 60%;
            height: 60%;
          }
        }
      }
    }

		i {
			position: absolute;
			top: 13px;
			right: 0;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			font-size: 8px;
			transition: all 0.3s;
			@include media-breakpoint-up(lg) {
				margin-top: -15px;
				top: 50%;
				width: 30px;
				height: 30px;
				font-size: 10px;
			}
		}

		&.js-faq-link {
      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
	}

	&__panel {
		display: none;
		@include media-breakpoint-up(xl) {
			padding-right: 100px;
		}

		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}

	&--inner {
		padding-top: 20px;
		@include media-breakpoint-up(xl) {
			padding-top: 80px;
		}

		.faq-section__holder {
			@include media-breakpoint-up(xl) {
				padding: 30px 60px;
			}
		}

		&:after {
			display: none;
		}
	}
}

}
.pageblock_14233 {
.project-slider {
	margin-bottom: 50px;
	position: relative;
	width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(lg) {
		margin-bottom: 80px;
	}

	&--background {
		background-image: linear-gradient(to bottom, rgba(250, 244, 239, 1) 0%, rgba(255, 253, 249, 1) 100%);
		@include media-breakpoint-up(xl) {
			padding: {
				top: 130px;
				bottom: 30px;
			};
		}
		padding: {
			top: 60px;
			bottom: 20px;
		};
	}

	&--background2 {
		margin-bottom: 60px;
		background: $gray-300;
		@include media-breakpoint-up(xl) {
			margin-bottom: 120px;
			padding: {
				top: 120px;
				bottom: 120px;
			};
		}
		padding: {
			top: 60px;
			bottom: 60px;
		};
	}

	&__top {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	&__top-left {
		display: inline-flex;
		align-items: center;

		h4 {
			margin-bottom: 0;
		}
	}

	&__number {
		margin-right: 15px;
		padding: 0 15px;
		display: inline-flex;
		align-items: center;
		height: 32px;
		font-size: 14px;
		color: $pink;
		border-radius: 40px;
		background: $gray-300;
		@include media-breakpoint-up(xl) {
			margin-right: 25px;
			padding: 0 25px;
			height: 48px;
		}
	}

	&__slider {
		position: relative;
		width: 100%;

		&:after {
			@include media-breakpoint-up(xxxl) {
				position: absolute;
				top: 0;
				left: 100%;
				width: 70000px;
				height: 100%;
				background: rgba($light-alt, 0.5);
				content: '';
			}
		}
	}

	&__slide {
		margin-right: 10px;
		width: 350px;
		@include media-breakpoint-up(xl) {
			margin-right: 16px;
			width: 528px;
		}

    .card-house {
      .card-house__bar .card-house__bar-inner {
        span {
          left: 0;
          border-radius: 0 4px 0 0;
        }
      }
    }
	}

	&__holder {
		position: relative;
		width: 100%;
    max-width: 1920px;
    margin: 0 auto;
    overflow: hidden;
	}

	&__next,
	&__prev {
		display: none !important;
		@include media-breakpoint-up(xl) {
			position: absolute;
			top: 115px;
			z-index: 3;
			display: flex !important;
			align-content: center;
			align-items: center;
			width: 40px;
			height: 140px;
			font-size: 13px;
			color: $blue;
			border: 0;
			background: transparent;
			transition: all 0.3s;
			cursor: pointer;
		}

		&.slick-disabled {
			opacity: 0;
			visibility: hidden;
		}

		&:hover {
			text-decoration: none;
			color: $blue;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			z-index: -1;
			width: 0;
			height: 0;
			border-style: solid;
			content: '';
		}
	}

	&--step {
		z-index: 9;

		.project-slider__slider {
			&:after {
				display: none;
			}
		}
	}

	&__prev {
		padding-left: 12px;
		left: 0;

		&:after {
			border-color: transparent transparent transparent $white;
			border-width: 70px 0 70px 40px;
		}
	}

	&__next {
		padding-left: 15px;
		right: 0;

		&:after {
			border-color: transparent $white transparent transparent;
			border-width: 70px 40px 70px 0;
		}
	}

	&--inner {
		.card-house__list {
			padding: 8px 0;
		}
	}
}

.card-house {
	position: relative;
	display: block;
	width: 100%;

  a:hover {
    text-decoration: none;
  }

	&:hover {
		text-decoration: none;
	}

	&__content {
		padding-top: 15px;
		width: 100%;
		font-size: $font-size-sm;
		font-weight: 300;
		color: $teal;

		h6 {
			margin-bottom: 8px;
			@include media-breakpoint-up(lg) {
				margin-bottom: 16px;
			}
		}
	}

	&__list {
		margin: 0;
		padding: 0 0 10px;
		display: flex;
		flex-wrap: wrap;
		font-size: 14px;
		font-weight: 300;
		@include media-breakpoint-up(lg) {
			padding-bottom: 12px;
			font-size: $font-size-sm;
		}
	}

	&__list-item {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;

		&:after {
			margin: 0 10px;
			display: inline-block;
			width: 4px;
			height: 4px;
			background: $teal;
			transform: rotate(45deg);
			content: '';
			@include media-breakpoint-up(xl) {
				margin: 0 15px;
			}
		}

    &.city {
      font-size: 20px;
      font-weight: 500;
    }

		a {
			text-decoration: none;
			color: $cyan;
		}

		&:last-child {
			&:after {
				display: none;
			}
		}
	}

	&__bar {
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 4px;
		background: rgba(242, 232, 225);
	}

	&__bar-inner {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 0;
		//width: attr(data-percentage %); // not supported yet


		height: 4px;
		background: $cyan;

		span {
			padding: 0 10px;
			position: absolute;
			bottom: calc(100% + 15px);
			left: calc(100% - 50px);
			display: inline-flex;
			align-items: center;
			justify-content: center;
			width: 100px;
			height: 26px;
			font-size: 12px;
			text-align: center;
			color: $blue;
			border-radius: 4px 4px 0 0;
			background: $white;

			&:after {
				margin-left: -4px;
				position: absolute;
				bottom: -4px;
				left: 50%;
				width: 0;
				height: 0;
				border-color: $white transparent transparent transparent;
				border-style: solid;
				border-width: 5px 4px 0;
				content: '';
			}
		}
	}

	&__heart {
		position: absolute;
		top: 15px;
		right: 15px;
		z-index: 2;
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		font-size: 10px;
		color: $red;
		border-radius: 50%;
		background: $white;
		transition: all 0.3s;
		@include media-breakpoint-up(xl) {
			top: 26px;
			right: 26px;
		}

    i.icon-heart-alt {
      color: $blue;
    }

		&.is-active,
		&:hover {
			text-decoration: none;

			i {
				&:before {
					content: '\e919';
          color: $blue;

				}

        &.icon-heart-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
			}
    }
	}

	&__image {
		position: relative;
		display: block;
		width: 100%;
		border-radius: 8px;
		overflow: hidden;

		&:hover {
			text-decoration: none;
		}

		&:after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(to right, rgba(0, 0, 0, 0.1) 39%, rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0) 100%);
			content: '';
		}

		picture,
		img {
			display: block;
			width: 100%;
			border-radius: 8px;
      aspect-ratio: 3/2;
      object-fit: cover;
    }
	}

	&__label {
		position: absolute;
		top: 22px;
		left: 0;
		z-index: 1;

		span {
			padding: 0 20px 0 12px;
			display: inline-flex;
			align-items: center;
			height: 24px;
			font-size: 12px;
			font-weight: 600;
			color: $white;
			background: $cyan;
			clip-path: polygon(0 0, 100% 0, 87% 100%, 0 100%);
		}
	}

	&__price {
		color: $cyan;
	}
}

.card-house {
  a:hover {
    text-decoration: none;
  }
}

.card-house__image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
}
.pageblock_14234 {
.seo-text {
	position: relative;
	width: 100%;
	font-size: $font-size-base;
	@include media-breakpoint-up(md) {
		font-size: $font-size-lg;
	}
	@include media-breakpoint-up(lg) {
		border-top: 1px solid $border-color;
		padding: {
			top: 60px;
		}
		margin: {
			top: 60px;
		}
	}
	@include media-breakpoint-up(xl) {
		padding: {
			top: 120px;
		}
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	padding: {
		top: 30px;
	}
	margin: {
		bottom: 60px;
	}

	h3 {
		@include media-breakpoint-up(lg) {
			margin-bottom: 24px;
		}

		strong {
			color: $pink;
		}
	}

	p {
		margin-bottom: 30px;
		color: $blue;
		@include media-breakpoint-up(lg) {
			margin-bottom: 40px;
			color: $pink;
		}
	}

	&__list {
		margin: 0 -8px;
		padding: 15px 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: calc(100% + 16px);
		@include media-breakpoint-up(xl) {
			padding-top: 30px;
		}
	}

	&__list-item {
		padding: 0 8px 8px;
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 50%;
		}
		@include media-breakpoint-up(xl) {
			padding-bottom: 16px;
			width: 33.333%;
		}
	}

	&__list-link {
		padding: 30px 60px 30px 50px;
		position: relative;
		display: block;
		width: 100%;
		color: $blue;
		border-radius: 8px;
		background: $gray-300;
		transition: all 0.3s;
		@include media-breakpoint-up(xl) {
			padding: 30px 80px;
		}

		&:after {
			margin-top: -24px;
			position: absolute;
			top: 50%;
			right: 15px;
			z-index: 2;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			font-family: $font-family-icon;
			font-size: 14px;
			font-weight: 400;
			color: $blue;
			border-radius: 50%;
			background: $white;
			transition: all 0.3s;
			content: '\e904';
			@include media-breakpoint-up(xl) {
				right: 20px;
			}
		}

		i {
			position: absolute;
			top: 50%;
			left: 20px;
			font-size: 20px;
			transform: translateY(-50%);
			transition: all 0.3s;
			@include media-breakpoint-up(xl) {
				left: 40px;
			}
		}

		&:hover {
			text-decoration: none;
			color: $blue;

			i {
				color: $cyan;
			}

			&:after {
				color: $white;
				background: $cyan;
			}
		}
	}
}

}
.pageblock_14240 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14242 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14643 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14247 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14661 {
.news-banner {
  $self: &;
  margin-bottom: 60px;
  padding-top: 165px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 320px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    max-width: 1840px;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    span {
      margin: 4px 0 0 15px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 4px;
      background: $cyan;
    }
  }

  &__content {
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    color: $white;

    h6 {
      font-weight: 600;
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: $white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 640px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__button {
    padding: 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(xl) {
      top: 50px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14662 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14251 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14253 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14653 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14259 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14639 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14641 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14263 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14265 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14650 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14268 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14275 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14277 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14634 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14289 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14291 {
.cta-banner {
	margin: 0 auto 56px;
	position: relative;
	width: 100%;
	max-width: 1920px;
	border-radius: 16px;
	background: $blue;
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 640px;
		border-radius: 24px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__content {
		margin: -20px 0 0;
		padding: 40px 20px;
		position: relative;
		color: $white;
		border-radius: 0 12px 12px 0;
		background: $blue;
		@include media-breakpoint-up(lg) {
			margin: 0;
			padding: 0 20px 0 0;
			width: 50%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(xl) {
			width: 40%;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 50px;
			width: 36%;
		}
		@include media-breakpoint-up(xxxl) {
			padding-right: 0;
			padding-left: 90px;
			width: 34%;
		}

		h6 {
			margin-bottom: 16px;
			color: $cyan;
		}

		h2 {
			color: $white;
			@include media-breakpoint-up(lg) {
				margin-bottom: 32px;
			}
		}

		p {
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				line-height: 26px;
			}
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 240px;
		@include media-breakpoint-up(sm) {
			height: auto;
		}
		@include media-breakpoint-up(lg) {
			width: 50%;
			height: 640px;
			border-radius: 24px 0 0 24px;
		}
		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		&:after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				right: -185px;
				width: 370px;
				height: 100%;
				content: '';
				@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 24px 0 0 24px;
			}
		}
	}
}

}
.pageblock_14292 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14294 {
.specialists-form {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(xl) {
		margin-bottom: 120px;
	}

	&__holder {
		margin: 0 -20px;
		position: relative;
		z-index: 2;
		width: calc(100% + 40px);
		border-radius: 16px;
		background-color: $blue;
		overflow: hidden;
		@include background-image(specialist-bg, svg, center center, no-repeat, cover);
		@include media-breakpoint-up(sm) {
			margin: 0;
			width: 100%;
		}
		@include media-breakpoint-up(xl) {
			border-radius: 18px;
			padding: {
				top: 90px;
				bottom: 90px;
			}
		}
		padding: {
			top: 50px;
			right: 20px;
			bottom: 50px;
			left: 20px;
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: -390px;
			z-index: -1;
			width: 776px;
			height: 1522px;
			transform: scaleX(-1);
			content: '';
			@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
		}
	}

	&__top {
		margin-bottom: 10px;
		width: 100%;
		font-size: 16px;
		text-align: center;
		color: rgba($white, 0.4);
		@include media-breakpoint-up(xl) {
			margin-bottom: 20px;
			font-size: 20px;
		}

		h2 {
			margin-bottom: 25px;
			color: $white;
			@include media-breakpoint-down(lg) {
				font-size: 24px;
				line-height: 32px;
				letter-spacing: -0.75px;
			}
		}
	}

	&__slider {
		position: relative;
		width: 100%;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 80px;
		border-radius: 8px;
		background: $pink;
	}

	&__slide {
		margin: 0 8px;
		width: 160px;
	}

	&__form {
		margin-bottom: 30px;
		padding-right: 20px;
		width: 100%;
		@include media-breakpoint-up(xl) {
			margin-bottom: 60px;
		}
	}
}

.card-search {
	padding-left: 80px;
	position: relative;
	width: 100%;
	border-radius: 10px 0 0 10px;
	background: $white;
	box-shadow: 0 12px 24px rgba($black, 0.12);

  @include media-breakpoint-up(md) {
    padding-left: 100px;
  }

	label {
		padding-left: 18px;
		position: absolute;
		top: 0;
		left: 0;
		display: inline-flex;
		align-items: center;
		height: 60px;
		font-size: $font-size-sm;
		font-weight: 600;
		color: $blue;

    @include media-breakpoint-up(md) {
      padding-left: 28px;
      height: 80px;
    }
	}

	&:after {
		position: absolute;
		top: 0;
		left: 100%;
		width: 0;
		height: 0;
		border-color: transparent transparent transparent $white;
		border-style: solid;
		border-width: 30px 0 30px 24px;
		content: '';

    @include media-breakpoint-up(md) {
      border-width: 40px 0 40px 24px;
    }
	}

	&__search-input {
		padding-right: 70px;
		width: 100%;
		height: 60px;
		font-size: $font-size-sm;
		font-weight: 300;
		color: $teal;
		border: 0;
		background: transparent;

    @include media-breakpoint-up(md) {
      height: 80px;
    }

		&::placeholder {
			color: $teal;
		}
	}

	&__search-btn {
		padding-top: 4px;
		position: absolute;
		top: 6px;
		right: 5px;
		width: 48px;
		height: 48px;
		font-size: $font-size-sm;
		color: $white;
		border: 0;
		border-radius: 50%;
		background: $orange;

    @include media-breakpoint-up(md) {
      top: 16px;
    }
	}

	&--select {
		padding-right: 245px;
	}

	&__select {
		padding-right: 20px;
		position: absolute;
		top: 15px;
		right: 40px;
		width: 210px;
		border-left: 1px solid $light-border;

		select {
			padding: 0 40px 0 15px;
			height: 48px;
			font-size: 16px;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}
	}
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3;

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}
}
.pageblock_14297 {
.faq-section {
  --size-link-asset: 32px;
	margin-bottom: 60px;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(lg) {
    --size-link-asset: 54px;
		margin-bottom: 120px;
	}

	h2 {
		margin-bottom: 20px;
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
		}

		strong {
			color: $pink;
		}
	}

  &__list {
    &--with-asset {
      .faq-section__panel {
        padding-left: calc(var(--size-link-asset) + 12px);
      }
    }
  }

	&__top {
		color: $blue;
		@include media-breakpoint-up(lg) {
			color: $pink;
		}
	}

	&__holder {
		margin-bottom: 20px;
		padding: 25px;
		width: 100%;
		border-radius: 8px;
		background-color: $gray-300;
		@include media-breakpoint-up(md) {
			padding: 45px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
			padding: 85px;
			border-radius: 16px;
		}

		h3 {
			margin-bottom: 25px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 50px;
			}
		}
	}

	&__item {
		margin-bottom: 18px;
		padding-bottom: 18px;
		border-bottom: 1px solid $light;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}

		&.is-active {
			.faq-section__link {
				i {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__link {
		padding: 10px 35px 10px 0;
		position: relative;
		display: block;
		font-weight: 600;
		color: $blue;

		@include media-breakpoint-up(lg) {
			padding: 0px 30px 0px 0;
		}

    &:not(.js-faq-link) {
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;

      &__asset {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--size-link-asset);
        height: var(--size-link-asset);
        margin-right: 12px;
        border-radius: 50%;
        background-color: $light;

        img {
          max: {
            width: 60%;
            height: 60%;
          }
        }
      }
    }

		i {
			position: absolute;
			top: 13px;
			right: 0;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			font-size: 8px;
			transition: all 0.3s;
			@include media-breakpoint-up(lg) {
				margin-top: -15px;
				top: 50%;
				width: 30px;
				height: 30px;
				font-size: 10px;
			}
		}

		&.js-faq-link {
      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
	}

	&__panel {
		display: none;
		@include media-breakpoint-up(xl) {
			padding-right: 100px;
		}

		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}

	&--inner {
		padding-top: 20px;
		@include media-breakpoint-up(xl) {
			padding-top: 80px;
		}

		.faq-section__holder {
			@include media-breakpoint-up(xl) {
				padding: 30px 60px;
			}
		}

		&:after {
			display: none;
		}
	}
}

}
.pageblock_14298 {
.card-house {
  a:hover {
    text-decoration: none;
  }
}

.card-house__image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
}
.pageblock_14305 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14307 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14644 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14311 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14313 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14654 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14317 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14319 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14635 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14323 {
.faq-section {
  --size-link-asset: 32px;
	margin-bottom: 60px;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(lg) {
    --size-link-asset: 54px;
		margin-bottom: 120px;
	}

	h2 {
		margin-bottom: 20px;
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
		}

		strong {
			color: $pink;
		}
	}

  &__list {
    &--with-asset {
      .faq-section__panel {
        padding-left: calc(var(--size-link-asset) + 12px);
      }
    }
  }

	&__top {
		color: $blue;
		@include media-breakpoint-up(lg) {
			color: $pink;
		}
	}

	&__holder {
		margin-bottom: 20px;
		padding: 25px;
		width: 100%;
		border-radius: 8px;
		background-color: $gray-300;
		@include media-breakpoint-up(md) {
			padding: 45px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
			padding: 85px;
			border-radius: 16px;
		}

		h3 {
			margin-bottom: 25px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 50px;
			}
		}
	}

	&__item {
		margin-bottom: 18px;
		padding-bottom: 18px;
		border-bottom: 1px solid $light;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}

		&.is-active {
			.faq-section__link {
				i {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__link {
		padding: 10px 35px 10px 0;
		position: relative;
		display: block;
		font-weight: 600;
		color: $blue;

		@include media-breakpoint-up(lg) {
			padding: 0px 30px 0px 0;
		}

    &:not(.js-faq-link) {
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;

      &__asset {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--size-link-asset);
        height: var(--size-link-asset);
        margin-right: 12px;
        border-radius: 50%;
        background-color: $light;

        img {
          max: {
            width: 60%;
            height: 60%;
          }
        }
      }
    }

		i {
			position: absolute;
			top: 13px;
			right: 0;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			font-size: 8px;
			transition: all 0.3s;
			@include media-breakpoint-up(lg) {
				margin-top: -15px;
				top: 50%;
				width: 30px;
				height: 30px;
				font-size: 10px;
			}
		}

		&.js-faq-link {
      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
	}

	&__panel {
		display: none;
		@include media-breakpoint-up(xl) {
			padding-right: 100px;
		}

		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}

	&--inner {
		padding-top: 20px;
		@include media-breakpoint-up(xl) {
			padding-top: 80px;
		}

		.faq-section__holder {
			@include media-breakpoint-up(xl) {
				padding: 30px 60px;
			}
		}

		&:after {
			display: none;
		}
	}
}

}
.pageblock_14325 {
.cta-banner {
	margin: 0 auto 56px;
	position: relative;
	width: 100%;
	max-width: 1920px;
	border-radius: 16px;
	background: $blue;
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 640px;
		border-radius: 24px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__content {
		margin: -20px 0 0;
		padding: 40px 20px;
		position: relative;
		color: $white;
		border-radius: 0 12px 12px 0;
		background: $blue;
		@include media-breakpoint-up(lg) {
			margin: 0;
			padding: 0 20px 0 0;
			width: 50%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(xl) {
			width: 40%;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 50px;
			width: 36%;
		}
		@include media-breakpoint-up(xxxl) {
			padding-right: 0;
			padding-left: 90px;
			width: 34%;
		}

		h6 {
			margin-bottom: 16px;
			color: $cyan;
		}

		h2 {
			color: $white;
			@include media-breakpoint-up(lg) {
				margin-bottom: 32px;
			}
		}

		p {
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				line-height: 26px;
			}
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 240px;
		@include media-breakpoint-up(sm) {
			height: auto;
		}
		@include media-breakpoint-up(lg) {
			width: 50%;
			height: 640px;
			border-radius: 24px 0 0 24px;
		}
		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		&:after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				right: -185px;
				width: 370px;
				height: 100%;
				content: '';
				@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 24px 0 0 24px;
			}
		}
	}
}

}
.pageblock_14326 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14327 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14328 {
.specialists-form {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(xl) {
		margin-bottom: 120px;
	}

	&__holder {
		margin: 0 -20px;
		position: relative;
		z-index: 2;
		width: calc(100% + 40px);
		border-radius: 16px;
		background-color: $blue;
		overflow: hidden;
		@include background-image(specialist-bg, svg, center center, no-repeat, cover);
		@include media-breakpoint-up(sm) {
			margin: 0;
			width: 100%;
		}
		@include media-breakpoint-up(xl) {
			border-radius: 18px;
			padding: {
				top: 90px;
				bottom: 90px;
			}
		}
		padding: {
			top: 50px;
			right: 20px;
			bottom: 50px;
			left: 20px;
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: -390px;
			z-index: -1;
			width: 776px;
			height: 1522px;
			transform: scaleX(-1);
			content: '';
			@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
		}
	}

	&__top {
		margin-bottom: 10px;
		width: 100%;
		font-size: 16px;
		text-align: center;
		color: rgba($white, 0.4);
		@include media-breakpoint-up(xl) {
			margin-bottom: 20px;
			font-size: 20px;
		}

		h2 {
			margin-bottom: 25px;
			color: $white;
			@include media-breakpoint-down(lg) {
				font-size: 24px;
				line-height: 32px;
				letter-spacing: -0.75px;
			}
		}
	}

	&__slider {
		position: relative;
		width: 100%;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 80px;
		border-radius: 8px;
		background: $pink;
	}

	&__slide {
		margin: 0 8px;
		width: 160px;
	}

	&__form {
		margin-bottom: 30px;
		padding-right: 20px;
		width: 100%;
		@include media-breakpoint-up(xl) {
			margin-bottom: 60px;
		}
	}
}

.cta-banner {
	margin: 0 auto 56px;
	position: relative;
	width: 100%;
	max-width: 1920px;
	border-radius: 16px;
	background: $blue;
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 640px;
		border-radius: 24px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__content {
		margin: -20px 0 0;
		padding: 40px 20px;
		position: relative;
		color: $white;
		border-radius: 0 12px 12px 0;
		background: $blue;
		@include media-breakpoint-up(lg) {
			margin: 0;
			padding: 0 20px 0 0;
			width: 50%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(xl) {
			width: 40%;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 50px;
			width: 36%;
		}
		@include media-breakpoint-up(xxxl) {
			padding-right: 0;
			padding-left: 90px;
			width: 34%;
		}

		h6 {
			margin-bottom: 16px;
			color: $cyan;
		}

		h2 {
			color: $white;
			@include media-breakpoint-up(lg) {
				margin-bottom: 32px;
			}
		}

		p {
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				line-height: 26px;
			}
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 240px;
		@include media-breakpoint-up(sm) {
			height: auto;
		}
		@include media-breakpoint-up(lg) {
			width: 50%;
			height: 640px;
			border-radius: 24px 0 0 24px;
		}
		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		&:after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				right: -185px;
				width: 370px;
				height: 100%;
				content: '';
				@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 24px 0 0 24px;
			}
		}
	}
}

}
.pageblock_14331 {
.faq-section {
  --size-link-asset: 32px;
	margin-bottom: 60px;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(lg) {
    --size-link-asset: 54px;
		margin-bottom: 120px;
	}

	h2 {
		margin-bottom: 20px;
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
		}

		strong {
			color: $pink;
		}
	}

  &__list {
    &--with-asset {
      .faq-section__panel {
        padding-left: calc(var(--size-link-asset) + 12px);
      }
    }
  }

	&__top {
		color: $blue;
		@include media-breakpoint-up(lg) {
			color: $pink;
		}
	}

	&__holder {
		margin-bottom: 20px;
		padding: 25px;
		width: 100%;
		border-radius: 8px;
		background-color: $gray-300;
		@include media-breakpoint-up(md) {
			padding: 45px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
			padding: 85px;
			border-radius: 16px;
		}

		h3 {
			margin-bottom: 25px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 50px;
			}
		}
	}

	&__item {
		margin-bottom: 18px;
		padding-bottom: 18px;
		border-bottom: 1px solid $light;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}

		&.is-active {
			.faq-section__link {
				i {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__link {
		padding: 10px 35px 10px 0;
		position: relative;
		display: block;
		font-weight: 600;
		color: $blue;

		@include media-breakpoint-up(lg) {
			padding: 0px 30px 0px 0;
		}

    &:not(.js-faq-link) {
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;

      &__asset {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--size-link-asset);
        height: var(--size-link-asset);
        margin-right: 12px;
        border-radius: 50%;
        background-color: $light;

        img {
          max: {
            width: 60%;
            height: 60%;
          }
        }
      }
    }

		i {
			position: absolute;
			top: 13px;
			right: 0;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			font-size: 8px;
			transition: all 0.3s;
			@include media-breakpoint-up(lg) {
				margin-top: -15px;
				top: 50%;
				width: 30px;
				height: 30px;
				font-size: 10px;
			}
		}

		&.js-faq-link {
      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
	}

	&__panel {
		display: none;
		@include media-breakpoint-up(xl) {
			padding-right: 100px;
		}

		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}

	&--inner {
		padding-top: 20px;
		@include media-breakpoint-up(xl) {
			padding-top: 80px;
		}

		.faq-section__holder {
			@include media-breakpoint-up(xl) {
				padding: 30px 60px;
			}
		}

		&:after {
			display: none;
		}
	}
}

}
.pageblock_14332 {
.card-house {
  a:hover {
    text-decoration: none;
  }
}

.card-house__image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
}
.pageblock_14340 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14342 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14645 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14345 {
@import '~assets/styles/variables';

$region-colors: (
  'arnhem': #00B3A8,
  'drenthe': #0056A8,
  'eemland': #001F52,
  'flevoland': #D94500,
  'friesland': #4C0157,
  'groene-hart': #6D5A1D,
  'groningen': #08B02A,
  'haaglanden': #CB0004,
  'haarlem': #003E37,
  'het-gooi': #7D838A,
  'leiden': #005317,
  'limburg': #363D4A,
  'amsterdam': #FFAE47,
  'nijmegen': #90F700,
  'noord-holland': #FEE47A,
  'noordoost-brabant': #94F700,
  'veluwe': #BD8494,
  'rotterdam': #FFE349,
  'twente': #AF647A,
  'utrecht': #2D54BF,
  'wageningen': #2A92C9,
  'west-brabant': #23408E,
  'stedendriehoek': #A0B6F3,
  'zaanstreek': #FE819C,
  'zeeland': #5E646E,
  'zuidoost-brabant': #AEA094,
  'zwolle': #FFBD76,
);

.content {
  &.content--region-map {
    margin-bottom: 60px;
    .region-map {
      &__map {
        display: flex;
        justify-content: center;
        max-height:90vh;

        &__tooltip {
          display:none;
          position:fixed;
          left:0;
          top:0;
          background-color: #FFF;
          padding: 0 12px;
          height: 48px;
          border-radius: 8px;
          border: 1px solid #161549;
          align-content: center;
        }
      }

      @include media-breakpoint-down(sm) {
        display: none;
      }

      svg {
        path {
          fill-opacity: 0.01;
          transition: all 0.35s;
          fill: #FFF;
          stroke: none;
          cursor: pointer;
          &:hover,
          &.active {
            fill:white;
            fill-opacity:0.3;
          }
        }
      }
    }

    .region-list {
      &__body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        //@media (hover: hover) and (pointer: fine) {
          .region-item {
            transition: {
              property: opacity;
              duration: 0.15s;
              timing-function: linear;
            }
          }

          &:hover,
          &.active {
            .region-item {
              opacity: .5;
            }
          }
       // }
      }

      .region-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: {
          top: 2px;
          bottom: 2px;
        }
        text-decoration: none;

        &__name {
          padding-right: 12px;
          font: {
            weight: 600;
            size: 18px;
          }
          letter-spacing: -.5px;
          color: $blue;
        }

        &__color {
          width: 12px;
          height: 16px;
          margin-right: 14px;
          clip-path: polygon(100% 50%, 0 0, 0 100%);
        }

        .btn {
          margin-left: auto;

          &.btn-circle {
            width: 2rem;
            height: 2rem;
            font-size: .625rem;
          }
        }

        @each $region, $color in $region-colors {
          &.region-item--#{$region} {
            .region-item__color {
              $set-color: $blue !default;

              @if $color != null {
                $set-color: $color;
              }

              background-color: $set-color;
            }
          }
        }

        //@media (hover: hover) and (pointer: fine) {
          transition: {
            property: opacity, transform;
            duration: 0.15s, 0.3s;
            timing-function: linear, cubic-bezier(.33, 1, .68, 1);
          }

          &:hover,
          &.active {
            opacity: 1;
            transform: scale(1.02);
          }
        //}
      }
    }
  }
}

.news-banner {
  $self: &;
	margin-bottom: 60px;
	padding-top: 165px;
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin-bottom: 80px;
		padding-top: 125px;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 160px;
	}

	&:after {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
		height: 320px;
		border-radius: 0 0 12px 12px;
		background: $blue;
		content: '';
		@include media-breakpoint-up(lg) {
			border-radius: 0 0 15px 15px;
		}
	}

	.breadcrumb {
		margin-bottom: 10px;
		padding-top: 25px;
		border-top: 1px solid rgba($white, 0.1);
	}

	&__holder {
		margin: 0 auto;
		padding: 0 20px;
		position: relative;
		max-width: 1840px;
	}

	&__subtitle {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;

		span {
			margin: 4px 0 0 15px;
			padding: 0 8px;
			display: inline-flex;
			align-items: center;
			height: 20px;
			font-size: 10px;
			text-transform: uppercase;
			color: $white;
			border-radius: 4px;
			background: $cyan;
		}
	}

	&__content {
		padding: 0 20px;
		position: absolute;
		bottom: 30px;
		left: 0;
		width: 100%;
		color: $white;

		h6 {
			font-weight: 600;
			color: $white;
			@include media-breakpoint-up(xl) {
				margin-bottom: 20px;
			}
		}

		h1 {
			color: $white;
			@include media-breakpoint-up(xl) {
				margin-bottom: 25px;
			}
		}

		p {
			color: $white;
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 400px;
		overflow: hidden;
		@include media-breakpoint-up(xl) {
			height: 640px;
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: 0;
			opacity: 0.5;
			width: 100%;
			height: 100%;
			border-radius: 24px;
			background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
			content: '';
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			border-radius: 24px;
			object-fit: cover;
		}
	}

	&__button {
		padding: 0 20px;
		position: absolute;
		top: 30px;
		right: 0;
		z-index: 3;
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		@include media-breakpoint-up(xl) {
			top: 50px;
		}
	}

  &__share,
	&__heart {
		margin-left: 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 48px;
		height: 48px;
		font-size: 15px;
		color: $blue;
		border-radius: 50%;
		background: $white;
		transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
	}

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}
}
.pageblock_14350 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14636 {
.news-banner {
  $self: &;
  margin-bottom: 60px;
  padding-top: 165px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 320px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    max-width: 1840px;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    span {
      margin: 4px 0 0 15px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 4px;
      background: $cyan;
    }
  }

  &__content {
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    color: $white;

    h6 {
      font-weight: 600;
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: $white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 640px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__button {
    padding: 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(xl) {
      top: 50px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14637 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14355 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14646 {
.news-banner {
  $self: &;
  margin-bottom: 60px;
  padding-top: 165px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 320px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    max-width: 1840px;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    span {
      margin: 4px 0 0 15px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 4px;
      background: $cyan;
    }
  }

  &__content {
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    color: $white;

    h6 {
      font-weight: 600;
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: $white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 640px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__button {
    padding: 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(xl) {
      top: 50px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14647 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14359 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14360 {
.plans-section {
	position: relative;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
		};
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 150px;
			bottom: 120px;
		}
	}
	margin: {
		top: 60px;
		bottom: 60px;
	}

	h3 {
		strong {
			color: $pink;
		}
	}

	&__list {
		margin: 0 -8px;
		padding: 15px 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: calc(100% + 16px);
		@include media-breakpoint-up(xl) {
			padding-top: 30px;
		}
	}

	&__list-item {
		padding: 0 8px 8px;
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 50%;
		}
		@include media-breakpoint-up(xl) {
			padding-bottom: 16px;
			width: 33.333%;
		}
	}

	&__list-link {
		padding: 30px 60px 30px 50px;
		position: relative;
		display: block;
		width: 100%;
		font-size: 18px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: -0.5px;
		color: $blue;
		border-radius: 8px;
		background: $gray-300;
		transition: all 0.3s;
		@include media-breakpoint-up(xl) {
			padding: 30px 80px;
		}

		&:after {
			margin-top: -24px;
			position: absolute;
			top: 50%;
			right: 15px;
			z-index: 2;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			font-family: $font-family-icon;
			font-size: 14px;
			font-weight: 400;
			color: $blue;
			border-radius: 50%;
			background: $white;
			transition: all 0.3s;
			content: '\e904';
			@include media-breakpoint-up(xl) {
				right: 20px;
			}
		}

		i {
			position: absolute;
			top: 50%;
			left: 20px;
			font-size: 20px;
			transform: translateY(-50%);
			transition: all 0.3s;
			@include media-breakpoint-up(xl) {
				left: 40px;
			}
		}

		&:hover {
			text-decoration: none;
			color: $blue;

			i {
				color: $cyan;
			}

			&:after {
				color: $white;
				background: $cyan;
			}
		}
	}
}

}
.pageblock_14361 {
.cta-banner {
	margin: 0 auto 56px;
	position: relative;
	width: 100%;
	max-width: 1920px;
	border-radius: 16px;
	background: $blue;
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 640px;
		border-radius: 24px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__content {
		margin: -20px 0 0;
		padding: 40px 20px;
		position: relative;
		color: $white;
		border-radius: 0 12px 12px 0;
		background: $blue;
		@include media-breakpoint-up(lg) {
			margin: 0;
			padding: 0 20px 0 0;
			width: 50%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(xl) {
			width: 40%;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 50px;
			width: 36%;
		}
		@include media-breakpoint-up(xxxl) {
			padding-right: 0;
			padding-left: 90px;
			width: 34%;
		}

		h6 {
			margin-bottom: 16px;
			color: $cyan;
		}

		h2 {
			color: $white;
			@include media-breakpoint-up(lg) {
				margin-bottom: 32px;
			}
		}

		p {
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				line-height: 26px;
			}
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 240px;
		@include media-breakpoint-up(sm) {
			height: auto;
		}
		@include media-breakpoint-up(lg) {
			width: 50%;
			height: 640px;
			border-radius: 24px 0 0 24px;
		}
		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		&:after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				right: -185px;
				width: 370px;
				height: 100%;
				content: '';
				@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 24px 0 0 24px;
			}
		}
	}
}

}
.pageblock_14362 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14363 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14364 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14365 {
.specialists-form {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(xl) {
		margin-bottom: 120px;
	}

	&__holder {
		margin: 0 -20px;
		position: relative;
		z-index: 2;
		width: calc(100% + 40px);
		border-radius: 16px;
		background-color: $blue;
		overflow: hidden;
		@include background-image(specialist-bg, svg, center center, no-repeat, cover);
		@include media-breakpoint-up(sm) {
			margin: 0;
			width: 100%;
		}
		@include media-breakpoint-up(xl) {
			border-radius: 18px;
			padding: {
				top: 90px;
				bottom: 90px;
			}
		}
		padding: {
			top: 50px;
			right: 20px;
			bottom: 50px;
			left: 20px;
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: -390px;
			z-index: -1;
			width: 776px;
			height: 1522px;
			transform: scaleX(-1);
			content: '';
			@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
		}
	}

	&__top {
		margin-bottom: 10px;
		width: 100%;
		font-size: 16px;
		text-align: center;
		color: rgba($white, 0.4);
		@include media-breakpoint-up(xl) {
			margin-bottom: 20px;
			font-size: 20px;
		}

		h2 {
			margin-bottom: 25px;
			color: $white;
			@include media-breakpoint-down(lg) {
				font-size: 24px;
				line-height: 32px;
				letter-spacing: -0.75px;
			}
		}
	}

	&__slider {
		position: relative;
		width: 100%;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 80px;
		border-radius: 8px;
		background: $pink;
	}

	&__slide {
		margin: 0 8px;
		width: 160px;
	}

	&__form {
		margin-bottom: 30px;
		padding-right: 20px;
		width: 100%;
		@include media-breakpoint-up(xl) {
			margin-bottom: 60px;
		}
	}
}

.card-search {
	padding-left: 80px;
	position: relative;
	width: 100%;
	border-radius: 10px 0 0 10px;
	background: $white;
	box-shadow: 0 12px 24px rgba($black, 0.12);

  @include media-breakpoint-up(md) {
    padding-left: 100px;
  }

	label {
		padding-left: 18px;
		position: absolute;
		top: 0;
		left: 0;
		display: inline-flex;
		align-items: center;
		height: 60px;
		font-size: $font-size-sm;
		font-weight: 600;
		color: $blue;

    @include media-breakpoint-up(md) {
      padding-left: 28px;
      height: 80px;
    }
	}

	&:after {
		position: absolute;
		top: 0;
		left: 100%;
		width: 0;
		height: 0;
		border-color: transparent transparent transparent $white;
		border-style: solid;
		border-width: 30px 0 30px 24px;
		content: '';

    @include media-breakpoint-up(md) {
      border-width: 40px 0 40px 24px;
    }
	}

	&__search-input {
		padding-right: 70px;
		width: 100%;
		height: 60px;
		font-size: $font-size-sm;
		font-weight: 300;
		color: $teal;
		border: 0;
		background: transparent;

    @include media-breakpoint-up(md) {
      height: 80px;
    }

		&::placeholder {
			color: $teal;
		}
	}

	&__search-btn {
		padding-top: 4px;
		position: absolute;
		top: 6px;
		right: 5px;
		width: 48px;
		height: 48px;
		font-size: $font-size-sm;
		color: $white;
		border: 0;
		border-radius: 50%;
		background: $orange;

    @include media-breakpoint-up(md) {
      top: 16px;
    }
	}

	&--select {
		padding-right: 245px;
	}

	&__select {
		padding-right: 20px;
		position: absolute;
		top: 15px;
		right: 40px;
		width: 210px;
		border-left: 1px solid $light-border;

		select {
			padding: 0 40px 0 15px;
			height: 48px;
			font-size: 16px;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}
	}
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3;

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}
}
.pageblock_14368 {
.faq-section {
  --size-link-asset: 32px;
	margin-bottom: 60px;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(lg) {
    --size-link-asset: 54px;
		margin-bottom: 120px;
	}

	h2 {
		margin-bottom: 20px;
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
		}

		strong {
			color: $pink;
		}
	}

  &__list {
    &--with-asset {
      .faq-section__panel {
        padding-left: calc(var(--size-link-asset) + 12px);
      }
    }
  }

	&__top {
		color: $blue;
		@include media-breakpoint-up(lg) {
			color: $pink;
		}
	}

	&__holder {
		margin-bottom: 20px;
		padding: 25px;
		width: 100%;
		border-radius: 8px;
		background-color: $gray-300;
		@include media-breakpoint-up(md) {
			padding: 45px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
			padding: 85px;
			border-radius: 16px;
		}

		h3 {
			margin-bottom: 25px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 50px;
			}
		}
	}

	&__item {
		margin-bottom: 18px;
		padding-bottom: 18px;
		border-bottom: 1px solid $light;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}

		&.is-active {
			.faq-section__link {
				i {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__link {
		padding: 10px 35px 10px 0;
		position: relative;
		display: block;
		font-weight: 600;
		color: $blue;

		@include media-breakpoint-up(lg) {
			padding: 0px 30px 0px 0;
		}

    &:not(.js-faq-link) {
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;

      &__asset {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--size-link-asset);
        height: var(--size-link-asset);
        margin-right: 12px;
        border-radius: 50%;
        background-color: $light;

        img {
          max: {
            width: 60%;
            height: 60%;
          }
        }
      }
    }

		i {
			position: absolute;
			top: 13px;
			right: 0;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			font-size: 8px;
			transition: all 0.3s;
			@include media-breakpoint-up(lg) {
				margin-top: -15px;
				top: 50%;
				width: 30px;
				height: 30px;
				font-size: 10px;
			}
		}

		&.js-faq-link {
      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
	}

	&__panel {
		display: none;
		@include media-breakpoint-up(xl) {
			padding-right: 100px;
		}

		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}

	&--inner {
		padding-top: 20px;
		@include media-breakpoint-up(xl) {
			padding-top: 80px;
		}

		.faq-section__holder {
			@include media-breakpoint-up(xl) {
				padding: 30px 60px;
			}
		}

		&:after {
			display: none;
		}
	}
}

}
.pageblock_14369 {
.card-house {
  a:hover {
    text-decoration: none;
  }
}

.card-house__image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
}
.pageblock_14371 {
input[name="agree"] {
    visibility: initial;
    margin-left: initial;
    z-index: -1;
}

.contact-info {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__image {
		@include media-breakpoint-up(xl) {
			position: absolute;
			right: 20px;
			bottom: 0;
			width: 130px;
		}
		@include media-breakpoint-up(xxl) {
			right: 40px;
			width: 180px;
		}
		@include media-breakpoint-up(xxxl) {
			right: 80px;
			width: 206px;
		}
	}

	&__content {
		margin-bottom: 15px;
		padding: 20px;
		position: relative;
		border-radius: 16px;
		background: $gray-300;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			padding: 30px;
			min-height: 100%;
		}
		@include media-breakpoint-up(xl) {
			padding-right: 170px;
		}
		@include media-breakpoint-up(xxl) {
			padding: 60px 250px 60px 60px;
		}
		@include media-breakpoint-up(xxxl) {
			padding: 60px 300px 60px 80px;
		}

		h4 {
			strong {
				display: inline-block;
				color: $pink;
			}
		}

		p {
			margin-bottom: 16px;
		}
	}

	&__form {
		padding: 20px;
		border-radius: 16px;
		background-color: $blue;
		@include background-image(contact-info-bg, svg, center right -40px, no-repeat);
		@include media-breakpoint-up(lg) {
			padding: 30px;
			min-height: 100%;
		}
		@include media-breakpoint-up(xxl) {
			padding: 60px;
		}
		@include media-breakpoint-up(xxxl) {
			padding: 60px 120px 60px 80px;
		}

		h4 {
			color: $white;
		}

		p {
			margin-bottom: 16px;
			color: rgba($white, 0.5);
		}

		.form-check {
			.form-check-input {
				&:checked + .form-check-label {
					&:after {
						background-color: $cyan;
					}
				}
			}
		}
	}

	&__bottom {
		margin-bottom: 25px;
		position: relative;
		@include media-breakpoint-up(lg) {
			padding-right: 75px;
		}

		.btn {
			margin-top: 20px;
			@include media-breakpoint-up(lg) {
				margin-top: 0;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}
}

}
.pageblock_14375 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14376 {
.residential {
  padding-bottom: 40px;
  position: relative;
  background-color: #f4eeea;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 1700px;
  }

  @media (min-width: 992px) {
    padding-top: 60px;
    height: 1200px;
  }

  @media (min-width: 1200px) {
    padding-top: 80px;
  }
}

.residential__holder {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
}

.residential__left {
  padding-top: 48px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    padding-right: 20px;
    padding-top: 0;
    padding-bottom: 0;
    width: 35%;
  }

  @media (min-width: 1200px) {
    width: 31%;
  }

  &:after {
    position: absolute;
    top: 0;
    left: -20px;
    width: calc(100% + 40px);
    height: 100%;
    border-radius: 0 0 15px 15px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0%, rgba(232, 219, 209, 0.7) 100%);
    content: "";

    @media (min-width: 992px) {
      display: none;
    }
  }
}

.residential__subtitle {
  margin-bottom: 11px;
  position: relative;
  z-index: 2;
  font-size: 16px;
  font-weight: 700;
  color: #00b0a7;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.residential__title {
  margin-bottom: 48px;
  position: relative;
  z-index: 2;
  font-size: 22px;
  line-height: 1.25;

  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 24px;
  }

  h1, h2 {
    font-size: unset;
    line-height: unset;
  }
}

.residential__item {
  margin-bottom: 13px;

  &.active {
    .residential__heading {
      font-weight: 700;
      color: #161549;

      span {
        &:before {
          opacity: 1;
          visibility: visible;
        }

        &:after {
          background-color: #fff;
        }
      }
    }

    .residential__text {
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.residential__right {
  padding-top: 50px;
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    padding-top: 130px;
    width: 65%;
  }

  @media (min-width: 1200px) {
    width: 69%;
  }
}

.residential__slider {
  position: absolute;
  top: 50px;
  left: 0;
  opacity: 0;
  width: 100%;
  pointer-events: none;

  @media (min-width: 992px) {
    top: 130px;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    position: relative;
    top: 0;
  }
}

.residential__heading {
  padding-bottom: 17px;
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  color: rgba(92, 91, 129, 0.7);
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: rgba(92, 91, 129, 0.7);
  }

  span {
    position: absolute;
    top: -5px;
    left: -55px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    &:before {
      display: block;
      opacity: 0;
      visibility: hidden;
      width: 32px;
      height: 32px;
      border: 1px solid #fff;
      border-radius: 50px;
      background-color: #f26a38;
      transition: all 0.3s;
      content: "";
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      border-radius: 50px;
      background-color: rgba(92, 91, 129, 0.7);
      transform: translate(-50%, -50%);
      transition: all 0.3s;
      content: "";
    }
  }
}

.residential__phone {
  justify-content: space-between;
  margin-left: -20px;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  width: calc(100% + 40px);

  &:after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(92, 91, 129, 0.2);
    transform: translateY(-50%);
    content: "";
  }
}

.residential__dot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  span {
    display: none;
    white-space: nowrap;
    font: {
      weight: 700;
      size: 14px;
    }
    color: #161549;
    position: absolute;
    top: -32px;
    left: 20px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 2;

    &:before {
      display: block;
      opacity: 0;
      visibility: hidden;
      width: 32px;
      height: 32px;
      border: 1px solid #fff;
      border-radius: 50px;
      background-color: #f26a38;
      transition: all 0.3s;
      content: "";
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      border-radius: 50px;
      background-color: rgba(92, 91, 129, 0.7);
      transform: translate(-50%, -50%);
      transition: all 0.3s;
      content: "";
    }
  }

  &.active {
    span {
      display: block;
    }

    a {
      &:before {
        opacity: 1;
        visibility: visible;
      }

      &:after {
        background-color: #fff;
      }
    }
  }
}

.residential__links {
  padding-top: 22px;
  padding-left: 38px;
  display: none;
  border-left: 2px solid rgba(92, 91, 129, 0.2);

  @media (min-width: 992px) {
    display: block;
  }
}

.residential__text {
  padding-bottom: 22px;
  padding-left: 14px;
  display: none;

  ul {
    margin-bottom: 0;
    padding-left: 0;

    li {
      margin-bottom: 6px;
      padding-left: 12px;
      position: relative;

      &:before {
        position: absolute;
        top: 12px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        width: 5px;
        height: 5px;
        border-radius: 10px;
        background-color: #f26a38;
        transition: all 0.3s;
        content: "";
      }

      a, span {
        font-size: 16px;
        color: #5c5b81;
        transition: all 0.3s;
        cursor: pointer;
      }

      &.active:before,
      &:hover:before {
        opacity: 1;
        visibility: visible;
      }

      &.active a,
      &.active span,
      &:hover a,
      &:hover span {
        font-weight: 600;
        text-decoration: none;
        color: #161549;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.residential__wrap {
  display: flex;
  flex-wrap: wrap;
}

.residential__image {
  margin-bottom: 40px;
  display: none;
  width: 100%;
  border-radius: 16px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    display: block;
    width: 34%;
  }

  img {
    width: 100%;
    border-radius: 16px;
  }
}

.residential__content {
  width: 100%;

  @media (min-width: 768px) {
    padding-left: 30px;
    width: 66%;
  }

  @media (min-width: 1200px) {
    padding-left: 74px;
  }

  strong {
    font-weight: 700;
  }

  h6 {
    margin-bottom: 0;
    font-size: 18px;
  }

  p {
    color: #161549;
  }
  
  ul {
    li {
      list-style: disc;
    }   
  }

  .btn.btn-link-dark {
    color: #161549;

    &:hover {
      color: #f26a38;
    }
  }
}

.residential__buttons {
  padding-top: 15px;
}

.residential__slider-nav {
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  //@media (min-width: 768px) {
  //  padding-top: 50px;
  //}

  @media (min-width: 992px) {
    padding-top: 80px;
    padding-right: 20px;
    justify-content: flex-end;
  }

  @media (min-width: 1200px) {
    padding-right: 100px;
  }
}

.residential__dots {
  padding-bottom: 5px;

  .slick-dots {
    margin: 0;
    padding: 0;
    display: flex;

    li {
      margin-right: 4px;

      @media (min-width: 768px) {
        margin-right: 7px;
      }

      &.slick-active button {
        background-color: $orange;
      }

      button {
        width: 20px;
        height: 2px;
        font-size: 0;
        border: 0;
        background-color: rgba(22, 21, 73, .2);

        @media (min-width: 768px) {
          width: 48px;
        }
      }
    }
  }
}

.residential__arrows {
  padding-left: 15px;
  display: flex;

  @media (min-width: 768px) {
    padding-left: 30px;
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    font-size: 14px;
    color: #fff;
    border: 0;
    border-radius: 50%;
    background-color: $cyan;

    &.change_prev,
    &.change_next,
    &.change_disabled {
      color: #000;
      background-color: #fff;
    }
  }

  .change-prev, .change-next {
    display: none;
  }

  &:has(.slick-arrow.slick-next.slick-disabled) {
    .change-next {
      display: block;
    }

    .slick-next {
      display: none !important;
    }
  }

  &:has(.slick-arrow.slick-prev.slick-disabled) {
    .change-prev {
      margin-right: 10px;
      display: block;
    }

    .slick-prev {
      display: none !important;
    }
  }

  .slick-prev,
  .js-prev {
    margin-right: 10px;
  }
}

.residential__angle {
  position: absolute;
  top: 0;
  left: -60px;
  display: none;
  width: 50%;
  height: 100%;
  cursor: pointer;

  @media (min-width: 768px) {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center right;
  }
}

}
.pageblock_14381 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14382 {
.plans-section {
	position: relative;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
		};
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 150px;
			bottom: 120px;
		}
	}
	margin: {
		top: 60px;
		bottom: 60px;
	}

	h3 {
		strong {
			color: $pink;
		}
	}

	&__list {
		margin: 0 -8px;
		padding: 15px 0 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		width: calc(100% + 16px);
		@include media-breakpoint-up(xl) {
			padding-top: 30px;
		}
	}

	&__list-item {
		padding: 0 8px 8px;
		width: 100%;
		@include media-breakpoint-up(md) {
			width: 50%;
		}
		@include media-breakpoint-up(xl) {
			padding-bottom: 16px;
			width: 33.333%;
		}
	}

	&__list-link {
		padding: 30px 60px 30px 50px;
		position: relative;
		display: block;
		width: 100%;
		font-size: 18px;
		font-weight: 600;
		line-height: 26px;
		letter-spacing: -0.5px;
		color: $blue;
		border-radius: 8px;
		background: $gray-300;
		transition: all 0.3s;
		@include media-breakpoint-up(xl) {
			padding: 30px 80px;
		}

		&:after {
			margin-top: -24px;
			position: absolute;
			top: 50%;
			right: 15px;
			z-index: 2;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 48px;
			height: 48px;
			font-family: $font-family-icon;
			font-size: 14px;
			font-weight: 400;
			color: $blue;
			border-radius: 50%;
			background: $white;
			transition: all 0.3s;
			content: '\e904';
			@include media-breakpoint-up(xl) {
				right: 20px;
			}
		}

		i {
			position: absolute;
			top: 50%;
			left: 20px;
			font-size: 20px;
			transform: translateY(-50%);
			transition: all 0.3s;
			@include media-breakpoint-up(xl) {
				left: 40px;
			}
		}

		&:hover {
			text-decoration: none;
			color: $blue;

			i {
				color: $cyan;
			}

			&:after {
				color: $white;
				background: $cyan;
			}
		}
	}
}

}
.pageblock_14383 {
.cta-banner {
	margin: 0 auto 56px;
	position: relative;
	width: 100%;
	max-width: 1920px;
	border-radius: 16px;
	background: $blue;
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 640px;
		border-radius: 24px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__content {
		margin: -20px 0 0;
		padding: 40px 20px;
		position: relative;
		color: $white;
		border-radius: 0 12px 12px 0;
		background: $blue;
		@include media-breakpoint-up(lg) {
			margin: 0;
			padding: 0 20px 0 0;
			width: 50%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(xl) {
			width: 40%;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 50px;
			width: 36%;
		}
		@include media-breakpoint-up(xxxl) {
			padding-right: 0;
			padding-left: 90px;
			width: 34%;
		}

		h6 {
			margin-bottom: 16px;
			color: $cyan;
		}

		h2 {
			color: $white;
			@include media-breakpoint-up(lg) {
				margin-bottom: 32px;
			}
		}

		p {
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				line-height: 26px;
			}
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 240px;
		@include media-breakpoint-up(sm) {
			height: auto;
		}
		@include media-breakpoint-up(lg) {
			width: 50%;
			height: 640px;
			border-radius: 24px 0 0 24px;
		}
		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		&:after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				right: -185px;
				width: 370px;
				height: 100%;
				content: '';
				@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 24px 0 0 24px;
			}
		}
	}
}

}
.pageblock_14384 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14385 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14386 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14387 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14388 {
.specialists-form {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(xl) {
		margin-bottom: 120px;
	}

	&__holder {
		margin: 0 -20px;
		position: relative;
		z-index: 2;
		width: calc(100% + 40px);
		border-radius: 16px;
		background-color: $blue;
		overflow: hidden;
		@include background-image(specialist-bg, svg, center center, no-repeat, cover);
		@include media-breakpoint-up(sm) {
			margin: 0;
			width: 100%;
		}
		@include media-breakpoint-up(xl) {
			border-radius: 18px;
			padding: {
				top: 90px;
				bottom: 90px;
			}
		}
		padding: {
			top: 50px;
			right: 20px;
			bottom: 50px;
			left: 20px;
		}

		&:after {
			position: absolute;
			bottom: 0;
			left: -390px;
			z-index: -1;
			width: 776px;
			height: 1522px;
			transform: scaleX(-1);
			content: '';
			@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
		}
	}

	&__top {
		margin-bottom: 10px;
		width: 100%;
		font-size: 16px;
		text-align: center;
		color: rgba($white, 0.4);
		@include media-breakpoint-up(xl) {
			margin-bottom: 20px;
			font-size: 20px;
		}

		h2 {
			margin-bottom: 25px;
			color: $white;
			@include media-breakpoint-down(lg) {
				font-size: 24px;
				line-height: 32px;
				letter-spacing: -0.75px;
			}
		}
	}

	&__slider {
		position: relative;
		width: 100%;
	}

	&__logo {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 80px;
		border-radius: 8px;
		background: $pink;
	}

	&__slide {
		margin: 0 8px;
		width: 160px;
	}

	&__form {
		margin-bottom: 30px;
		padding-right: 20px;
		width: 100%;
		@include media-breakpoint-up(xl) {
			margin-bottom: 60px;
		}
	}
}

.card-search {
	padding-left: 80px;
	position: relative;
	width: 100%;
	border-radius: 10px 0 0 10px;
	background: $white;
	box-shadow: 0 12px 24px rgba($black, 0.12);

  @include media-breakpoint-up(md) {
    padding-left: 100px;
  }

	label {
		padding-left: 18px;
		position: absolute;
		top: 0;
		left: 0;
		display: inline-flex;
		align-items: center;
		height: 60px;
		font-size: $font-size-sm;
		font-weight: 600;
		color: $blue;

    @include media-breakpoint-up(md) {
      padding-left: 28px;
      height: 80px;
    }
	}

	&:after {
		position: absolute;
		top: 0;
		left: 100%;
		width: 0;
		height: 0;
		border-color: transparent transparent transparent $white;
		border-style: solid;
		border-width: 30px 0 30px 24px;
		content: '';

    @include media-breakpoint-up(md) {
      border-width: 40px 0 40px 24px;
    }
	}

	&__search-input {
		padding-right: 70px;
		width: 100%;
		height: 60px;
		font-size: $font-size-sm;
		font-weight: 300;
		color: $teal;
		border: 0;
		background: transparent;

    @include media-breakpoint-up(md) {
      height: 80px;
    }

		&::placeholder {
			color: $teal;
		}
	}

	&__search-btn {
		padding-top: 4px;
		position: absolute;
		top: 6px;
		right: 5px;
		width: 48px;
		height: 48px;
		font-size: $font-size-sm;
		color: $white;
		border: 0;
		border-radius: 50%;
		background: $orange;

    @include media-breakpoint-up(md) {
      top: 16px;
    }
	}

	&--select {
		padding-right: 245px;
	}

	&__select {
		padding-right: 20px;
		position: absolute;
		top: 15px;
		right: 40px;
		width: 210px;
		border-left: 1px solid $light-border;

		select {
			padding: 0 40px 0 15px;
			height: 48px;
			font-size: 16px;
			border: 0;
			background-color: transparent;
			cursor: pointer;
		}
	}
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3;

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}
}
.pageblock_14391 {
.faq-section {
  --size-link-asset: 32px;
	margin-bottom: 60px;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(lg) {
    --size-link-asset: 54px;
		margin-bottom: 120px;
	}

	h2 {
		margin-bottom: 20px;
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
		}

		strong {
			color: $pink;
		}
	}

  &__list {
    &--with-asset {
      .faq-section__panel {
        padding-left: calc(var(--size-link-asset) + 12px);
      }
    }
  }

	&__top {
		color: $blue;
		@include media-breakpoint-up(lg) {
			color: $pink;
		}
	}

	&__holder {
		margin-bottom: 20px;
		padding: 25px;
		width: 100%;
		border-radius: 8px;
		background-color: $gray-300;
		@include media-breakpoint-up(md) {
			padding: 45px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
			padding: 85px;
			border-radius: 16px;
		}

		h3 {
			margin-bottom: 25px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 50px;
			}
		}
	}

	&__item {
		margin-bottom: 18px;
		padding-bottom: 18px;
		border-bottom: 1px solid $light;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}

		&.is-active {
			.faq-section__link {
				i {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__link {
		padding: 10px 35px 10px 0;
		position: relative;
		display: block;
		font-weight: 600;
		color: $blue;

		@include media-breakpoint-up(lg) {
			padding: 0px 30px 0px 0;
		}

    &:not(.js-faq-link) {
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;

      &__asset {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--size-link-asset);
        height: var(--size-link-asset);
        margin-right: 12px;
        border-radius: 50%;
        background-color: $light;

        img {
          max: {
            width: 60%;
            height: 60%;
          }
        }
      }
    }

		i {
			position: absolute;
			top: 13px;
			right: 0;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			font-size: 8px;
			transition: all 0.3s;
			@include media-breakpoint-up(lg) {
				margin-top: -15px;
				top: 50%;
				width: 30px;
				height: 30px;
				font-size: 10px;
			}
		}

		&.js-faq-link {
      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
	}

	&__panel {
		display: none;
		@include media-breakpoint-up(xl) {
			padding-right: 100px;
		}

		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}

	&--inner {
		padding-top: 20px;
		@include media-breakpoint-up(xl) {
			padding-top: 80px;
		}

		.faq-section__holder {
			@include media-breakpoint-up(xl) {
				padding: 30px 60px;
			}
		}

		&:after {
			display: none;
		}
	}
}

}
.pageblock_14392 {
.card-house {
  a:hover {
    text-decoration: none;
  }
}

.card-house__image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
}
.pageblock_14398 {
@import '~assets/styles/variables';

// Hide step 2 until it is paginated to
.stepper-step-2 {
  display: none;
}

// Heading
.step-heading {
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 160px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 240px;
    padding-bottom: 60px;
  }

  .h2,
  h2 {
    color: $white;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-weight: 600;
    color: $cyan;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% + 100px);
    background-color: $blue;
    content: '';
    @include background-image(step-bg, svg, center top, no-repeat, cover);
    @include media-breakpoint-up(xl) {
      height: calc(100% + 160px);
    }
  }
}

// Section
.step-section {
  margin-bottom: 60px;
  position: relative;
  z-index: 6;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__list {
    margin: 0 -8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 16px);
  }

  &__list-item {
    padding: 0 8px;
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 33.333%;
    }
    @include media-breakpoint-up(xl) {
      width: 25%;
    }
    @include media-breakpoint-up(xxl) {
      width: 20%;
    }
  }
}

// Pagination

.step-pagination {
  margin: 30px 0;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin: 50px 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__list-item {
    margin: 0 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    color: $blue;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      width: 48px;
      color: $teal;
    }

    &:after {
      margin-top: -1px;
      position: absolute;
      top: 50%;
      left: 100%;
      width: 16px;
      height: 2px;
      background: rgba(248, 241, 234, 1);
      content: '';
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      text-decoration: none;
      color: $blue;
      border-radius: 50%;
      background: $gray-300;
      transition: all 0.3s;

      &:hover {
        color: $white;
        background: $cyan;
      }
    }

    &.is-selected {
      a {
        color: $cyan;
        background: $gray-300;
      }
    }

    &.is-active {
      a {
        color: $white;
        background: $cyan;
      }

      &:after {
        background-image: linear-gradient(to right, rgba(0, 176, 167, 1) 0%, rgba(237, 237, 238, 1) 100%);
      }
    }
  }
}

// Card (step)
.card-step {
  margin-bottom: 16px;
  padding-bottom: 35px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
  }

  h6 {
    margin-bottom: 20px;
  }

  &__img {
    margin-bottom: 25px;
    padding: 10px 10px 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    @include background-image(step-block-bg, svg, center top, no-repeat, 100% 100%);
    @include media-breakpoint-up(lg) {
      padding: 0;
      height: 144px;
    }

    img {
      max-height: 100%;
      object-fit: contain;
      height: 80px;
      width: 80px;
    }
  }

  &__icon {
    padding-top: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140px;
  }
}

// Card (expert)
.card-search-expert {
  margin-bottom: 30px;
  padding: 25px;
  width: 100%;
  font-size: $font-size-sm;
  border-radius: 16px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(xl) {
    padding: 60px;
  }

  h4 {
    margin-bottom: 20px;
  }

  .btn {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .form-group {
    width: 50%;

    label {
      @include media-breakpoint-up(lg) {
        padding-top: 21px;
      }
    }

    .form-control {
      @include media-breakpoint-up(lg) {
        height: 64px;
      }
    }

    &:nth-child(1) {
      @include media-breakpoint-up(md) {
        width: 290px;
      }

      .form-control {
        border-radius: 8px 0 0 8px;
      }
    }

    &:nth-child(2) {
      @include media-breakpoint-up(md) {
        width: 210px;
      }

      .form-control {
        margin-left: -1px;
        border-radius: 0 8px 8px 0;
      }
    }
  }
}

}
.pageblock_14406 {
@import '~assets/styles/variables';

.cta-banner {
	margin: 0 auto 56px;
	position: relative;
	width: 100%;
	max-width: 1920px;
	border-radius: 16px;
	background: $blue;
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 640px;
		border-radius: 24px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__content {
		margin: -20px 0 0;
		padding: 40px 20px;
		position: relative;
		color: $white;
		border-radius: 0 12px 12px 0;
		background: $blue;
		@include media-breakpoint-up(lg) {
			margin: 0;
			padding: 0 20px 0 0;
			width: 50%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(xl) {
			width: 40%;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 50px;
			width: 36%;
		}
		@include media-breakpoint-up(xxxl) {
			padding-right: 0;
			padding-left: 90px;
			width: 34%;
		}

		h6 {
			margin-bottom: 16px;
			color: $cyan;
		}

		h2 {
			color: $white;
			@include media-breakpoint-up(lg) {
				margin-bottom: 32px;
			}
		}

		p {
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				line-height: 26px;
			}
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 240px;
		@include media-breakpoint-up(sm) {
			height: auto;
		}
		@include media-breakpoint-up(lg) {
			width: 50%;
			height: 640px;
			border-radius: 24px 0 0 24px;
		}
		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		&:after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				right: -185px;
				width: 370px;
				height: 100%;
				content: '';
				@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 24px 0 0 24px;
			}
		}
	}
}

}
.pageblock_14416 {
@import '~assets/styles/variables';

// Hide step 2 until it is paginated to
.stepper-step-2 {
  display: none;
}

// Heading
.step-heading {
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 160px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 240px;
    padding-bottom: 60px;
  }

  .h2,
  h2 {
    color: $white;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-weight: 600;
    color: $cyan;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: calc(100% + 100px);
    background-color: $blue;
    content: '';
    @include background-image(step-bg, svg, center top, no-repeat, cover);
    @include media-breakpoint-up(xl) {
      height: calc(100% + 160px);
    }
  }
}

// Section
.step-section {
  margin-bottom: 60px;
  position: relative;
  z-index: 6;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__list {
    margin: 0 -8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 16px);
  }

  &__list-item {
    padding: 0 8px;
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 33.333%;
    }
    @include media-breakpoint-up(xl) {
      width: 25%;
    }
    @include media-breakpoint-up(xxl) {
      width: 20%;
    }
  }
}

// Pagination

.step-pagination {
  margin: 30px 0;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin: 50px 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__list-item {
    margin: 0 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    color: $blue;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      width: 48px;
      color: $teal;
    }

    &:after {
      margin-top: -1px;
      position: absolute;
      top: 50%;
      left: 100%;
      width: 16px;
      height: 2px;
      background: rgba(248, 241, 234, 1);
      content: '';
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      text-decoration: none;
      color: $blue;
      border-radius: 50%;
      background: $gray-300;
      transition: all 0.3s;

      &:hover {
        color: $white;
        background: $cyan;
      }
    }

    &.is-selected {
      a {
        color: $cyan;
        background: $gray-300;
      }
    }

    &.is-active {
      a {
        color: $white;
        background: $cyan;
      }

      &:after {
        background-image: linear-gradient(to right, rgba(0, 176, 167, 1) 0%, rgba(237, 237, 238, 1) 100%);
      }
    }
  }
}

// Card (step)
.card-step {
  margin-bottom: 16px;
  padding-bottom: 35px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
  }

  h6 {
    margin-bottom: 20px;
  }

  &__img {
    margin-bottom: 25px;
    padding: 10px 10px 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    @include background-image(step-block-bg, svg, center top, no-repeat, 100% 100%);
    @include media-breakpoint-up(lg) {
      padding: 0;
      height: 144px;
    }

    img {
      max-height: 100%;
      object-fit: contain;
      height: 80px;
      width: 80px;
    }
  }

  &__icon {
    padding-top: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140px;
  }
}

// Card (expert)
.card-search-expert {
  margin-bottom: 30px;
  padding: 25px;
  width: 100%;
  font-size: $font-size-sm;
  border-radius: 16px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(xl) {
    padding: 60px;
  }

  h4 {
    margin-bottom: 20px;
  }

  .btn {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .form-group {
    width: 50%;

    label {
      @include media-breakpoint-up(lg) {
        padding-top: 21px;
      }
    }

    .form-control {
      @include media-breakpoint-up(lg) {
        height: 64px;
      }
    }

    &:nth-child(1) {
      @include media-breakpoint-up(md) {
        width: 290px;
      }

      .form-control {
        border-radius: 8px 0 0 8px;
      }
    }

    &:nth-child(2) {
      @include media-breakpoint-up(md) {
        width: 210px;
      }

      .form-control {
        margin-left: -1px;
        border-radius: 0 8px 8px 0;
      }
    }
  }
}

}
.pageblock_14424 {
@import '~assets/styles/variables';

.cta-banner {
	margin: 0 auto 56px;
	position: relative;
	width: 100%;
	max-width: 1920px;
	border-radius: 16px;
	background: $blue;
	@include media-breakpoint-up(lg) {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		min-height: 640px;
		border-radius: 24px;
	}
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__content {
		margin: -20px 0 0;
		padding: 40px 20px;
		position: relative;
		color: $white;
		border-radius: 0 12px 12px 0;
		background: $blue;
		@include media-breakpoint-up(lg) {
			margin: 0;
			padding: 0 20px 0 0;
			width: 50%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(xl) {
			width: 40%;
		}
		@include media-breakpoint-up(xxl) {
			padding-left: 50px;
			width: 36%;
		}
		@include media-breakpoint-up(xxxl) {
			padding-right: 0;
			padding-left: 90px;
			width: 34%;
		}

		h6 {
			margin-bottom: 16px;
			color: $cyan;
		}

		h2 {
			color: $white;
			@include media-breakpoint-up(lg) {
				margin-bottom: 32px;
			}
		}

		p {
			margin-bottom: 40px;
			@include media-breakpoint-down(md) {
				line-height: 26px;
			}
		}
	}

	&__image {
		position: relative;
		width: 100%;
		height: 240px;
		@include media-breakpoint-up(sm) {
			height: auto;
		}
		@include media-breakpoint-up(lg) {
			width: 50%;
			height: 640px;
			border-radius: 24px 0 0 24px;
		}
		@include media-breakpoint-up(xl) {
			width: 60%;
		}

		&:after {
			@include media-breakpoint-up(lg) {
				position: absolute;
				top: 0;
				right: -185px;
				width: 370px;
				height: 100%;
				content: '';
				@include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
			}
		}

		picture,
		img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			@include media-breakpoint-up(lg) {
				border-radius: 24px 0 0 24px;
			}
		}
	}
}

}
.pageblock_15149 {
@import 'leaflet/dist/leaflet.css';

.expert-map-section {
  position: relative;
  width: 100%;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 168px;
    border-radius: 0 0 8px 8px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(xl) {
      height: 161px;
      border-radius: 0 0 24px 24px;
    }
  }

  .expert-map-section__map-wrapper {
    position: relative;

    .js-mobile-map-filters {
      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 32px;
        right: 32px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $cyan;
        z-index: 999;

        i {
          color: $white;
        }

      }
    }
  }

  &__map {
    position: relative;
    display: block;
    height: 70vh;
    border-radius: 16px;

    @include media-breakpoint-up(lg) {
      height: 100vh;
      min-height: 600px;
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .expert-list-wrapper {
    position: absolute;
    bottom: 16px;
    z-index: 400;
    background-color: white;
    right: 0;
    left: 0;
    width: calc(100% - 32px);
    margin: 0 auto;
    border-radius: 5px;
  }

  &__holder:not(.expert-list-wrapper) {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 8px 8px rgba($black, 0.08);
    margin: 0 auto;
    transition: .3s ease;
    z-index: 400;
    background-color: transparent;

    @include media-breakpoint-down(md) {
      opacity: 0;
      z-index: -1;
    }

    &.is-open {
      opacity: 1;
      z-index: 9999999;
    }

    .expert-map-section__overlay {
      @include media-breakpoint-down(md) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.3);
      }
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 210px;
      right: 50px;
      bottom: auto;
      left: auto;
      background-color: $white;
      width: 528px;
      margin: 0;
      height: calc(100vh - 260px);
      transform: none;
    }
  }

  &__top-block {
    width: 100%;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

    .col-md-5 {
      @include media-breakpoint-down(md) {
        padding: 0 !important;
      }
    }

    .form-group {
      @include media-breakpoint-down(md) {
        margin-bottom: 8px !important;
        width: 100% !important;
      }

      &:nth-child(1) {
        @include media-breakpoint-up(md) {
          width: calc(100% - 300px);
        }

        input {
          border-radius: 5px 0 0 5px;
        }
      }

      button {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        width: 128px;

        .form-control {
          margin-left: -1px;
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }

  &__top {
    padding: 30px 40px 20px;
    width: 100%;
    min-height: 168px;
    border-radius: 8px;
    background: $white;
    box-shadow: 0 8px 8px rgba($black, 0.1);
    z-index: 3;
    position: relative;

    @include media-breakpoint-down(md) {
      margin: 0 auto;
      width: calc(100% - 40px);
    }

    .js-mobile-filters-close {
      position: absolute;
      top: 8px;
      right: 12px;

      i {
        font-size: 14px;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    h5,
    .h5 {
      padding-right: 100px;
      position: relative;

      a {
        position: absolute;
        top: 4px;
        right: 50px;
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        color: $blue;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__bottom {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      height: calc(100% - 168px);
      padding: 15px 24px;
      overflow: auto;
    }
  }

  &__block {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s;
    @include media-breakpoint-up(lg) {
      border-bottom: 1px solid $gray-500;
    }

    &:hover, &.is-active {
      text-decoration: none;
      border-radius: 8px;

      @include media-breakpoint-up(lg) {
        background: $gray-300;
      }
    }
  }

  &__block-img {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 66px;
    border: 1px solid $gray-500;
    border-radius: 8px;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 10px;
      width: 104px;
    }
  }

  &__block-text {
    padding-left: 20px;
    width: calc(100% - 66px);
    @include media-breakpoint-up(lg) {
      padding-left: 30px;
      width: calc(100% - 104px);
    }

    h6 {
      margin-bottom: 0;
      font-size: 16px;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }
    }

    address {
      margin-bottom: 4px;
      padding-left: 20px;
      position: relative;
      display: block;
      font-size: 14px;
      font-weight: 300;
      color: $blue;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        padding-left: 25px;
        font-size: 16px;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        font-family: $font-family-icon;
        color: $teal;
        content: '\e924';
      }
    }
  }
}

.house-heading {
  margin-bottom: 40px;
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;
  background-color: $blue;
  z-index: 4;
  @include background-image(house-heading-bg, svg, center top, no-repeat, cover);
  @include media-breakpoint-up(lg) {
    margin-bottom: 24px;
    padding-top: 160px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    &:not(.house-heading__thin) {
      padding-top: 200px;
    }
  }

  &__thin {
    height: 140px;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__search {
    margin: 0 auto;
    padding-right: 25px;
    max-width: 802px;
  }
}

}
.pageblock_14441 {
.card-house__image {
  img {
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}
}
.pageblock_14442 {
.contact-info {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
	}

	&__image {
		@include media-breakpoint-up(xl) {
			position: absolute;
			right: 20px;
			bottom: 0;
			width: 130px;
		}
		@include media-breakpoint-up(xxl) {
			right: 40px;
			width: 180px;
		}
		@include media-breakpoint-up(xxxl) {
			right: 80px;
			width: 206px;
		}
	}

	&__content {
		margin-bottom: 15px;
		padding: 20px;
		position: relative;
		border-radius: 16px;
		background: $gray-300;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			padding: 30px;
			min-height: 100%;
		}
		@include media-breakpoint-up(xl) {
			padding-right: 170px;
		}
		@include media-breakpoint-up(xxl) {
			padding: 60px 250px 60px 60px;
		}
		@include media-breakpoint-up(xxxl) {
			padding: 60px 300px 60px 80px;
		}

		h4 {
			strong {
				display: inline-block;
				color: $pink;
			}
		}

		p {
			margin-bottom: 16px;
		}
	}

	&__form {
		padding: 20px;
		border-radius: 16px;
		background-color: $blue;
		@include background-image(contact-info-bg, svg, center right -40px, no-repeat);
		@include media-breakpoint-up(lg) {
			padding: 30px;
			min-height: 100%;
		}
		@include media-breakpoint-up(xxl) {
			padding: 60px;
		}
		@include media-breakpoint-up(xxxl) {
			padding: 60px 120px 60px 80px;
		}

		h4 {
			color: $white;
		}

		p {
			margin-bottom: 16px;
			color: rgba($white, 0.5);
		}

		.form-check {
			.form-check-input {
				&:checked + .form-check-label {
					&:after {
						background-color: $cyan;
					}
				}
			}
		}
	}

	&__bottom {
		margin-bottom: 25px;
		position: relative;
		@include media-breakpoint-up(lg) {
			padding-right: 75px;
		}

		.btn {
			margin-top: 20px;
			@include media-breakpoint-up(lg) {
				margin-top: 0;
				position: absolute;
				top: 0;
				right: 0;
			}
		}
	}
}

}
.pageblock_14456 {
.contact-block {
	margin-bottom: 40px;
	position: relative;
	width: 100%;
	@include media-breakpoint-up(xl) {
		margin-bottom: 80px;
		padding-top: 45px;
	}

	h4 {
		margin-bottom: 20px;
		@include media-breakpoint-up(xl) {
			margin-bottom: 30px;
		}
	}

	&__content {
		@include media-breakpoint-up(xl) {
			padding-right: 80px;
		}

    b, strong {
      font-weight: 600;
    }
	}

	&__block {
		padding: 25px;
		border-radius: 8px;
		background: $gray-300;
	}

	&__info {
		margin-bottom: 20px;
		padding-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		border-bottom: 1px solid $light-border;
		@include media-breakpoint-up(xl) {
			margin-bottom: 15px;
			padding-bottom: 15px;
		}

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}
	}

	&__info-text {
		padding-left: 15px;
		width: calc(100% - 48px);
		font-size: 12px;
		color: $blue;
		@include media-breakpoint-up(xl) {
			font-size: 14px;
		}

		p {
			margin-bottom: 5px;
			font-size: 12px;
			@include media-breakpoint-up(xl) {
				font-size: 16px;
			}

			strong {
				font-weight: 600;
			}

			a {
				color: $blue;
			}
		}

		span {
			display: block;
			color: $teal;
		}
	}

	&__info-icon {
		display: flex;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 40px;
		height: 40px;
		font-size: 11px;
		color: $primary;
		border-radius: 50%;
		background: $white;
		box-shadow: 0 4px 4px rgba($black, 0.08);
		@include media-breakpoint-up(xl) {
			width: 48px;
			height: 48px;
			font-size: 16px;
		}

		&a:hover {
			text-decoration: none;
		}
	}
}

}
.pageblock_14460 {
#appleid-signin {
  width: 290px;
  height: 40px;
}
}
.pageblock_14473 {
.not-found {
	margin-top: 160px;
	margin-bottom: 40px;
	padding-top: 45px;
	padding-bottom: 40px;
	position: relative;
	width: 100%;
	text-align: center;
	color: rgba($white, 0.6);
	border-radius: 16px;
	background-color: $blue;
	@include background-image(not-found-bg2, svg, left top, no-repeat, cover);
	@include media-breakpoint-up(lg) {
		margin-top: 0;
		margin-bottom: 120px;
		padding-top: 220px;
		padding-bottom: 80px;
		border-radius: 0 0 15px 15px;
		background-position: center top;
	}
	@include media-breakpoint-up(xl) {
		padding-top: 280px;
		padding-bottom: 135px;
	}

	h1 {
		margin-bottom: 20px;
		color: $white;
		@include media-breakpoint-up(xl) {
			margin-bottom: 45px;
		}
	}

	p {
		margin-bottom: 30px;
		@include media-breakpoint-up(xl) {
			margin-bottom: 60px;
		}
	}

	&__image {
		margin: 0 auto 30px;
		padding-right: 13px;
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: center;
		width: 88px;
		height: 96px;
		@include background-image(not-found-bg, svg, center center, no-repeat, 100% 100%);
		@include media-breakpoint-up(lg) {
			width: 120px;
			height: 131px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 70px;
		}

		img {
			width: 40px;
			@include media-breakpoint-up(lg) {
				width: 50px;
			}
		}
	}
}
}
.pageblock_14476 {
.event-img {
    background-position: center;
    height: 15em;
}

}
.pageblock_14479 {
.landing-banner {
  margin-bottom: 30px;
  padding-top: 160px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 124px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 50px;
    padding-top: 160px;
  }

  &__holder {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      min-height: 640px;
      border-radius: 0 0 16px 16px;
      background: $blue;
    }
  }

  &__content {
    padding: 50px 20px;
    position: relative;
    z-index: 4;
    color: $white;
    background: $blue;
    @include media-breakpoint-up(lg) {
      padding: 0 0 0 20px;
      z-index: 6;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 280px;
      width: 40%;
    }

    &:after {
      position: absolute;
      top: -180px;
      left: -185px;
      z-index: -1;
      width: 370px;
      height: 700px;
      transform: scaleX(-1);
      content: '';
      @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    p,
    h1 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }
  }

  &__image {
    margin-top: -10px;
    position: relative;
    z-index: 5;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
      overflow: visible;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      content: '';
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: -185px;
        width: 370px;
        height: 100%;
        transform: scaleX(-1);
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(lg) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__search {
    margin: -28px 0 0 20px;
    padding: 20px;
    position: relative;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    border-radius: 8px;
    background: $white;
    overflow: hidden;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 20px;
      bottom: -40px;
      width: 400px;
    }
    @include media-breakpoint-up(lg) {
      margin-right: -480px;
      right: 50%;
    }
    @include media-breakpoint-up(xl) {
      margin-right: -580px;
      padding: 45px;
      width: 530px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: -680px;
    }

    p {
      margin-bottom: 8px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }
    }
  }

  &__search-form {
    position: relative;
    width: 100%;

    input {
      padding: 0 60px 0 20px;
      width: 100%;
      height: 56px;
      font-size: 16px;
      font-weight: 300;
      border: 1px solid $border-color;
      border-radius: 4px;
    }

    button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 40px;
      height: 40px;
      font-size: 12px;
      color: $white;
      border: 0;
      border-radius: 50%;
      background: $cyan;
    }
  }

  &__block {
    margin: -50px 0 0 20px;
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    color: $white;
    border-radius: 10px 0 0 10px;
    background: $blue;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 370px;
    }
    @include media-breakpoint-up(xl) {
      width: 480px;
    }
  }

  &__block-img {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 112px;
    @include background-image(hero-block-bg, svg, center right, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      width: 160px;
      height: 152px;
    }

    img {
      max-height: 70px;
      @include media-breakpoint-up(xl) {
        max-height: 92px;
      }
    }
  }

  &__block-text {
    padding-left: 20px;
    width: calc(100% - 120px);
    font-size: 12px;
    @include media-breakpoint-up(xl) {
      padding-left: 35px;
      width: calc(100% - 160px);
      font-size: $font-size-sm;
    }

    strong {
      margin-bottom: 5px;
      display: block;
      font-size: $font-size-sm;
      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }

    p {
      margin-bottom: 5px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 15px;
      }
    }

    span {
      display: block;
      color: $cyan;
    }
  }
}

}
.pageblock_14480 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14481 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14482 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14483 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14484 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14489 {
.news-detail {
	margin-bottom: 80px;
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin-bottom: 160px;
	}

	h4 {
		margin-bottom: 20px;
	}

	&__block {
		margin-top: 50px;
		margin-bottom: 50px;
		padding: 25px;
		border-radius: 8px;
		background: $gray-300;
		@include media-breakpoint-up(lg) {
			margin-top: 75px;
			margin-bottom: 75px;
			padding: 25px 35px;
		}

		p {
			font-size: 16px;
			font-weight: 300;
			@include media-breakpoint-up(xl) {
				margin-bottom: 0;
			}
		}
	}

	&__content {
		h6 {
			margin-bottom: 40px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 55px;
			}
		}
	}

	&__space {
		margin-top: 50px;
		margin-bottom: 50px;
		width: 100%;
		@include media-breakpoint-up(lg) {
			margin-top: 75px;
			margin-bottom: 75px;
		}
	}

	&__bottom {
		margin-top: 50px;
		padding-top: 30px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		border-top: 1px solid $gray-500;
		@include media-breakpoint-up(lg) {
			margin-top: 70px;
		}
	}

	&__sidebar {
		position: sticky;
		top: 20px;
	}

	&__social {
		width: 100%;
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
      width: 55%;
		}
		@include media-breakpoint-up(xl) {
			justify-content: flex-end;
		}

		strong {
			margin-right: 22px;
			margin-bottom: 10px;
			width: 100%;
			font-weight: 600;
			color: $blue;
			@include media-breakpoint-up(xl) {
				margin-bottom: 0;
				width: auto;
			}
		}

		ul {
			margin: 0;
			padding: 0;
			display: inline-flex;
			flex-wrap: wrap;

			li {
				margin-left: 8px;

				&:first-child {
					margin-left: 0;
				}

				a {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 48px;
					height: 48px;
					color: $blue;
					border-radius: 50%;
					background: $gray-300;
					transition: all 0.3s;

					&:hover {
						text-decoration: none;
						color: $white;
						background: $blue;
					}
				}
			}
		}
	}

	&__user {
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			width: 45%;
		}
	}

	&__user-img {
		width: 48px;
		height: 48px;
		border-radius: 50%;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
		}
	}

	&__user-text {
		padding-left: 15px;
		width: calc(100% - 48px);

		span {
			display: block;
			font-size: 14px;
			font-weight: 300;
		}

		strong {
			display: block;
			font-size: 16px;
			color: $blue;
		}
	}
}

.privacy {
    h4 {
        font-size:20px;
    }
    table {
        margin-bottom:30px;
        width:100%;
        border:1px solid $secondary;
    }
    
    table th {
        background-color:$secondary;
        color:#FFFFFF;
        text-align:left;
        width:33%;
        vertical-align: top;
        border:1px solid $secondary;
        padding:5px;
    }
    table td {
        width:33%;
        vertical-align: top;
        border:1px solid $secondary;
        padding:5px;
    }
    
}
}
.pageblock_14494 {
.news-detail {
	margin-bottom: 80px;
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin-bottom: 160px;
	}

	h4 {
		margin-bottom: 20px;
	}

	&__block {
		margin-top: 50px;
		margin-bottom: 50px;
		padding: 25px;
		border-radius: 8px;
		background: $gray-300;
		@include media-breakpoint-up(lg) {
			margin-top: 75px;
			margin-bottom: 75px;
			padding: 25px 35px;
		}

		p {
			font-size: 16px;
			font-weight: 300;
			@include media-breakpoint-up(xl) {
				margin-bottom: 0;
			}
		}
	}

	&__content {
		h6 {
			margin-bottom: 40px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 55px;
			}
		}
	}

	&__space {
		margin-top: 50px;
		margin-bottom: 50px;
		width: 100%;
		@include media-breakpoint-up(lg) {
			margin-top: 75px;
			margin-bottom: 75px;
		}
	}

	&__bottom {
		margin-top: 50px;
		padding-top: 30px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		border-top: 1px solid $gray-500;
		@include media-breakpoint-up(lg) {
			margin-top: 70px;
		}
	}

	&__sidebar {
		position: sticky;
		top: 20px;
	}

	&__social {
		width: 100%;
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
      width: 55%;
		}
		@include media-breakpoint-up(xl) {
			justify-content: flex-end;
		}

		strong {
			margin-right: 22px;
			margin-bottom: 10px;
			width: 100%;
			font-weight: 600;
			color: $blue;
			@include media-breakpoint-up(xl) {
				margin-bottom: 0;
				width: auto;
			}
		}

		ul {
			margin: 0;
			padding: 0;
			display: inline-flex;
			flex-wrap: wrap;

			li {
				margin-left: 8px;

				&:first-child {
					margin-left: 0;
				}

				a {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 48px;
					height: 48px;
					color: $blue;
					border-radius: 50%;
					background: $gray-300;
					transition: all 0.3s;

					&:hover {
						text-decoration: none;
						color: $white;
						background: $blue;
					}
				}
			}
		}
	}

	&__user {
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			width: 45%;
		}
	}

	&__user-img {
		width: 48px;
		height: 48px;
		border-radius: 50%;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
		}
	}

	&__user-text {
		padding-left: 15px;
		width: calc(100% - 48px);

		span {
			display: block;
			font-size: 14px;
			font-weight: 300;
		}

		strong {
			display: block;
			font-size: 16px;
			color: $blue;
		}
	}
}

}
.pageblock_14500 {
.news-detail {
	margin-bottom: 80px;
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin-bottom: 160px;
	}

	h4 {
		margin-bottom: 20px;
	}

	&__block {
		margin-top: 50px;
		margin-bottom: 50px;
		padding: 25px;
		border-radius: 8px;
		background: $gray-300;
		@include media-breakpoint-up(lg) {
			margin-top: 75px;
			margin-bottom: 75px;
			padding: 25px 35px;
		}

		p {
			font-size: 16px;
			font-weight: 300;
			@include media-breakpoint-up(xl) {
				margin-bottom: 0;
			}
		}
	}

	&__content {
		h6 {
			margin-bottom: 40px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 55px;
			}
		}
	}

	&__space {
		margin-top: 50px;
		margin-bottom: 50px;
		width: 100%;
		@include media-breakpoint-up(lg) {
			margin-top: 75px;
			margin-bottom: 75px;
		}
	}

	&__bottom {
		margin-top: 50px;
		padding-top: 30px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		border-top: 1px solid $gray-500;
		@include media-breakpoint-up(lg) {
			margin-top: 70px;
		}
	}

	&__sidebar {
		position: sticky;
		top: 20px;
	}

	&__social {
		width: 100%;
		@include media-breakpoint-up(lg) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
      width: 55%;
		}
		@include media-breakpoint-up(xl) {
			justify-content: flex-end;
		}

		strong {
			margin-right: 22px;
			margin-bottom: 10px;
			width: 100%;
			font-weight: 600;
			color: $blue;
			@include media-breakpoint-up(xl) {
				margin-bottom: 0;
				width: auto;
			}
		}

		ul {
			margin: 0;
			padding: 0;
			display: inline-flex;
			flex-wrap: wrap;

			li {
				margin-left: 8px;

				&:first-child {
					margin-left: 0;
				}

				a {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 48px;
					height: 48px;
					color: $blue;
					border-radius: 50%;
					background: $gray-300;
					transition: all 0.3s;

					&:hover {
						text-decoration: none;
						color: $white;
						background: $blue;
					}
				}
			}
		}
	}

	&__user {
		margin-bottom: 20px;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		width: 100%;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
			width: 45%;
		}
	}

	&__user-img {
		width: 48px;
		height: 48px;
		border-radius: 50%;

		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
			object-fit: cover;
		}
	}

	&__user-text {
		padding-left: 15px;
		width: calc(100% - 48px);

		span {
			display: block;
			font-size: 14px;
			font-weight: 300;
		}

		strong {
			display: block;
			font-size: 16px;
			color: $blue;
		}
	}
}

}
.pageblock_14504 {
.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14508 {
.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14512 {
.usp-section {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__item {
    margin-bottom: 15px;
    position: relative;
    width: 100%;
    font-size: 16px;
    border-right: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin: 0 15px 0 0;
      width: calc(33.333% - 15px);
    }
    @include media-breakpoint-up(xxl) {
      margin-right: 30px;
      padding-left: 30px;
      width: calc(33.333% - 30px);
    }
    padding: {
      top: 4px;
      bottom: 4px;
      left: 25px;
    }

    &:last-child {
      margin: 0;
      border-right: 0;
    }

    &:before {
      position: absolute;
      top: 6px;
      left: 0;
      font-family: $font-family-icon;
      font-size: 11px;
      color: $cyan;
      content: '\e909';
    }
  }
}

}
.pageblock_14513 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14514 {
.usp-slider {
  margin-bottom: 56px;
  position: relative;
  width: 100%;
  overflow: hidden;

  &.usp-slider--alt {
    @include media-breakpoint-up(xxl) {
      margin-bottom: 120px;
    }

    .usp-slider__title {
      strong {
        color: $cyan;
      }
    }

    .usp-slider__slide {
      margin-right: 20px;
    }

    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        .card-usp {
          &,
          &__content {
            height: 100%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__slider {
    position: relative;
    width: 100%;
  }

  &__title {
    margin-bottom: 30px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 50px;
    }

    strong {
      color: $pink;
    }
  }

  h3 {
    line-height: 1;
  }

  &__slide {
    margin-right: 30px;
    width: 240px;
    @include media-breakpoint-up(xl) {
      margin-right: 20px;
      width: 300px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: 30px;
      width: 320px;
    }
    @include media-breakpoint-up(xxxl) {
      margin-right: 70px;
      width: 340px;
    }
  }
}

.card-usp {
  position: relative;
  display: block;
  width: 100%;

  &.card-usp--alt {
    .card-usp__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 16px;
      background-color: $gray-300;
      padding: 32px;

      p {
        padding-bottom: 32px;
      }
    }

    .btn {
      margin-top: auto;
    }
  }

  &__image {
    margin-bottom: 15px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102px;
    height: 80px;
    @include background-image(menu-bg, svg, center center, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      margin-bottom: 35px;
      width: 155px;
      height: 120px;
    }

    img {
      max-height: 50px;
      @include media-breakpoint-up(xl) {
        max-height: 76px;
      }
    }
  }

  &__content {
    p {
      margin-bottom: 0;
      padding: 16px 0;
      font-size: $font-size-sm;
    }
  }
}
}
.pageblock_14515 {
.cta-house {
  margin: 0 auto 60px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__holder {
    padding: 20px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }
    @include media-breakpoint-up(xl) {
      padding: 80px 0;
    }
  }

  &__left {
    position: relative;
    z-index: 5;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-right: -20px;
      width: calc(50% + 20px);
    }
    @include media-breakpoint-up(xxl) {
      margin-right: -50px;
      width: calc(50% + 50px);
    }
  }

  &__right {
    position: relative;
    z-index: 1;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-left: -20px;
      padding-left: 50px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
      width: calc(50% + 20px);
      border-radius: 0 16px 16px 0;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 100px;
    }
    @include media-breakpoint-up(xxl) {
      margin-left: -50px;
      padding-right: 100px;
      padding-left: 100px;
      width: calc(50% + 50px);
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(22, 21, 73, 1) 0%, rgba(46, 45, 92, 1) 100%);
      content: '';
      @include media-breakpoint-up(lg) {
        background-image: linear-gradient(to right, rgba(22, 21, 73, 1) 0%, rgba(46, 45, 92, 1) 100%);
        clip-path: polygon(100px 0%, 100% 0, 100% 100%, 100px 100%, 0% 50%);
      }
      @include media-breakpoint-up(xl) {
        clip-path: polygon(155px 0%, 100% 0, 100% 100%, 155px 100%, 0% 50%);
      }
    }
  }

  &__slide {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: 538px;
      border-radius: 16px 0 0 16px;
    }
    @include media-breakpoint-up(xl) {
      height: 538px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        clip-path: polygon(0 0, calc(100% - 100px) 0%, 100% 50%, calc(100% - 100px) 100%, 0 100%);
      }
      @include media-breakpoint-up(xl) {
        clip-path: polygon(0 0, calc(100% - 155px) 0%, 100% 50%, calc(100% - 155px) 100%, 0 100%);
      }
    }
  }

  &__content {
    padding: 20px;
    position: relative;
    color: $white;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 50px;
      background: transparent;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
    }

    h6 {
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 35px;
      }
    }
  }

  &__block {
    padding: 5px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 10px;
      right: auto;
      bottom: 40px;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 500;
        color: $cyan;
      }
    }
  }

  &__next,
  &__prev {
    display: none !important;
    @include media-breakpoint-up(xl) {
      margin-top: -70px;
      position: absolute;
      top: 50%;
      z-index: 3;
      display: flex !important;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 140px;
      font-size: 13px;
      color: $blue;
      background: $white;
      transition: all 0.3s;
      clip-path: polygon(40px 0%, calc(100% - 40px) 0%, 100% 50%, calc(100% - 40px) 100%, 40px 100%, 0% 50%);
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:after {
      width: 0;
      height: 0;
      border-color: transparent transparent transparent $cyan;
      border-style: solid;
      border-width: 8px 0 8px 10px;
      content: '';
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }

  &__prev {
    left: 20px;

    &:after {
      border-color: transparent $cyan transparent transparent;
      border-width: 8px 10px 8px 0;
    }
  }

  &__next {
    right: 20px;
  }
}

}
.pageblock_14516 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14518 {
.customer-reviews {
  overflow: hidden;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto 60px;
  border-radius: 16px;
  background: $blue;

  p {
    margin-bottom: 0;
  }

  .container {
    position: relative;
  }

  .customer-reviews__background {
    width: 100%;
    height: 100%;
    background: radial-gradient(43.82% 76.5% at 53.72% 45.91%, $indigo 0%, $blue 100%);
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
  }

  .customer-reviews__wrapper {
    padding:{
      top: 96px;
      bottom: 96px;
    }
  }

  .customer-reviews__subtitle {
    margin-bottom: 32px;
    font-weight: 700;
    text-align: center;
    color: $cyan;
    position: relative;
  }

  .customer-reviews__slider {
    display: flex;
    justify-content: center;
    position: relative;

    .js-slider {
      width: 890px;
      max-width: 100%;

      @include media-breakpoint-up(md) {
        padding: {
          right: 60px;
          left: 60px;
        }
      }
    }

    .slide {
      .slide__text {
        text-align: center;

        p {
          font: {
            weight: 700;
            size: 1.5rem;
          }
          line-height: 32px;
          letter-spacing: -1.5px;
          color: $white;

          @include media-breakpoint-up(lg) {
            font-size: $h3-font-size;
            line-height: 48px;
          }
        }
      }

      .slide__detail {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        margin-top: 56px;
        font-size: 14px;

        .customer-review-name {
          color: $white;
        }

        .customer-review-date {
          color: rgba($white, 60%);
        }
      }
    }
  }

  .arrows-holder {
    &__right,
    &__left {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &::before {
        opacity: 0.8;
        width: 380%;
        height: 380%;
        border-radius: 2px;
        content: '';
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .arrows-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 140px;
        margin: 0;
        border: unset;
        border-radius: 2px;
        background: $white;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

        i {
          color: $cyan;
        }

        &.js-slide-prev {
          i {
            transform: scaleX(-1);
          }
        }
      }
    }

    &__right {
      right: 0;

      &::before {
        background: linear-gradient(270deg, $indigo 0%, $blue 100%);
        left: -16px;
      }

      @include media-breakpoint-between(lg, xxl) {
        right: 4%;
      }
    }

    &__left {
      left: 0;

      &::before {
        background: linear-gradient(90deg, $indigo 0%, $blue 100%);
        right: -16px;
      }

      @include media-breakpoint-between(lg, xxl) {
        left: 4%;
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin: {
      top: 56px;
      bottom: 0;
    }
    padding: 0;

    li {
      button {
        opacity: .3;
        display: block;
        width: 8px;
        height: 8px;
        border: unset;
        border-radius: 50%;
        padding: 0;
        text-indent: -9999px;
        background: $white;
        cursor: pointer;
      }

      &.slick-active {
        button {
          opacity: 1;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: {
      top: 80px;
      bottom: 80px;
    }
  }
}
}
.pageblock_14670 {
.grid-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.card-grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 32px;
  border: 1px solid rgba($blue, 0.1);
  border-radius: 16px;
  background: $gray-300;
  padding: 40px 32px;

  > * {
    width: 100%;
  }

  .card__header {
    h5 {
      font-weight: 600;
    }

    p {
      margin-bottom: 0;
      color: $indigo;
      font-size: 1.25rem;
    }
  }

  .card__content {
    border-top: 1px solid rgba($blue, 0.1);
    padding-top: 24px;

    ul {
      margin-bottom: 0;
      padding: 0;

      li {
        padding-left: 16px;
        font: {
          weight: 600;
          size: 1rem;
        }
        position: relative;

        &::before {
          font-family: $font-family-icon;
          font-size: 8px;
          color: $cyan;
          content: '\e947';
          position: absolute;
          top: 6px;
          left: 0;
        }

        &:not(:first-of-type) {
          margin-top: 8px;
        }

        &.card-subscription__excluded {
          opacity: .2;
        }
      }
    }
  }

  .card__footer {
    margin-top: auto;
    border-top: 1px solid rgba($blue, 0.1);
    padding-top: 24px;
  }

  &.card-grid--small {
    .card__header {
      p {
        font-size: 1rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    max-width: calc((4 / 12 * 100%) - 16px);

    &.card-grid--small {
      max-width: calc(50% - 16px);
    }
  }

  @include media-breakpoint-up(xl) {
    &.card-grid--small {
      max-width: calc(25% - 16px);
    }
  }
}

.subscriptions {
  margin: {
    top: 40px;
    bottom: 40px;
  }

  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }

  .subscriptions__header {
    margin-bottom: 48px;
    text-align: center;

    h2 {
      margin-bottom: 0;

      strong {
        color: $pink;
      }

      + * {
        margin-top: 24px;
      }
    }

    p {
      margin-bottom: 0;
      color: $indigo;

      strong {
        font-weight: 700;
      }
    }
  }

  .subscriptions__footer {
    display: flex;
    margin-top: 32px;

    .btn {
      margin: 0 auto;
    }
  }
}

}
.pageblock_14671 {
.grid-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.card-grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 32px;
  border: 1px solid rgba($blue, 0.1);
  border-radius: 16px;
  background: $gray-300;
  padding: 40px 32px;

  > * {
    width: 100%;
  }

  .card__header {
    h5 {
      font-weight: 600;
    }

    p {
      margin-bottom: 0;
      color: $indigo;
      font-size: 1.25rem;
    }
  }

  .card__content {
    border-top: 1px solid rgba($blue, 0.1);
    padding-top: 24px;

    ul {
      margin-bottom: 0;
      padding: 0;

      li {
        padding-left: 16px;
        font: {
          weight: 600;
          size: 1rem;
        }
        position: relative;

        &::before {
          font-family: $font-family-icon;
          font-size: 8px;
          color: $cyan;
          content: '\e947';
          position: absolute;
          top: 6px;
          left: 0;
        }

        &:not(:first-of-type) {
          margin-top: 8px;
        }

        &.card-subscription__excluded {
          opacity: .2;
        }
      }
    }
  }

  .card__footer {
    margin-top: auto;
    border-top: 1px solid rgba($blue, 0.1);
    padding-top: 24px;
  }

  &.card-grid--small {
    .card__header {
      p {
        font-size: 1rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    max-width: calc((4 / 12 * 100%) - 16px);

    &.card-grid--small {
      max-width: calc(50% - 16px);
    }
  }

  @include media-breakpoint-up(xl) {
    &.card-grid--small {
      max-width: calc(25% - 16px);
    }
  }
}

.products {
  margin: {
    top: 40px;
    bottom: 40px;
  }

  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }

  .products__header {
    margin-bottom: 48px;
    text-align: center;

    h2 {
      margin-bottom: 0;

      strong {
        color: $pink;
      }

      + * {
        margin-top: 24px;
      }
    }
  }
}

}
.pageblock_14525 {
.landing-banner {
  margin-bottom: 30px;
  padding-top: 160px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 124px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 50px;
    padding-top: 160px;
  }

  &__holder {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      min-height: 640px;
      border-radius: 0 0 16px 16px;
      background: $blue;
    }
  }

  &__content {
    padding: 50px 20px;
    position: relative;
    z-index: 4;
    color: $white;
    background: $blue;
    @include media-breakpoint-up(lg) {
      padding: 0 0 0 20px;
      z-index: 6;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 280px;
      width: 40%;
    }

    &:after {
      position: absolute;
      top: -180px;
      left: -185px;
      z-index: -1;
      width: 370px;
      height: 700px;
      transform: scaleX(-1);
      content: '';
      @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    p,
    h1 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }
  }

  &__image {
    margin-top: -10px;
    position: relative;
    z-index: 5;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
      overflow: visible;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      content: '';
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: -185px;
        width: 370px;
        height: 100%;
        transform: scaleX(-1);
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(lg) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__search {
    margin: -28px 0 0 20px;
    padding: 20px;
    position: relative;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    border-radius: 8px;
    background: $white;
    overflow: hidden;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 20px;
      bottom: -40px;
      width: 400px;
    }
    @include media-breakpoint-up(lg) {
      margin-right: -480px;
      right: 50%;
    }
    @include media-breakpoint-up(xl) {
      margin-right: -580px;
      padding: 45px;
      width: 530px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: -680px;
    }

    p {
      margin-bottom: 8px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }
    }
  }

  &__search-form {
    position: relative;
    width: 100%;

    input {
      padding: 0 60px 0 20px;
      width: 100%;
      height: 56px;
      font-size: 16px;
      font-weight: 300;
      border: 1px solid $border-color;
      border-radius: 4px;
    }

    button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 40px;
      height: 40px;
      font-size: 12px;
      color: $white;
      border: 0;
      border-radius: 50%;
      background: $cyan;
    }
  }

  &__block {
    margin: -50px 0 0 20px;
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    color: $white;
    border-radius: 10px 0 0 10px;
    background: $blue;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 370px;
    }
    @include media-breakpoint-up(xl) {
      width: 480px;
    }
  }

  &__block-img {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 112px;
    @include background-image(hero-block-bg, svg, center right, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      width: 160px;
      height: 152px;
    }

    img {
      max-height: 70px;
      @include media-breakpoint-up(xl) {
        max-height: 92px;
      }
    }
  }

  &__block-text {
    padding-left: 20px;
    width: calc(100% - 120px);
    font-size: 12px;
    @include media-breakpoint-up(xl) {
      padding-left: 35px;
      width: calc(100% - 160px);
      font-size: $font-size-sm;
    }

    strong {
      margin-bottom: 5px;
      display: block;
      font-size: $font-size-sm;
      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }

    p {
      margin-bottom: 5px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 15px;
      }
    }

    span {
      display: block;
      color: $cyan;
    }
  }
}

}
.pageblock_14526 {
.usp-slider {
  margin-bottom: 56px;
  position: relative;
  width: 100%;
  overflow: hidden;

  &.usp-slider--alt {
    .usp-slider__title {
      strong {
        color: $cyan;
      }
    }

    .usp-slider__slide {
      margin-right: 20px;
    }

    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        .card-usp {
          &,
          &__content {
            height: 100%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__slider {
    position: relative;
    width: 100%;
  }

  &__title {
    margin-bottom: 30px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 50px;
    }

    strong {
      color: $pink;
    }
  }

  h3 {
    line-height: 1;
  }

  &__slide {
    margin-right: 30px;
    width: 240px;
    @include media-breakpoint-up(xl) {
      margin-right: 20px;
      width: 300px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: 30px;
      width: 320px;
    }
    @include media-breakpoint-up(xxxl) {
      margin-right: 70px;
      width: 340px;
    }
  }
}

.card-usp {
  position: relative;
  display: block;
  width: 100%;

  &.card-usp--alt {
    .card-usp__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 16px;
      background-color: $gray-300;
      padding: 32px;

      p {
        padding-bottom: 32px;
      }
    }

    .btn {
      margin-top: auto;
    }
  }

  &__image {
    margin-bottom: 15px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102px;
    height: 80px;
    @include background-image(menu-bg, svg, center center, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      margin-bottom: 35px;
      width: 155px;
      height: 120px;
    }

    img {
      max-height: 50px;
      @include media-breakpoint-up(xl) {
        max-height: 76px;
      }
    }
  }

  &__content {
    p {
      margin-bottom: 0;
      padding: 16px 0;
      font-size: $font-size-sm;
    }
  }
}
}
.pageblock_14527 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14528 {
.usp-slider {
  margin-bottom: 56px;
  position: relative;
  width: 100%;
  overflow: hidden;

  &.usp-slider--alt {
    @include media-breakpoint-up(xxl) {
      margin-bottom: 120px;
    }

    .usp-slider__title {
      strong {
        color: $cyan;
      }
    }

    .usp-slider__slide {
      margin-right: 20px;
    }

    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        .card-usp {
          &,
          &__content {
            height: 100%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__slider {
    position: relative;
    width: 100%;
  }

  &__title {
    margin-bottom: 30px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 50px;
    }

    strong {
      color: $pink;
    }
  }

  h3 {
    line-height: 1;
  }

  &__slide {
    margin-right: 30px;
    width: 240px;
    @include media-breakpoint-up(xl) {
      margin-right: 20px;
      width: 300px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: 30px;
      width: 320px;
    }
    @include media-breakpoint-up(xxxl) {
      margin-right: 70px;
      width: 340px;
    }
  }
}

.card-usp {
  position: relative;
  display: block;
  width: 100%;

  &.card-usp--alt {
    .card-usp__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 16px;
      background-color: $gray-300;
      padding: 32px;

      p {
        padding-bottom: 32px;
      }
    }

    .btn {
      margin-top: auto;
    }
  }

  &__image {
    margin-bottom: 15px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102px;
    height: 80px;
    @include background-image(menu-bg, svg, center center, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      margin-bottom: 35px;
      width: 155px;
      height: 120px;
    }

    img {
      max-height: 50px;
      @include media-breakpoint-up(xl) {
        max-height: 76px;
      }
    }
  }

  &__content {
    p {
      margin-bottom: 0;
      padding: 16px 0;
      font-size: $font-size-sm;
    }
  }
}
}
.pageblock_14529 {
.cta-house {
  margin: 0 auto 60px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__holder {
    padding: 20px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }
    @include media-breakpoint-up(xl) {
      padding: 80px 0;
    }
  }

  &__left {
    position: relative;
    z-index: 5;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-right: -20px;
      width: calc(50% + 20px);
    }
    @include media-breakpoint-up(xxl) {
      margin-right: -50px;
      width: calc(50% + 50px);
    }
  }

  &__right {
    position: relative;
    z-index: 1;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-left: -20px;
      padding-left: 50px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
      width: calc(50% + 20px);
      border-radius: 0 16px 16px 0;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 100px;
    }
    @include media-breakpoint-up(xxl) {
      margin-left: -50px;
      padding-right: 100px;
      padding-left: 100px;
      width: calc(50% + 50px);
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(22, 21, 73, 1) 0%, rgba(46, 45, 92, 1) 100%);
      content: '';
      @include media-breakpoint-up(lg) {
        background-image: linear-gradient(to right, rgba(22, 21, 73, 1) 0%, rgba(46, 45, 92, 1) 100%);
        clip-path: polygon(100px 0%, 100% 0, 100% 100%, 100px 100%, 0% 50%);
      }
      @include media-breakpoint-up(xl) {
        clip-path: polygon(155px 0%, 100% 0, 100% 100%, 155px 100%, 0% 50%);
      }
    }
  }

  &__slide {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: 538px;
      border-radius: 16px 0 0 16px;
    }
    @include media-breakpoint-up(xl) {
      height: 538px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        clip-path: polygon(0 0, calc(100% - 100px) 0%, 100% 50%, calc(100% - 100px) 100%, 0 100%);
      }
      @include media-breakpoint-up(xl) {
        clip-path: polygon(0 0, calc(100% - 155px) 0%, 100% 50%, calc(100% - 155px) 100%, 0 100%);
      }
    }
  }

  &__content {
    padding: 20px;
    position: relative;
    color: $white;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 50px;
      background: transparent;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
    }

    h6 {
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 35px;
      }
    }
  }

  &__block {
    padding: 5px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 10px;
      right: auto;
      bottom: 40px;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 500;
        color: $cyan;
      }
    }
  }

  &__next,
  &__prev {
    display: none !important;
    @include media-breakpoint-up(xl) {
      margin-top: -70px;
      position: absolute;
      top: 50%;
      z-index: 3;
      display: flex !important;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 140px;
      font-size: 13px;
      color: $blue;
      background: $white;
      transition: all 0.3s;
      clip-path: polygon(40px 0%, calc(100% - 40px) 0%, 100% 50%, calc(100% - 40px) 100%, 40px 100%, 0% 50%);
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:after {
      width: 0;
      height: 0;
      border-color: transparent transparent transparent $cyan;
      border-style: solid;
      border-width: 8px 0 8px 10px;
      content: '';
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }

  &__prev {
    left: 20px;

    &:after {
      border-color: transparent $cyan transparent transparent;
      border-width: 8px 10px 8px 0;
    }
  }

  &__next {
    right: 20px;
  }
}

}
.pageblock_14530 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14531 {
.image-text {
  padding: {
    top: 40px;
    bottom: 40px;
  }
  background: $gray-300;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    padding: {
      top: 120px;
      bottom: 120px;
    }
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14532 {
@import '~assets/styles/variables';

// Hide step 2 until it is paginated to
.stepper-step-2 {
  display: none;
}

// Heading
.step-heading {
  padding-top: 0;
  padding-bottom: 40px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    padding-bottom: 60px;
  }

  .h2,
  h2 {
    color: $pink;

    strong {
        color: $blue;
    }
  }

  &__subtitle {
    margin-bottom: 20px;
    font-weight: 600;
    color: $cyan;
  }
  
  &::after {
    display: none;
  }
}

// Section
.step-section {
  margin-bottom: 60px;
  position: relative;
  z-index: 6;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__list {
    margin: 0 -8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: calc(100% + 16px);
  }

  &__list-item {
    padding: 0 8px;
    width: 100%;
    @include media-breakpoint-up(sm) {
      width: 50%;
    }
    @include media-breakpoint-up(lg) {
      width: 33.333%;
    }
    @include media-breakpoint-up(xl) {
      width: 25%;
    }
    @include media-breakpoint-up(xxl) {
      width: 20%;
    }
  }
}

// Pagination

.step-pagination {
  margin: 30px 0;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(xl) {
    margin: 50px 0;
  }

  &__list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__list-item {
    margin: 0 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    font-size: 16px;
    font-weight: 600;
    color: $blue;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      width: 48px;
      color: $teal;
    }

    &:after {
      margin-top: -1px;
      position: absolute;
      top: 50%;
      left: 100%;
      width: 16px;
      height: 2px;
      background: rgba(248, 241, 234, 1);
      content: '';
    }

    &:last-child {
      &:after {
        display: none;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      text-decoration: none;
      color: $blue;
      border-radius: 50%;
      background: $gray-300;
      transition: all 0.3s;

      &:hover {
        color: $white;
        background: $cyan;
      }
    }

    &.is-selected {
      a {
        color: $cyan;
        background: $gray-300;
      }
    }

    &.is-active {
      a {
        color: $white;
        background: $cyan;
      }

      &:after {
        background-image: linear-gradient(to right, rgba(0, 176, 167, 1) 0%, rgba(237, 237, 238, 1) 100%);
      }
    }
  }
}

// Card (step)
.card-step {
  margin-bottom: 16px;
  padding-bottom: 35px;
  width: 100%;
  text-align: center;
  border-radius: 8px;
  background: $white;
  overflow: hidden;
  box-shadow: 0 8px 8px rgba($black, 0.08);
  @include media-breakpoint-up(lg) {
    margin-bottom: 30px;
  }

  h6 {
    margin-bottom: 20px;
  }

  &__img {
    margin-bottom: 25px;
    padding: 10px 10px 0;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    @include background-image(step-block-bg, svg, center top, no-repeat, 100% 100%);
    @include media-breakpoint-up(lg) {
      padding: 0;
      height: 144px;
    }

    img {
      max-height: 100%;
      object-fit: contain;
      height: 80px;
      width: 80px;
    }
  }

  &__icon {
    padding-top: 20px;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 140px;
  }
}

// Card (expert)
.card-search-expert {
  margin-bottom: 30px;
  padding: 25px;
  width: 100%;
  font-size: $font-size-sm;
  border-radius: 16px;
  border: 1px solid rgba($blue, 10%);
  background: $gray-300;
  overflow: hidden;
  box-shadow: unset;
  @include media-breakpoint-up(xl) {
    padding: 60px;
  }

  h4 {
    margin-bottom: 20px;
  }

  .btn {
    width: 100%;
    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .form-group {
    width: 50%;

    label {
      @include media-breakpoint-up(lg) {
        padding-top: 21px;
      }
    }

    .form-control {
      @include media-breakpoint-up(lg) {
        height: 64px;
      }
    }

    &:nth-child(1) {
      @include media-breakpoint-up(md) {
        width: 290px;
      }

      .form-control {
        border-radius: 8px 0 0 8px;
      }
    }

    &:nth-child(2) {
      @include media-breakpoint-up(md) {
        width: 210px;
      }

      .form-control {
        margin-left: -1px;
        border-radius: 0 8px 8px 0;
      }
    }
  }
}

}
.pageblock_14533 {
.customer-reviews {
  overflow: hidden;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto 60px;
  border-radius: 16px;
  background: $blue;

  p {
    margin-bottom: 0;
  }

  .container {
    position: relative;
  }

  .customer-reviews__background {
    width: 100%;
    height: 100%;
    background: radial-gradient(43.82% 76.5% at 53.72% 45.91%, $indigo 0%, $blue 100%);
    pointer-events: none;
    position: absolute;
    top: 0;
    right: 0;
  }

  .customer-reviews__wrapper {
    padding:{
      top: 96px;
      bottom: 96px;
    }
  }

  .customer-reviews__subtitle {
    margin-bottom: 32px;
    font-weight: 700;
    text-align: center;
    color: $cyan;
    position: relative;
  }

  .customer-reviews__slider {
    display: flex;
    justify-content: center;
    position: relative;

    .js-slider {
      width: 890px;
      max-width: 100%;

      @include media-breakpoint-up(md) {
        padding: {
          right: 60px;
          left: 60px;
        }
      }
    }

    .slide {
      .slide__text {
        text-align: center;

        p {
          font: {
            weight: 700;
            size: 1.5rem;
          }
          line-height: 32px;
          letter-spacing: -1.5px;
          color: $white;

          @include media-breakpoint-up(lg) {
            font-size: $h3-font-size;
            line-height: 48px;
          }
        }
      }

      .slide__detail {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 8px;
        margin-top: 56px;
        font-size: 14px;

        .customer-review-name {
          color: $white;
        }

        .customer-review-date {
          color: rgba($white, 60%);
        }
      }
    }
  }

  .arrows-holder {
    &__right,
    &__left {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      &::before {
        opacity: 0.8;
        width: 380%;
        height: 380%;
        border-radius: 2px;
        content: '';
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      .arrows-button {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 140px;
        margin: 0;
        border: unset;
        border-radius: 2px;
        background: $white;
        clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);

        i {
          color: $cyan;
        }

        &.js-slide-prev {
          i {
            transform: scaleX(-1);
          }
        }
      }
    }

    &__right {
      right: 0;

      &::before {
        background: linear-gradient(270deg, $indigo 0%, $blue 100%);
        left: -16px;
      }

      @include media-breakpoint-between(lg, xxl) {
        right: 4%;
      }
    }

    &__left {
      left: 0;

      &::before {
        background: linear-gradient(90deg, $indigo 0%, $blue 100%);
        right: -16px;
      }

      @include media-breakpoint-between(lg, xxl) {
        left: 4%;
      }
    }

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin: {
      top: 56px;
      bottom: 0;
    }
    padding: 0;

    li {
      button {
        opacity: .3;
        display: block;
        width: 8px;
        height: 8px;
        border: unset;
        border-radius: 50%;
        padding: 0;
        text-indent: -9999px;
        background: $white;
        cursor: pointer;
      }

      &.slick-active {
        button {
          opacity: 1;
        }
      }
    }
  }

  @include media-breakpoint-up(md) {
    padding: {
      top: 80px;
      bottom: 80px;
    }
  }
}
}
.pageblock_14534 {
.grid-flex {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.card-grid {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 32px;
  border: 1px solid rgba($blue, 0.1);
  border-radius: 16px;
  background: $gray-300;
  padding: 40px 32px;

  > * {
    width: 100%;
  }

  .card__header {
    h5 {
      font-weight: 600;
    }

    p {
      margin-bottom: 0;
      color: $indigo;
      font-size: 1.25rem;
    }
  }

  .card__content {
    border-top: 1px solid rgba($blue, 0.1);
    padding-top: 24px;

    ul {
      margin-bottom: 0;
      padding: 0;

      li {
        padding-left: 16px;
        font: {
          weight: 600;
          size: 1rem;
        }
        position: relative;

        &::before {
          font-family: $font-family-icon;
          font-size: 8px;
          color: $cyan;
          content: '\e947';
          position: absolute;
          top: 6px;
          left: 0;
        }

        &:not(:first-of-type) {
          margin-top: 8px;
        }

        &.card-subscription__excluded {
          opacity: .2;
        }
      }
    }
  }

  .card__footer {
    margin-top: auto;
    border-top: 1px solid rgba($blue, 0.1);
    padding-top: 24px;
  }

  &.card-grid--small {
    .card__header {
      p {
        font-size: 1rem;
      }
    }
  }

  @include media-breakpoint-up(md) {
    max-width: calc((4 / 12 * 100%) - 16px);

    &.card-grid--small {
      max-width: calc(50% - 16px);
    }
  }

  @include media-breakpoint-up(xl) {
    &.card-grid--small {
      max-width: calc(25% - 16px);
    }
  }
}

.products {
  margin: {
    top: 40px;
    bottom: 40px;
  }

  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }

  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }

  .products__header {
    margin-bottom: 48px;
    text-align: center;

    h2 {
      margin-bottom: 0;

      strong {
        color: $pink;
      }

      + * {
        margin-top: 24px;
      }
    }
  }
}

}
.pageblock_14539 {
.landing-banner {
  margin-bottom: 30px;
  padding-top: 160px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 124px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 50px;
    padding-top: 160px;
  }

  &__holder {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      min-height: 640px;
      border-radius: 0 0 16px 16px;
      background: $blue;
    }
  }

  &__content {
    padding: 50px 20px;
    position: relative;
    z-index: 4;
    color: $white;
    background: $blue;
    @include media-breakpoint-up(lg) {
      padding: 0 0 0 20px;
      z-index: 6;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 280px;
      width: 40%;
    }

    &:after {
      position: absolute;
      top: -180px;
      left: -185px;
      z-index: -1;
      width: 370px;
      height: 700px;
      transform: scaleX(-1);
      content: '';
      @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    p,
    h1 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }
  }

  &__image {
    margin-top: -10px;
    position: relative;
    z-index: 5;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
      overflow: visible;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      content: '';
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: -185px;
        width: 370px;
        height: 100%;
        transform: scaleX(-1);
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(lg) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__search {
    margin: -28px 0 0 20px;
    padding: 20px;
    position: relative;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    border-radius: 8px;
    background: $white;
    overflow: hidden;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 20px;
      bottom: -40px;
      width: 400px;
    }
    @include media-breakpoint-up(lg) {
      margin-right: -480px;
      right: 50%;
    }
    @include media-breakpoint-up(xl) {
      margin-right: -580px;
      padding: 45px;
      width: 530px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: -680px;
    }

    p {
      margin-bottom: 8px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }
    }
  }

  &__search-form {
    position: relative;
    width: 100%;

    input {
      padding: 0 60px 0 20px;
      width: 100%;
      height: 56px;
      font-size: 16px;
      font-weight: 300;
      border: 1px solid $border-color;
      border-radius: 4px;
    }

    button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 40px;
      height: 40px;
      font-size: 12px;
      color: $white;
      border: 0;
      border-radius: 50%;
      background: $cyan;
    }
  }

  &__block {
    margin: -50px 0 0 20px;
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    color: $white;
    border-radius: 10px 0 0 10px;
    background: $blue;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 370px;
    }
    @include media-breakpoint-up(xl) {
      width: 480px;
    }
  }

  &__block-img {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 112px;
    @include background-image(hero-block-bg, svg, center right, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      width: 160px;
      height: 152px;
    }

    img {
      max-height: 70px;
      @include media-breakpoint-up(xl) {
        max-height: 92px;
      }
    }
  }

  &__block-text {
    padding-left: 20px;
    width: calc(100% - 120px);
    font-size: 12px;
    @include media-breakpoint-up(xl) {
      padding-left: 35px;
      width: calc(100% - 160px);
      font-size: $font-size-sm;
    }

    strong {
      margin-bottom: 5px;
      display: block;
      font-size: $font-size-sm;
      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }

    p {
      margin-bottom: 5px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 15px;
      }
    }

    span {
      display: block;
      color: $cyan;
    }
  }
}

}
.pageblock_14540 {
.usp-section {
	margin-bottom: 60px;
	position: relative;
	width: 100%;
	overflow: hidden;
	@include media-breakpoint-up(xl) {
		margin-bottom: 120px;
	}

	&__list {
		margin: 0;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	&__item {
		margin-bottom: 15px;
		position: relative;
		width: 100%;
		font-size: 16px;
		border-right: 1px solid $gray-500;
		@include media-breakpoint-up(lg) {
			margin: 0 15px 0 0;
			width: calc(33.333% - 15px);
		}
		@include media-breakpoint-up(xxl) {
			margin-right: 30px;
			padding-left: 30px;
			width: calc(33.333% - 30px);
		}
		padding: {
			top: 4px;
			bottom: 4px;
			left: 25px;
		}

		&:last-child {
			margin: 0;
			border-right: 0;
		}

		&:before {
			position: absolute;
			top: 6px;
			left: 0;
			font-family: $font-family-icon;
			font-size: 11px;
			color: $cyan;
			content: '\e909';
		}
	}
}

}
.pageblock_14541 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}

}
.pageblock_14542 {
.cta-house {
  margin: 0 auto 60px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__holder {
    padding: 20px 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(lg) {
      padding: 40px 0;
    }
    @include media-breakpoint-up(xl) {
      padding: 80px 0;
    }
  }

  &__left {
    position: relative;
    z-index: 5;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-right: -20px;
      width: calc(50% + 20px);
    }
    @include media-breakpoint-up(xxl) {
      margin-right: -50px;
      width: calc(50% + 50px);
    }
  }

  &__right {
    position: relative;
    z-index: 1;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-left: -20px;
      padding-left: 50px;
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      align-items: center;
      width: calc(50% + 20px);
      border-radius: 0 16px 16px 0;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 100px;
    }
    @include media-breakpoint-up(xxl) {
      margin-left: -50px;
      padding-right: 100px;
      padding-left: 100px;
      width: calc(50% + 50px);
    }

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, rgba(22, 21, 73, 1) 0%, rgba(46, 45, 92, 1) 100%);
      content: '';
      @include media-breakpoint-up(lg) {
        background-image: linear-gradient(to right, rgba(22, 21, 73, 1) 0%, rgba(46, 45, 92, 1) 100%);
        clip-path: polygon(100px 0%, 100% 0, 100% 100%, 100px 100%, 0% 50%);
      }
      @include media-breakpoint-up(xl) {
        clip-path: polygon(155px 0%, 100% 0, 100% 100%, 155px 100%, 0% 50%);
      }
    }
  }

  &__slide {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      height: 538px;
      border-radius: 16px 0 0 16px;
    }
    @include media-breakpoint-up(xl) {
      height: 538px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        clip-path: polygon(0 0, calc(100% - 100px) 0%, 100% 50%, calc(100% - 100px) 100%, 0 100%);
      }
      @include media-breakpoint-up(xl) {
        clip-path: polygon(0 0, calc(100% - 155px) 0%, 100% 50%, calc(100% - 155px) 100%, 0 100%);
      }
    }
  }

  &__content {
    padding: 20px;
    position: relative;
    color: $white;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 50px;
      background: transparent;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
    }

    h6 {
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 35px;
      }
    }
  }

  &__block {
    padding: 5px;
    position: absolute;
    right: 10px;
    bottom: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 10px;
      right: auto;
      bottom: 40px;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 500;
        color: $cyan;
      }
    }
  }

  &__next,
  &__prev {
    display: none !important;
    @include media-breakpoint-up(xl) {
      margin-top: -70px;
      position: absolute;
      top: 50%;
      z-index: 3;
      display: flex !important;
      align-content: center;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 140px;
      font-size: 13px;
      color: $blue;
      background: $white;
      transition: all 0.3s;
      clip-path: polygon(40px 0%, calc(100% - 40px) 0%, 100% 50%, calc(100% - 40px) 100%, 40px 100%, 0% 50%);
    }

    &.slick-disabled {
      opacity: 0;
      visibility: hidden;
    }

    &:after {
      width: 0;
      height: 0;
      border-color: transparent transparent transparent $cyan;
      border-style: solid;
      border-width: 8px 0 8px 10px;
      content: '';
    }

    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }

  &__prev {
    left: 20px;

    &:after {
      border-color: transparent $cyan transparent transparent;
      border-width: 8px 10px 8px 0;
    }
  }

  &__next {
    right: 20px;
  }
}

}
.pageblock_14543 {
.usp-slider {
  margin-bottom: 56px;
  position: relative;
  width: 100%;
  overflow: hidden;

  &.usp-slider--alt {
    .usp-slider__title {
      strong {
        color: $cyan;
      }
    }

    .usp-slider__slide {
      margin-right: 20px;
    }

    .slick-track {
      display: flex;

      .slick-slide {
        height: auto;

        .card-usp {
          &,
          &__content {
            height: 100%;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__slider {
    position: relative;
    width: 100%;
  }

  &__title {
    margin-bottom: 30px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 50px;
    }

    strong {
      color: $pink;
    }
  }

  h3 {
    line-height: 1;
  }

  &__slide {
    margin-right: 30px;
    width: 240px;
    @include media-breakpoint-up(xl) {
      margin-right: 20px;
      width: 300px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: 30px;
      width: 320px;
    }
    @include media-breakpoint-up(xxxl) {
      margin-right: 70px;
      width: 340px;
    }
  }
}

.card-usp {
  position: relative;
  display: block;
  width: 100%;

  &.card-usp--alt {
    .card-usp__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-radius: 16px;
      background-color: $gray-300;
      padding: 32px;

      p {
        padding-bottom: 32px;
      }
    }

    .btn {
      margin-top: auto;
    }
  }

  &__image {
    margin-bottom: 15px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 102px;
    height: 80px;
    @include background-image(menu-bg, svg, center center, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      margin-bottom: 35px;
      width: 155px;
      height: 120px;
    }

    img {
      max-height: 50px;
      @include media-breakpoint-up(xl) {
        max-height: 76px;
      }
    }
  }

  &__content {
    p {
      margin-bottom: 0;
      padding: 16px 0;
      font-size: $font-size-sm;
    }
  }
}
}
.pageblock_14544 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14545 {
.image-text {
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin: {
      top: 80px;
      bottom: 80px;
    }
  }
  @include media-breakpoint-up(xl) {
    margin: {
      top: 120px;
      bottom: 120px;
    }
  }
  margin: {
    top: 40px;
    bottom: 40px;
  }

  &__content {
    margin: 0 -20px;
    padding: 30px 20px;
    width: calc(100% + 40px);
    border-radius: 0 12px 0 0;
    background: $white;
    @include media-breakpoint-up(sm) {
      margin: 0;
      padding: 0;
      width: 100%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(lg) {
      padding: 0 30px;
    }
    @include media-breakpoint-up(xl) {
      padding: 0;
    }

    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 30px;
      }

      strong {
        color: $pink;
      }
    }
  }

  &__image {
    margin: 0 -20px -30px;
    position: relative;
    width: calc(100% + 40px);
    overflow: hidden;
    @include media-breakpoint-up(sm) {
      margin: 0 0 30px;
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(sm) {
        border-radius: 16px;
      }
    }
  }

  &__tag {
    padding: 5px;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 104px;
    height: 104px;
    font-size: 12px;
    text-align: center;
    border-radius: 50%;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 15px;
      top: 40px;
      right: auto;
      left: 40px;
      width: 160px;
      height: 160px;
      font-size: $font-size-sm;
    }

    span {
      display: block;
      color: $blue;

      strong {
        display: inline-block;
        font-weight: 700;
        color: $cyan;
      }
    }
  }
}

}
.pageblock_14546 {
.specialists-form {
  margin-bottom: 60px;
  position: relative;
  width: 100%;
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    margin-bottom: 120px;
  }

  &__holder {
    margin: 0 -20px;
    position: relative;
    z-index: 2;
    width: calc(100% + 40px);
    border-radius: 16px;
    background-color: $blue;
    overflow: hidden;
    @include background-image(specialist-bg, svg, center center, no-repeat, cover);
    @include media-breakpoint-up(sm) {
      margin: 0;
      width: 100%;
    }
    @include media-breakpoint-up(xl) {
      border-radius: 18px;
      padding: {
        top: 90px;
        bottom: 90px;
      }
    }
    padding: {
      top: 50px;
      right: 20px;
      bottom: 50px;
      left: 20px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: -390px;
      z-index: -1;
      width: 776px;
      height: 1522px;
      transform: scaleX(-1);
      content: '';
      @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
    }
  }

  &__top {
    margin-bottom: 10px;
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: rgba($white, 0.4);
    @include media-breakpoint-up(xl) {
      margin-bottom: 20px;
      font-size: 20px;
    }

    h2 {
      margin-bottom: 25px;
      color: $white;
      @include media-breakpoint-down(lg) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.75px;
      }
    }
  }

  &__slider {
    position: relative;
    width: 100%;
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;
    border-radius: 8px;
    background: $pink;
  }

  &__slide {
    margin: 0 8px;
    width: 160px;
  }

  &__form {
    margin-bottom: 30px;
    padding-right: 20px;
    width: 100%;
    @include media-breakpoint-up(xl) {
      margin-bottom: 60px;
    }
  }
}

.card-search {
  padding-left: 80px;
  position: relative;
  width: 100%;
  border-radius: 10px 0 0 10px;
  background: $white;
  box-shadow: 0 12px 24px rgba($black, 0.12);

  @include media-breakpoint-up(md) {
    padding-left: 100px;
  }

  label {
    padding-left: 18px;
    position: absolute;
    top: 0;
    left: 0;
    display: inline-flex;
    align-items: center;
    height: 60px;
    font-size: $font-size-sm;
    font-weight: 600;
    color: $blue;

    @include media-breakpoint-up(md) {
      padding-left: 28px;
      height: 80px;
    }
  }

  &:after {
    position: absolute;
    top: 0;
    left: 100%;
    width: 0;
    height: 0;
    border-color: transparent transparent transparent $white;
    border-style: solid;
    border-width: 30px 0 30px 24px;
    content: '';

    @include media-breakpoint-up(md) {
      border-width: 40px 0 40px 24px;
    }
  }

  &__search-input {
    padding-right: 70px;
    width: 100%;
    height: 60px;
    font-size: $font-size-sm;
    font-weight: 300;
    color: $teal;
    border: 0;
    background: transparent;

    @include media-breakpoint-up(md) {
      height: 80px;
    }

    &::placeholder {
      color: $teal;
    }
  }

  &__search-btn {
    padding-top: 4px;
    position: absolute;
    top: 6px;
    right: 5px;
    width: 48px;
    height: 48px;
    font-size: $font-size-sm;
    color: $white;
    border: 0;
    border-radius: 50%;
    background: $orange;

    @include media-breakpoint-up(md) {
      top: 16px;
    }
  }

  &--select {
    padding-right: 245px;
  }

  &__select {
    padding-right: 20px;
    position: absolute;
    top: 15px;
    right: 40px;
    width: 210px;
    border-left: 1px solid $light-border;

    select {
      padding: 0 40px 0 15px;
      height: 48px;
      font-size: 16px;
      border: 0;
      background-color: transparent;
      cursor: pointer;
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3;

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}
}
.pageblock_14561 {
.heading { padding-bottom:10px; }
}
.pageblock_14565 {
.blog-img {
    background-position: center;
    height: 15em;
}
}
.pageblock_14577 {
.word-filter {
    .btn-light {
        @include media-breakpoint-down(md) {
            padding: 8px 12px;
            margin-bottom: 4px;
        }
    }
}

.house-heading {
	margin-bottom: 40px;
	padding-top: 130px;
	padding-bottom: 40px;
	position: relative;
	width: 100%;
	background-color: $blue;
  z-index: 4;
	@include background-image(house-heading-bg, svg, center top, no-repeat, cover);
	@include media-breakpoint-up(lg) {
		margin-bottom: 24px;
		padding-top: 160px;
		border-radius: 0 0 15px 15px;
	}
	@include media-breakpoint-up(xl) {
    &:not(.house-heading__thin) {
		  padding-top: 200px;
    }
	}

  &__thin {
    height: 140px;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

	&__search {
		margin: 0 auto;
		padding-right: 25px;
		max-width: 802px;
	}
}

.faq-section {
  --size-link-asset: 32px;
	margin-bottom: 60px;
	position: relative;
	width: 100%;

	@include media-breakpoint-up(lg) {
    --size-link-asset: 54px;
		margin-bottom: 120px;
	}

	h2 {
		margin-bottom: 20px;
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
		}

		strong {
			color: $pink;
		}
	}

  &__list {
    &--with-asset {
      .faq-section__panel {
        padding-left: calc(var(--size-link-asset) + 12px);
      }
    }
  }

	&__top {
		color: $blue;
		@include media-breakpoint-up(lg) {
			color: $pink;
		}
	}

	&__holder {
		margin-bottom: 20px;
		padding: 25px;
		width: 100%;
		border-radius: 8px;
		background-color: $gray-300;
		@include media-breakpoint-up(md) {
			padding: 45px;
		}
		@include media-breakpoint-up(xl) {
			margin-bottom: 40px;
			padding: 85px;
			border-radius: 16px;
		}

		h3 {
			margin-bottom: 25px;
			@include media-breakpoint-up(xl) {
				margin-bottom: 50px;
			}
		}
	}

	&__item {
		margin-bottom: 18px;
		padding-bottom: 18px;
		border-bottom: 1px solid $light;

		&:last-child {
			margin-bottom: 0;
			padding-bottom: 0;
			border-bottom: 0;
		}

		&.is-active {
			.faq-section__link {
				i {
					transform: rotate(-180deg);
				}
			}
		}
	}

	&__link {
		padding: 10px 35px 10px 0;
		position: relative;
		display: block;
		font-weight: 600;
		color: $blue;

		@include media-breakpoint-up(lg) {
			padding: 0px 30px 0px 0;
		}

    &:not(.js-faq-link) {
      pointer-events: none;
    }

    &__content {
      display: flex;
      align-items: center;

      &__asset {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: var(--size-link-asset);
        height: var(--size-link-asset);
        margin-right: 12px;
        border-radius: 50%;
        background-color: $light;

        img {
          max: {
            width: 60%;
            height: 60%;
          }
        }
      }
    }

		i {
			position: absolute;
			top: 13px;
			right: 0;
			display: flex;
			align-content: center;
			align-items: center;
			justify-content: center;
			width: 20px;
			height: 20px;
			font-size: 8px;
			transition: all 0.3s;
			@include media-breakpoint-up(lg) {
				margin-top: -15px;
				top: 50%;
				width: 30px;
				height: 30px;
				font-size: 10px;
			}
		}

		&.js-faq-link {
      &:hover {
        text-decoration: none;
        color: $pink;
      }
    }
	}

	&__panel {
		display: none;
		@include media-breakpoint-up(xl) {
			padding-right: 100px;
		}

		p {
			margin-bottom: 0;
			font-size: 16px;
		}
	}

	&--inner {
		padding-top: 20px;
		@include media-breakpoint-up(xl) {
			padding-top: 80px;
		}

		.faq-section__holder {
			@include media-breakpoint-up(xl) {
				padding: 30px 60px;
			}
		}

		&:after {
			display: none;
		}
	}
}

}
.pageblock_14585 {
.survey {
  &__form {
    border-radius: 16px;
    padding: 80px 40px;
    background-color: $gray-300;
    &__content {
      margin-bottom: 40px;
      .h5 {
        margin-bottom: 24px;
      }
    }
  }
  &__info {
    border-radius: 16px;
    padding: 48px;
    background-color: $gray-300;
    h6 {
      margin-bottom: 16px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 16px;
    }
  }
}
}
.pageblock_14587 {
.survey {
  &__form {
    border-radius: 16px;
    padding: 80px 40px;
    background-color: $gray-300;
    &__content {
      margin-bottom: 40px;
      .h5 {
        margin-bottom: 24px;
      }
    }
  }
  &__info {
    border-radius: 16px;
    padding: 48px;
    background-color: $gray-300;
    h6 {
      margin-bottom: 16px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 16px;
    }
  }
}
}
.pageblock_14593 {
.landing-banner {
  margin-bottom: 30px;
  padding-top: 160px;
  position: relative;
  width: 100%;
  @include media-breakpoint-up(lg) {
    padding-top: 124px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 50px;
    padding-top: 160px;
  }

  &__holder {
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1920px;
    border-radius: 0 0 12px 12px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      min-height: 640px;
      border-radius: 0 0 16px 16px;
      background: $blue;
    }
  }

  &__content {
    padding: 50px 20px;
    position: relative;
    z-index: 4;
    color: $white;
    background: $blue;
    @include media-breakpoint-up(lg) {
      padding: 0 0 0 20px;
      z-index: 6;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 280px;
      width: 40%;
    }

    &:after {
      position: absolute;
      top: -180px;
      left: -185px;
      z-index: -1;
      width: 370px;
      height: 700px;
      transform: scaleX(-1);
      content: '';
      @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    p,
    h1 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }
  }

  &__image {
    margin-top: -10px;
    position: relative;
    z-index: 5;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    @include media-breakpoint-up(lg) {
      margin-top: 0;
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
      overflow: visible;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
      content: '';
      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: -185px;
        width: 370px;
        height: 100%;
        transform: scaleX(-1);
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      @include media-breakpoint-up(lg) {
        height: 100%;
        object-fit: cover;
      }
    }
  }

  &__search {
    margin: -28px 0 0 20px;
    padding: 20px;
    position: relative;
    z-index: 6;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    border-radius: 8px;
    background: $white;
    overflow: hidden;
    box-shadow: 0 16px 48px rgba($black, 0.12);
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 20px;
      bottom: -40px;
      width: 400px;
    }
    @include media-breakpoint-up(lg) {
      margin-right: -480px;
      right: 50%;
    }
    @include media-breakpoint-up(xl) {
      margin-right: -580px;
      padding: 45px;
      width: 530px;
    }
    @include media-breakpoint-up(xxl) {
      margin-right: -680px;
    }

    p {
      margin-bottom: 8px;
      @include media-breakpoint-up(lg) {
        margin-bottom: 25px;
      }
    }
  }

  &__search-form {
    position: relative;
    width: 100%;

    input {
      padding: 0 60px 0 20px;
      width: 100%;
      height: 56px;
      font-size: 16px;
      font-weight: 300;
      border: 1px solid $border-color;
      border-radius: 4px;
    }

    button {
      position: absolute;
      top: 8px;
      right: 8px;
      width: 40px;
      height: 40px;
      font-size: 12px;
      color: $white;
      border: 0;
      border-radius: 50%;
      background: $cyan;
    }
  }

  &__block {
    margin: -50px 0 0 20px;
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: calc(100% - 20px);
    color: $white;
    border-radius: 10px 0 0 10px;
    background: $blue;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      right: 0;
      bottom: 0;
      width: 370px;
    }
    @include media-breakpoint-up(xl) {
      width: 480px;
    }
  }

  &__block-img {
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 112px;
    @include background-image(hero-block-bg, svg, center right, no-repeat, 100% 100%);
    @include media-breakpoint-up(xl) {
      width: 160px;
      height: 152px;
    }

    img {
      max-height: 70px;
      @include media-breakpoint-up(xl) {
        max-height: 92px;
      }
    }
  }

  &__block-text {
    padding-left: 20px;
    width: calc(100% - 120px);
    font-size: 12px;
    @include media-breakpoint-up(xl) {
      padding-left: 35px;
      width: calc(100% - 160px);
      font-size: $font-size-sm;
    }

    strong {
      margin-bottom: 5px;
      display: block;
      font-size: $font-size-sm;
      @include media-breakpoint-up(xl) {
        font-size: 20px;
      }
    }

    p {
      margin-bottom: 5px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 15px;
      }
    }

    span {
      display: block;
      color: $cyan;
    }
  }
}

}
.pageblock_14594 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}
}
.pageblock_14595 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}
}
.pageblock_14596 {
.image-text {
	position: relative;
	z-index: 1;
	width: 100%;
	@include media-breakpoint-up(lg) {
		margin: {
			top: 80px;
			bottom: 80px;
		}
	}
	@include media-breakpoint-up(xl) {
		margin: {
			top: 120px;
			bottom: 120px;
		}
	}
	margin: {
		top: 40px;
		bottom: 40px;
	}

	&__content {
		margin: 0 -20px;
		padding: 30px 20px;
		width: calc(100% + 40px);
		border-radius: 0 12px 0 0;
		background: $white;
		@include media-breakpoint-up(sm) {
			margin: 0;
			padding: 0;
			width: 100%;
			border-radius: 0;
			background: transparent;
		}
		@include media-breakpoint-up(lg) {
			padding: 0 30px;
		}
		@include media-breakpoint-up(xl) {
			padding: 0;
		}

		h2 {
			@include media-breakpoint-up(xl) {
				margin-bottom: 30px;
			}

			strong {
				color: $pink;
			}
		}
	}

	&__image {
		margin: 0 -20px -30px;
		position: relative;
		width: calc(100% + 40px);
		overflow: hidden;
		@include media-breakpoint-up(sm) {
			margin: 0 0 30px;
			width: 100%;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}

		picture,
		img {
			display: block;
			width: 100%;
			@include media-breakpoint-up(sm) {
				border-radius: 16px;
			}
		}
	}

	&__tag {
		padding: 5px;
		position: absolute;
		top: 10px;
		right: 10px;
		display: flex;
		flex-wrap: wrap;
		align-content: center;
		align-items: center;
		justify-content: center;
		width: 104px;
		height: 104px;
		font-size: 12px;
		text-align: center;
		border-radius: 50%;
		background: $white;
		@include media-breakpoint-up(lg) {
			padding: 15px;
			top: 40px;
			right: auto;
			left: 40px;
			width: 160px;
			height: 160px;
			font-size: $font-size-sm;
		}

		span {
			display: block;
			color: $blue;

			strong {
				display: inline-block;
				font-weight: 700;
				color: $cyan;
			}
		}
	}
}
}
.pageblock_14600 {
ul {
    padding-left: 0px;
}
}
.pageblock_14604 {
.residential {
  padding-bottom: 40px;
  position: relative;
  background-color: #f4eeea;
  overflow: hidden;

  @media (min-width: 768px) {
    height: 1700px;
  }

  @media (min-width: 992px) {
    padding-top: 60px;
    height: 1200px;
  }

  @media (min-width: 1200px) {
    padding-top: 80px;
  }
}

.residential__holder {
  position: relative;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
}

.residential__left {
  padding-top: 48px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    padding-right: 20px;
    padding-top: 0;
    padding-bottom: 0;
    width: 35%;
  }

  @media (min-width: 1200px) {
    width: 31%;
  }

  &:after {
    position: absolute;
    top: 0;
    left: -20px;
    width: calc(100% + 40px);
    height: 100%;
    border-radius: 0 0 15px 15px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0.7) 0%, rgba(232, 219, 209, 0.7) 100%);
    content: "";

    @media (min-width: 992px) {
      display: none;
    }
  }
}

.residential__subtitle {
  margin-bottom: 11px;
  position: relative;
  z-index: 2;
  font-size: 16px;
  font-weight: 700;
  color: #00b0a7;

  @media (min-width: 768px) {
    font-size: 18px;
  }
}

.residential__title {
  margin-bottom: 48px;
  position: relative;
  z-index: 2;
  font-size: 22px;
  line-height: 1.25;

  @media (min-width: 768px) {
    font-size: 32px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 24px;
  }

  h1, h2 {
    font-size: unset;
    line-height: unset;
  }
}

.residential__item {
  margin-bottom: 13px;

  &.active {
    .residential__heading {
      font-weight: 700;
      color: #161549;

      span {
        &:before {
          opacity: 1;
          visibility: visible;
        }

        &:after {
          background-color: #fff;
        }
      }
    }

    .residential__text {
      display: block;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.residential__right {
  padding-top: 50px;
  position: relative;
  width: 100%;

  @media (min-width: 992px) {
    padding-top: 130px;
    width: 65%;
  }

  @media (min-width: 1200px) {
    width: 69%;
  }
}

.residential__slider {
  position: absolute;
  top: 50px;
  left: 0;
  opacity: 0;
  width: 100%;
  pointer-events: none;

  @media (min-width: 992px) {
    top: 130px;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    position: relative;
    top: 0;
  }
}

.residential__heading {
  padding-bottom: 17px;
  position: relative;
  display: inline-block;
  font-size: 18px;
  font-weight: 500;
  color: rgba(92, 91, 129, 0.7);
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: rgba(92, 91, 129, 0.7);
  }

  span {
    position: absolute;
    top: -5px;
    left: -55px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;

    &:before {
      display: block;
      opacity: 0;
      visibility: hidden;
      width: 32px;
      height: 32px;
      border: 1px solid #fff;
      border-radius: 50px;
      background-color: #f26a38;
      transition: all 0.3s;
      content: "";
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      border-radius: 50px;
      background-color: rgba(92, 91, 129, 0.7);
      transform: translate(-50%, -50%);
      transition: all 0.3s;
      content: "";
    }
  }
}

.residential__phone {
  justify-content: space-between;
  margin-left: -20px;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  width: calc(100% + 40px);

  &:after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(92, 91, 129, 0.2);
    transform: translateY(-50%);
    content: "";
  }
}

.residential__dot {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  span {
    display: none;
    white-space: nowrap;
    font: {
      weight: 700;
      size: 14px;
    }
    color: #161549;
    position: absolute;
    top: -32px;
    left: 20px;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: relative;
    z-index: 2;

    &:before {
      display: block;
      opacity: 0;
      visibility: hidden;
      width: 32px;
      height: 32px;
      border: 1px solid #fff;
      border-radius: 50px;
      background-color: #f26a38;
      transition: all 0.3s;
      content: "";
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 12px;
      height: 12px;
      border-radius: 50px;
      background-color: rgba(92, 91, 129, 0.7);
      transform: translate(-50%, -50%);
      transition: all 0.3s;
      content: "";
    }
  }

  &.active {
    span {
      display: block;
    }

    a {
      &:before {
        opacity: 1;
        visibility: visible;
      }

      &:after {
        background-color: #fff;
      }
    }
  }
}

.residential__links {
  padding-top: 22px;
  padding-left: 38px;
  display: none;
  border-left: 2px solid rgba(92, 91, 129, 0.2);

  @media (min-width: 992px) {
    display: block;
  }
}

.residential__text {
  padding-bottom: 22px;
  padding-left: 14px;
  display: none;

  ul {
    margin-bottom: 0;
    padding-left: 0;

    li {
      margin-bottom: 6px;
      padding-left: 12px;
      position: relative;

      &:before {
        position: absolute;
        top: 12px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        width: 5px;
        height: 5px;
        border-radius: 10px;
        background-color: #f26a38;
        transition: all 0.3s;
        content: "";
      }

      a, span {
        font-size: 16px;
        color: #5c5b81;
        transition: all 0.3s;
        cursor: pointer;
      }

      &.active:before,
      &:hover:before {
        opacity: 1;
        visibility: visible;
      }

      &.active a,
      &.active span,
      &:hover a,
      &:hover span {
        font-weight: 600;
        text-decoration: none;
        color: #161549;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.residential__wrap {
  display: flex;
  flex-wrap: wrap;
}

.residential__image {
  margin-bottom: 40px;
  display: none;
  width: 100%;
  border-radius: 16px;

  @media (min-width: 768px) {
    margin-bottom: 0;
    display: block;
    width: 34%;
  }

  img {
    width: 100%;
    border-radius: 16px;
  }
}

.residential__content {
  width: 100%;

  @media (min-width: 768px) {
    padding-left: 30px;
    width: 66%;
  }

  @media (min-width: 1200px) {
    padding-left: 74px;
  }

  strong {
    margin-bottom: 11px;
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: #00b0a7;
  }

  h6 {
    margin-bottom: 0;
    font-size: 18px;
  }

  p {
    color: #161549;
  }

  .btn.btn-link-dark {
    color: #161549;

    &:hover {
      color: #f26a38;
    }
  }
}

.residential__buttons {
  padding-top: 15px;
}

.residential__slider-nav {
  padding-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;

  //@media (min-width: 768px) {
  //  padding-top: 50px;
  //}

  @media (min-width: 992px) {
    padding-top: 80px;
    padding-right: 20px;
    justify-content: flex-end;
  }

  @media (min-width: 1200px) {
    padding-right: 100px;
  }
}

.residential__dots {
  padding-bottom: 5px;

  .slick-dots {
    margin: 0;
    padding: 0;
    display: flex;

    li {
      margin-right: 4px;

      @media (min-width: 768px) {
        margin-right: 7px;
      }

      &.slick-active button {
        background-color: $orange;
      }

      button {
        width: 20px;
        height: 2px;
        font-size: 0;
        border: 0;
        background-color: rgba(22, 21, 73, .2);

        @media (min-width: 768px) {
          width: 48px;
        }
      }
    }
  }
}

.residential__arrows {
  padding-left: 15px;
  display: flex;

  @media (min-width: 768px) {
    padding-left: 30px;
  }

  .slick-arrow {
    width: 40px;
    height: 40px;
    font-size: 14px;
    color: #fff;
    border: 0;
    border-radius: 50%;
    background-color: $cyan;

    &.change_prev,
    &.change_next,
    &.change_disabled {
      color: #000;
      background-color: #fff;
    }
  }

  .change-prev, .change-next {
    display: none;
  }

  &:has(.slick-arrow.slick-next.slick-disabled) {
    .change-next {
      display: block;
    }

    .slick-next {
      display: none !important;
    }
  }

  &:has(.slick-arrow.slick-prev.slick-disabled) {
    .change-prev {
      margin-right: 10px;
      display: block;
    }

    .slick-prev {
      display: none !important;
    }
  }

  .slick-prev,
  .js-prev {
    margin-right: 10px;
  }
}

.residential__angle {
  position: absolute;
  top: 0;
  left: -60px;
  display: none;
  width: 50%;
  height: 100%;
  cursor: pointer;

  @media (min-width: 768px) {
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center right;
  }
}

}
.pageblock_14607 {
#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_14608 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_14609 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
.pageblock_14619 {
@import '~assets/styles/variables';
@import 'leaflet/dist/leaflet.css';

.expert-map-section {
  position: relative;
  width: 100%;

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 168px;
    border-radius: 0 0 8px 8px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(xl) {
      height: 161px;
      border-radius: 0 0 24px 24px;
    }
  }

  .expert-map-section__map-wrapper {
    position: relative;

    .js-mobile-map-filters {
      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 32px;
        right: 32px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: $cyan;
        z-index: 999;

        i {
          color: $white;
        }

      }
    }
  }

  &__map {
    position: relative;
    display: block;
    height: 70vh;

    @include media-breakpoint-up(lg) {
      height: 100vh;
      min-height: 600px;
    }

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .expert-list-wrapper {
    position: absolute;
    bottom: 16px;
    z-index: 80;
    background-color: white;
    right: 0;
    left: 0;
    width: calc(100% - 32px);
    margin: 0 auto;
    border-radius: 5px;
  }

  &__holder:not(.expert-list-wrapper) {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: 0 8px 8px rgba($black, 0.08);
    margin: 0 auto;
    transition: .3s ease;
    z-index: 400;
    background-color: transparent;

    @include media-breakpoint-down(md) {
      opacity: 0;
      z-index: -1;
    }

    &.is-open {
      opacity: 1;
      z-index: 9999999;
    }

    .expert-map-section__overlay {
      @include media-breakpoint-down(md) {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($black, 0.3);
      }
    }

    @include media-breakpoint-up(lg) {
      position: absolute;
      top: 48px;
      right: 48px;
      bottom: auto;
      left: auto;
      background-color: $white;
      width: 528px;
      margin: 0;
      height: auto;
      max-height: calc(100% - 96px);
      transform: none;
    }
  }

  &__top-block {
    width: 100%;

    @include media-breakpoint-down(md) {
      flex-wrap: wrap;
    }

    .col-md-5 {
      @include media-breakpoint-down(md) {
        padding: 0 !important;
      }
    }

    .form-group {
      @include media-breakpoint-down(md) {
        margin-bottom: 8px !important;
        width: 100% !important;
      }

      &:nth-child(1) {
        @include media-breakpoint-up(md) {
          width: calc(100% - 300px);
        }

        input {
          border-radius: 5px 0 0 5px;
        }
      }

      button {
        @include media-breakpoint-down(md) {
          width: 100%;
        }
      }

      &:nth-child(2) {
        width: 128px;

        .form-control {
          margin-left: -1px;
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }

  &__top {
    padding: 30px 40px 20px;
    width: 100%;
    min-height: 168px;
    border-radius: 8px;
    background: $white;
    box-shadow: 0 8px 8px rgba($black, 0.1);
    z-index: 3;
    position: relative;

    @include media-breakpoint-down(md) {
      margin: 0 auto;
      width: calc(100% - 40px);
    }

    .js-mobile-filters-close {
      position: absolute;
      top: 8px;
      right: 12px;

      i {
        font-size: 14px;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    h5,
    .h5 {
      padding-right: 100px;
      position: relative;

      a {
        position: absolute;
        top: 4px;
        right: 50px;
        display: block;
        font-size: 14px;
        font-weight: 400;
        line-height: 1.4;
        color: $blue;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  &__bottom {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
      height: calc(100% - 168px);
      padding: 15px 24px;
      overflow: auto;
    }
  }

  &__block {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    width: 100%;
    transition: all 0.3s;

    @include media-breakpoint-up(lg) {
      &:not(:last-child) {
        border-bottom: 1px solid $gray-500;
      }
    }

    &:hover,
    &.is-active {
      text-decoration: none;
      border-radius: 8px;

      @include media-breakpoint-up(lg) {
        background: $gray-300;
      }
    }
  }

  .realtor-list-item {
    .expert-map-section__block-img {
      overflow: hidden;
      width: 80px;
      height: 80px;
      padding: 0;
      border: unset;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    .expert-map-section__block-text {
      address {
        &::after {
          content: '\e900';
        }

        span {
          color: rgba($blue, 40%);
        }

        @include media-breakpoint-up(lg) {
          margin: {
            top: 6px;
            bottom: 8px;
          }
        }
      }

      .btn-link-secondary {
        i {
          color: $cyan;
        }
      }

      @include media-breakpoint-up(lg) {
        width: calc(100% - 80px);
        padding-left: 40px;
      }
    }
  }

  &__block-img {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    width: 66px;
    border: 1px solid $gray-500;
    border-radius: 8px;
    background: $white;
    @include media-breakpoint-up(lg) {
      padding: 10px;
      width: 104px;
    }
  }

  &__block-text {
    padding-left: 20px;
    width: calc(100% - 66px);
    @include media-breakpoint-up(lg) {
      padding-left: 30px;
      width: calc(100% - 104px);
    }

    h6 {
      margin-bottom: 0;
      font-size: 16px;
      @include media-breakpoint-up(lg) {
        font-size: 18px;
      }
    }

    p {
      margin-bottom: 0;
    }

    address {
      margin-bottom: 4px;
      padding-left: 20px;
      position: relative;
      display: block;
      font-size: 14px;
      font-weight: 300;
      color: $blue;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        padding-left: 25px;
        font-size: 16px;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        font-family: $font-family-icon;
        color: $teal;
        content: '\e924';
      }
    }
  }
}

.house-heading {
  margin-bottom: 40px;
  padding-top: 130px;
  padding-bottom: 40px;
  position: relative;
  width: 100%;
  background-color: $blue;
  z-index: 4;
  @include background-image(house-heading-bg, svg, center top, no-repeat, cover);
  @include media-breakpoint-up(lg) {
    margin-bottom: 24px;
    padding-top: 160px;
    border-radius: 0 0 15px 15px;
  }
  @include media-breakpoint-up(xl) {
    &:not(.house-heading__thin) {
      padding-top: 200px;
    }
  }

  &__thin {
    height: 140px;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &__search {
    margin: 0 auto;
    padding-right: 25px;
    max-width: 802px;
  }
}
}
.pageblock_14679 {
background-color: #FFFFFF;
width:100vw;
height:100vh;

}
.pageblock_15629 {
.news-banner {
  $self: &;
  margin-bottom: 60px;
  padding-top: 165px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 80px;
    padding-top: 125px;
  }
  @include media-breakpoint-up(xl) {
    padding-top: 160px;
  }

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 320px;
    border-radius: 0 0 12px 12px;
    background: $blue;
    content: '';
    @include media-breakpoint-up(lg) {
      border-radius: 0 0 15px 15px;
    }
  }

  .breadcrumb {
    margin-bottom: 10px;
    padding-top: 25px;
    border-top: 1px solid rgba($white, 0.1);
  }

  &__holder {
    margin: 0 auto;
    padding: 0 20px;
    position: relative;
    max-width: 1840px;
  }

  &__subtitle {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    span {
      margin: 4px 0 0 15px;
      padding: 0 8px;
      display: inline-flex;
      align-items: center;
      height: 20px;
      font-size: 10px;
      text-transform: uppercase;
      color: $white;
      border-radius: 4px;
      background: $cyan;
    }
  }

  &__content {
    padding: 0 20px;
    position: absolute;
    bottom: 30px;
    left: 0;
    width: 100%;
    color: $white;

    h6 {
      font-weight: 600;
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 20px;
      }
    }

    h1 {
      color: $white;
      @include media-breakpoint-up(xl) {
        margin-bottom: 25px;
      }
    }

    p {
      color: $white;
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 400px;
    overflow: hidden;
    @include media-breakpoint-up(xl) {
      height: 640px;
    }

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.5;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.8) 100%);
      content: '';
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 24px;
      object-fit: cover;
    }
  }

  &__button {
    padding: 0 20px;
    position: absolute;
    top: 30px;
    right: 0;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    @include media-breakpoint-up(xl) {
      top: 50px;
    }
  }

  &__share,
  &__heart {
    margin-left: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    font-size: 15px;
    color: $blue;
    border-radius: 50%;
    background: $white;
    transition: all 0.3s;

    i.icon-heart {
      color: $red;
      &-alt {
        color: $blue;
      }
    }

    &.is-active,
    &:hover {
      color: $white;
      background: $blue;
      text-decoration: none;

      i.icon-heart {
        &:before {
          content: '\e919';
          color: $white;

        }

        &-alt {
          &:before {
            content: '\e91a';
            color: $red;

          }
        }
      }
    }
  }

  &.news-banner--small {
    #{$self}__image {
      @include media-breakpoint-up(xl) {
        height: 320px;
      }
    }
  }
}

#searchResults {
  position: absolute;
  background-color: #fff;
  width: 100%;
  margin-left: -100px;
  margin-top: -6px;
  border-radius: 0 0 10px 10px;
  z-index: 3; // index needs to be 3 to fall over the card-hearts

  ul {
    padding-top: 0;
    padding-left: 100px;
    width: fit-content;
    margin-right: 50px;

    li {
      border-bottom: 1px solid #ededed;
      padding-bottom: 8px;
      margin-bottom: 8px;

      .pull-right {
        padding-left: 10px;
      }

      ul {
        padding-top: 2px;
        padding-left: 0;

        li {
          padding-top: 5px;
          border-bottom: 0;
          padding-bottom: 0;
          margin-bottom: 0;

          a {
            color: #161549;
          }
        }
      }
    }
  }
}

}
.pageblock_15630 {
@import '~components/card-social/card-social';

.news-detail {
  margin-bottom: 80px;
  position: relative;
  z-index: 1;
  width: 100%;
  @include media-breakpoint-up(lg) {
    margin-bottom: 160px;
  }

  h4 {
    margin-bottom: 20px;
  }

  &__block {
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 25px;
    border-radius: 8px;
    background: $gray-300;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
      padding: 25px 35px;
    }

    p {
      font-size: 16px;
      font-weight: 300;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }
    }
  }

  &__content {
    h6 {
      margin-bottom: 40px;
      @include media-breakpoint-up(xl) {
        margin-bottom: 55px;
      }
    }
  }

  &__space {
    margin-top: 50px;
    margin-bottom: 50px;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-top: 75px;
      margin-bottom: 75px;
    }
  }

  &__bottom {
    margin-top: 50px;
    padding-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    border-top: 1px solid $gray-500;
    @include media-breakpoint-up(lg) {
      margin-top: 70px;
    }
  }

  &__sidebar {
    position: sticky;
    top: 20px;
  }

  &__social {
    width: 100%;
    @include media-breakpoint-up(lg) {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 55%;
    }
    @include media-breakpoint-up(xl) {
      justify-content: flex-end;
    }

    strong {
      margin-right: 22px;
      margin-bottom: 10px;
      width: 100%;
      font-weight: 600;
      color: $blue;
      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
        width: auto;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      display: inline-flex;
      flex-wrap: wrap;

      li {
        margin-left: 8px;

        &:first-child {
          margin-left: 0;
        }

        a {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          height: 48px;
          color: $blue;
          border-radius: 50%;
          background: $gray-300;
          transition: all 0.3s;

          &:hover {
            text-decoration: none;
            color: $white;
            background: $blue;
          }
        }
      }
    }
  }

  &__user {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
      width: 45%;
    }
  }

  &__user-img {
    width: 48px;
    height: 48px;
    border-radius: 50%;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
  }

  &__user-text {
    padding-left: 15px;
    width: calc(100% - 48px);

    span {
      display: block;
      font-size: 14px;
      font-weight: 300;
    }

    strong {
      display: block;
      font-size: 16px;
      color: $blue;
    }
  }
}

}
.pageblock_15631 {
.cta-banner {
  margin: 0 auto 56px;
  position: relative;
  width: 100%;
  max-width: 1920px;
  border-radius: 16px;
  background: $blue;
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 640px;
    border-radius: 24px;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 80px;
  }

  &__content {
    margin: -20px 0 0;
    padding: 40px 20px;
    position: relative;
    color: $white;
    border-radius: 0 12px 12px 0;
    background: $blue;
    @include media-breakpoint-up(lg) {
      margin: 0;
      padding: 0 20px 0 0;
      width: 50%;
      border-radius: 0;
      background: transparent;
    }
    @include media-breakpoint-up(xl) {
      width: 40%;
    }
    @include media-breakpoint-up(xxl) {
      padding-left: 50px;
      width: 36%;
    }
    @include media-breakpoint-up(xxxl) {
      padding-right: 0;
      padding-left: 90px;
      width: 34%;
    }

    h6 {
      margin-bottom: 16px;
      color: $cyan;
    }

    h2 {
      color: $white;
      @include media-breakpoint-up(lg) {
        margin-bottom: 32px;
      }
    }

    p {
      margin-bottom: 40px;
      @include media-breakpoint-down(md) {
        line-height: 26px;
      }
    }
  }

  &__image {
    position: relative;
    width: 100%;
    height: 240px;
    @include media-breakpoint-up(sm) {
      height: auto;
    }
    @include media-breakpoint-up(lg) {
      width: 50%;
      height: 640px;
      border-radius: 24px 0 0 24px;
    }
    @include media-breakpoint-up(xl) {
      width: 60%;
    }

    &:after {
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        right: -185px;
        width: 370px;
        height: 100%;
        content: '';
        @include background-image(cta-bg, svg, center center, no-repeat, 100% 100%);
      }
    }

    picture,
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include media-breakpoint-up(lg) {
        border-radius: 24px 0 0 24px;
      }
    }
  }
}

}
